import {
  COUNT_PLANTLOCATION_FAILED,
  COUNT_PLANTLOCATION_SUCCEED,
  COUNT_PLANTLOCATION_PROCESSING,
  FETCH_PLANTLOCATION_FAILED,
  FETCH_PLANTLOCATION_SUCCEED,
  FETCH_PLANTLOCATION_PROCESSING,
  SET_PLANTLOCATION_PARAMS,
  GET_PLANTLOCATION_FAILED,
  GET_PLANTLOCATION_SUCCEED,
  GET_PLANTLOCATION_PROCESSING,
  SAVE_PLANTLOCATION_FAILED,
  SAVE_PLANTLOCATION_SUCCEED,
  SAVE_PLANTLOCATION_PROCESSING,
  DELETE_PLANTLOCATION_FAILED,
  DELETE_PLANTLOCATION_SUCCEED,
  DELETE_PLANTLOCATION_PROCESSING,
  HIDE_PLANTLOCATION_ALERT,
} from 'constants/plantLocation.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  error: false,
  errorMessage: '',
  list: [],
  total: 0,
  regions: [],
  months: [],
  genusCategories: [],
  params: {},
  record: {
    name: '',
    location: '',
    locationImageURI: '',
  },
  searchText: '',
}

const plantLocation = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLANTLOCATION_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
        errorMessage: action.payload.error,
      }
    case FETCH_PLANTLOCATION_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
        errorMessage: '',
      }
    case FETCH_PLANTLOCATION_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        errorMessage: '',
        list: action.payload.list,
      }
    case SET_PLANTLOCATION_PARAMS:
      return {
        ...state,
        params: action.payload,
      }
    case GET_PLANTLOCATION_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: true,
        errorMessage: action.payload.error,
      }
    case GET_PLANTLOCATION_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
        record: initialState.record,
        errorMessage: '',
      }
    case GET_PLANTLOCATION_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        record: action.payload.record,
        errorMessage: '',
      }
    case COUNT_PLANTLOCATION_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_PLANTLOCATION_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_PLANTLOCATION_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case SAVE_PLANTLOCATION_FAILED:
      return {
        ...state,
        saving: false,
        saved: true,
        errorMessage: action.payload.error,
      }
    case SAVE_PLANTLOCATION_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_PLANTLOCATION_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_PLANTLOCATION_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_PLANTLOCATION_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_PLANTLOCATION_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_PLANTLOCATION_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        error: false,
        errorMessage: '',
        deleted: false,
      }
    default:
      return state
  }
}

export default plantLocation
