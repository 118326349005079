export const COUNT_USER_PROCESSING = 'COUNT_USER_PROCESSING'
export const COUNT_USER_SUCCEED = 'COUNT_USER_SUCCEED'
export const COUNT_USER_FAILED = 'COUNT_USER_FAILED'
export const FETCH_USER_PROCESSING = 'FETCH_USER_PROCESSING'
export const FETCH_USER_SUCCEED = 'FETCH_USER_SUCCEED'
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED'
export const GET_USER_PROCESSING = 'GET_USER_PROCESSING'
export const GET_USER_SUCCEED = 'GET_USER_SUCCEED'
export const GET_USER_FAILED = 'GET_USER_FAILED'
export const FETCH_USER_REGIONS_PROCESSING = 'FETCH_USER_REGIONS_PROCESSING'
export const FETCH_USER_REGIONS_SUCCEED = 'FETCH_USER_REGIONS_SUCCEED'
export const FETCH_USER_REGIONS_FAILED = 'FETCH_USER_REGIONS_FAILED'
export const SAVE_USER_PROCESSING = 'SAVE_USER_PROCESSING'
export const SAVE_USER_SUCCEED = 'SAVE_USER_SUCCEED'
export const SAVE_USER_FAILED = 'SAVE_USER_FAILED'
export const DELETE_USER_PROCESSING = 'DELETE_USER_PROCESSING'
export const DELETE_USER_SUCCEED = 'DELETE_USER_SUCCEED'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'
export const HIDE_USER_ALERT = 'HIDE_USER_ALERT'
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
