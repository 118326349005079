import {
  COUNT_CATEGORYCAREINFO_FAILED,
  COUNT_CATEGORYCAREINFO_SUCCEED,
  COUNT_CATEGORYCAREINFO_PROCESSING,
  FETCH_CATEGORYCAREINFO_FAILED,
  FETCH_CATEGORYCAREINFO_SUCCEED,
  FETCH_CATEGORYCAREINFO_PROCESSING,
  GET_CATEGORYCAREINFO_FAILED,
  GET_CATEGORYCAREINFO_SUCCEED,
  GET_CATEGORYCAREINFO_PROCESSING,
  FETCH_CATEGORYCAREINFO_REGIONS_FAILED,
  FETCH_CATEGORYCAREINFO_REGIONS_SUCCEED,
  FETCH_CATEGORYCAREINFO_REGIONS_PROCESSING,
  FETCH_CATEGORYCAREINFO_PRODUCTS_FAILED,
  FETCH_CATEGORYCAREINFO_PRODUCTS_SUCCEED,
  FETCH_CATEGORYCAREINFO_PRODUCTS_PROCESSING,
  FETCH_CATEGORYCAREINFO_MONTHS_FAILED,
  FETCH_CATEGORYCAREINFO_MONTHS_SUCCEED,
  FETCH_CATEGORYCAREINFO_MONTHS_PROCESSING,
  FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_FAILED,
  FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_SUCCEED,
  FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_PROCESSING,
  FETCH_CATEGORYCAREINFO_ICONS_FAILED,
  FETCH_CATEGORYCAREINFO_ICONS_SUCCEED,
  FETCH_CATEGORYCAREINFO_ICONS_PROCESSING,
  SAVE_CATEGORYCAREINFO_FAILED,
  SAVE_CATEGORYCAREINFO_SUCCEED,
  SAVE_CATEGORYCAREINFO_PROCESSING,
  DELETE_CATEGORYCAREINFO_FAILED,
  DELETE_CATEGORYCAREINFO_SUCCEED,
  DELETE_CATEGORYCAREINFO_PROCESSING,
  HIDE_CATEGORY_CARE_INFO_ALERT,
} from '../constants/categoryCareInfo.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  error: false,
  errorMessage: '',
  list: [],
  total: 0,
  regions: [],
  months: [],
  genusCategories: [],
  products: [],
  record: {
    object_id: '',
    categoryId: {
      value: '',
      label: '',
    },
    alexa: '',
    careField1: '',
    careField2: '',
    careField3: '',
    careDetails: '',
    careDescription: '',
    products: [],
    monthId: [],
    regions: [],
    icon: '',
  },
  searchText: '',
}

const categoryCareInfo = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORYCAREINFO_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CATEGORYCAREINFO_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
        errorMessage: '',
        searchText: action.payload.searchText,
      }
    case FETCH_CATEGORYCAREINFO_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        errorMessage: '',
        list: action.payload.list,
      }
    case GET_CATEGORYCAREINFO_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: true,
        errorMessage: action.payload.error,
      }
    case GET_CATEGORYCAREINFO_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
        errorMessage: '',
      }
    case GET_CATEGORYCAREINFO_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        record: action.payload.record,
        errorMessage: '',
      }
    case FETCH_CATEGORYCAREINFO_REGIONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CATEGORYCAREINFO_REGIONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CATEGORYCAREINFO_REGIONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        regions: action.payload.regions,
      }
    case COUNT_CATEGORYCAREINFO_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_CATEGORYCAREINFO_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_CATEGORYCAREINFO_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_CATEGORYCAREINFO_PRODUCTS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CATEGORYCAREINFO_PRODUCTS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CATEGORYCAREINFO_PRODUCTS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        products: action.payload.products,
      }
    case FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        genusCategories: action.payload.genusCategories,
      }
    case FETCH_CATEGORYCAREINFO_MONTHS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CATEGORYCAREINFO_MONTHS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CATEGORYCAREINFO_MONTHS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        months: action.payload.months,
      }
    case FETCH_CATEGORYCAREINFO_ICONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CATEGORYCAREINFO_ICONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CATEGORYCAREINFO_ICONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        icons: action.payload.icons,
      }
    case SAVE_CATEGORYCAREINFO_FAILED:
      return {
        ...state,
        saving: false,
        saved: true,
        errorMessage: action.payload.error,
      }
    case SAVE_CATEGORYCAREINFO_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_CATEGORYCAREINFO_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_CATEGORYCAREINFO_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_CATEGORYCAREINFO_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_CATEGORYCAREINFO_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_CATEGORY_CARE_INFO_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        errorMessage: '',
        deleted: false,
      }
    default:
      return state
  }
}

export default categoryCareInfo
