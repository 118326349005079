import PropTypes from 'prop-types'
import { propTypes as reduxFormPropTypes } from 'redux-form'

export default {
  initialValues: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object,
  ...reduxFormPropTypes,
}
