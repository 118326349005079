import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'
import './assets/css/material-dashboard-react.css'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'
import { createMuiTheme } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import registerServiceWorker from './registerServiceWorker'
import indexRoutes from './routes/index.jsx'
import { primaryColor, secondaryColor } from './variables/styles'
import Loading from 'components/Loading'

const hist = createBrowserHistory()

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    tertiary: {
      main: '#ff9800',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router basename={process.env.REACT_APP_ROUTER_BASENAME} history={hist}>
          <Suspense fallback={<Loading />}>
            <Switch>
              {indexRoutes.map(({ path, component }, key) => {
                return <Route path={path} component={component} key={key} />
              })}
            </Switch>
          </Suspense>
        </Router>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
)

registerServiceWorker()
