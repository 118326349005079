export const GET_NUMBERIDDONEBYEXPERT_PROCESSING =
  'GET_NUMBERIDDONEBYEXPERT_PROCESSING'
export const GET_NUMBERIDDONEBYEXPERT_SUCCEED =
  'GET_NUMBERIDDONEBYEXPERT_SUCCEED'
export const GET_NUMBERIDDONEBYEXPERT_FAILED = 'GET_NUMBERIDDONEBYEXPERT_FAILED'
export const HIDE_REPORT_ALERT = 'HIDE_REPORT_ALERT'

export const GET_RETAILERGROUPSREPORT_PROCESSING =
  'GET_RETAILERGROUPSREPORT_PROCESSING'
export const GET_RETAILERGROUPSREPORT_SUCCEED =
  'GET_RETAILERGROUPSREPORT_SUCCEED'
export const GET_RETAILERGROUPSREPORT_FAILED = 'GET_RETAILERGROUPSREPORT_FAILED'

export const GET_CHATSBYEXPERT_PROCESSING = 'GET_CHATSBYEXPERT_PROCESSING'
export const GET_CHATSBYEXPERT_SUCCEED = 'GET_CHATSBYEXPERT_SUCCEED'
export const GET_CHATSBYEXPERT_FAILED = 'GET_CHATSBYEXPERT_FAILED'
