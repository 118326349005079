export const SEND_PUSH_NOTIFICATION_PROCESSING =
  'SEND_PUSH_NOTIFICATION_PROCESSING'
export const SEND_PUSH_NOTIFICATION_SUCCEED = 'SEND_PUSH_NOTIFICATION_SUCCEED'
export const SEND_PUSH_NOTIFICATION_FAILED = 'SEND_PUSH_NOTIFICATION_FAILED'

export const SEND_EMAIL_MARKETING_PROCESSING = 'SEND_EMAIL_MARKETING_PROCESSING'
export const SEND_EMAIL_MARKETING_SUCCEED = 'SEND_EMAIL_MARKETING_SUCCEED'
export const SEND_EMAIL_MARKETING_FAILED = 'SEND_EMAIL_MARKETING_FAILED'
export const DISMISS_EMAIL_MARKETING_SUCCEED = 'DISMISS_EMAIL_MARKETING_SUCCEED'

export const GET_EMAIL_TEMPLATES_PROCESSING = 'GET_EMAIL_TEMPLATES_PROCESSING'
export const GET_EMAIL_TEMPLATES_SUCCEED = 'GET_EMAIL_TEMPLATES_SUCCEED'
export const GET_EMAIL_TEMPLATES_FAILED = 'GET_EMAIL_TEMPLATES_FAILED'
export const DISMISS_PUSH_MARKETING_SUCCEED = 'DISMISS_PUSH_MARKETING_SUCCEED'