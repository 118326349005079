export const COUNT_UNMATCHED_BARCODE_PROCESSING =
  'COUNT_UNMATCHED_BARCODE_PROCESSING'
export const COUNT_UNMATCHED_BARCODE_SUCCEED = 'COUNT_UNMATCHED_BARCODE_SUCCEED'
export const COUNT_UNMATCHED_BARCODE_FAILED = 'COUNT_UNMATCHED_BARCODE_FAILED'
export const FETCH_UNMATCHED_BARCODE_PROCESSING =
  'FETCH_UNMATCHED_BARCODE_PROCESSING'
export const FETCH_UNMATCHED_BARCODE_SUCCEED = 'FETCH_UNMATCHED_BARCODE_SUCCEED'
export const FETCH_UNMATCHED_BARCODE_FAILED = 'FETCH_UNMATCHED_BARCODE_FAILED'
export const GET_UNMATCHED_BARCODE_PROCESSING =
  'GET_UNMATCHED_BARCODE_PROCESSING'
export const GET_UNMATCHED_BARCODE_SUCCEED = 'GET_UNMATCHED_BARCODE_SUCCEED'
export const GET_UNMATCHED_BARCODE_FAILED = 'GET_UNMATCHED_BARCODE_FAILED'
export const FETCH_UNMATCHED_BARCODE_BARCODES_FAILED =
  'FETCH_UNMATCHED_BARCODE_BARCODES_FAILED'
export const FETCH_UNMATCHED_BARCODE_BARCODES_SUCCEED =
  'FETCH_UNMATCHED_BARCODE_BARCODES_SUCCEED'
export const FETCH_UNMATCHED_BARCODE_BARCODES_PROCESSING =
  'FETCH_UNMATCHED_BARCODE_BARCODES_PROCESSING'
export const SAVE_UNMATCHED_BARCODE_PROCESSING =
  'SAVE_UNMATCHED_BARCODE_PROCESSING'
export const SAVE_UNMATCHED_BARCODE_SUCCEED = 'SAVE_UNMATCHED_BARCODE_SUCCEED'
export const SAVE_UNMATCHED_BARCODE_FAILED = 'SAVE_UNMATCHED_BARCODE_FAILED'
export const SET_UNMATCHED_BARCODE_PAGE = 'SET_UNMATCHED_BARCODE_PAGE'
export const DELETE_UNMATCHED_BARCODE_PROCESSING =
  'DELETE_UNMATCHED_BARCODE_PROCESSING'
export const DELETE_UNMATCHED_BARCODE_SUCCEED =
  'DELETE_UNMATCHED_BARCODE_SUCCEED'
export const DELETE_UNMATCHED_BARCODE_FAILED = 'DELETE_UNMATCHED_BARCODE_FAILED'
export const HIDE_UNMATCHED_BARCODE_ALERT = 'HIDE_UNMATCHED_BARCODE_ALERT'
export const SELECT_PLANT = 'SELECT_PLANT'
export const TOGGLE_ADDING_NEW_PLANT = 'TOGGLE_ADDING_NEW_PLANT'
export const ADDING_EXISTING_PLANT = 'ADDING_EXISTING_PLANT'
