// parse client
import Parse from 'parse'

Parse.initialize(
  process.env.REACT_APP_ID,
  process.env.REACT_APP_JAVASCRIPT_KEY
)
Parse.serverURL = `${process.env.REACT_APP_PARSE_SERVER_PROTOCOL}${process.env.REACT_APP_PARSE_SERVER_ADDRESS}/parse`

export default Parse
