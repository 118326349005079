import {
  COUNT_GENUSCAREINFO_FAILED,
  COUNT_GENUSCAREINFO_SUCCEED,
  COUNT_GENUSCAREINFO_PROCESSING,
  FETCH_GENUSCAREINFO_FAILED,
  FETCH_GENUSCAREINFO_SUCCEED,
  FETCH_GENUSCAREINFO_PROCESSING,
  GET_GENUSCAREINFO_FAILED,
  GET_GENUSCAREINFO_SUCCEED,
  GET_GENUSCAREINFO_PROCESSING,
  FETCH_GENUSCAREINFO_REGIONS_FAILED,
  FETCH_GENUSCAREINFO_REGIONS_SUCCEED,
  FETCH_GENUSCAREINFO_REGIONS_PROCESSING,
  FETCH_GENUSCAREINFO_PRODUCTS_FAILED,
  FETCH_GENUSCAREINFO_PRODUCTS_SUCCEED,
  FETCH_GENUSCAREINFO_PRODUCTS_PROCESSING,
  FETCH_GENUSCAREINFO_MONTHS_FAILED,
  FETCH_GENUSCAREINFO_MONTHS_SUCCEED,
  FETCH_GENUSCAREINFO_MONTHS_PROCESSING,
  FETCH_GENUSCAREINFO_GENUSES_FAILED,
  FETCH_GENUSCAREINFO_GENUSES_SUCCEED,
  FETCH_GENUSCAREINFO_GENUSES_PROCESSING,
  FETCH_GENUSCAREINFO_ICONS_FAILED,
  FETCH_GENUSCAREINFO_ICONS_SUCCEED,
  FETCH_GENUSCAREINFO_ICONS_PROCESSING,
  SAVE_GENUSCAREINFO_FAILED,
  SAVE_GENUSCAREINFO_SUCCEED,
  SAVE_GENUSCAREINFO_PROCESSING,
  DELETE_GENUSCAREINFO_FAILED,
  DELETE_GENUSCAREINFO_SUCCEED,
  DELETE_GENUSCAREINFO_PROCESSING,
  HIDE_GENUS_CARE_INFO_ALERT,
} from '../constants/genusCareInfo.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  regions: [],
  months: [],
  genuses: [],
  products: [],
  record: {
    object_id: '',
    genusId: {
      value: '',
      label: '',
    },
    careField1: '',
    careField2: '',
    careDetails: '',
    alexa: '',
    products: [],
    monthId: [],
    regions: [],
    icon: '',
  },
  searchText: '',
}

const genusCareInfo = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GENUSCAREINFO_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
        list: [],
      }
    case FETCH_GENUSCAREINFO_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        searchText: action.payload.searchText,
        errorMessage: '',
      }
    case FETCH_GENUSCAREINFO_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: '',
      }
    case GET_GENUSCAREINFO_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
        record: initialState.record,
      }
    case GET_GENUSCAREINFO_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case GET_GENUSCAREINFO_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        errorMessage: '',
      }
    case FETCH_GENUSCAREINFO_REGIONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_GENUSCAREINFO_REGIONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_GENUSCAREINFO_REGIONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        regions: action.payload.regions,
      }
    case COUNT_GENUSCAREINFO_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_GENUSCAREINFO_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_GENUSCAREINFO_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_GENUSCAREINFO_PRODUCTS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_GENUSCAREINFO_PRODUCTS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_GENUSCAREINFO_PRODUCTS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        products: action.payload.products,
      }
    case FETCH_GENUSCAREINFO_GENUSES_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_GENUSCAREINFO_GENUSES_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_GENUSCAREINFO_GENUSES_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        genuses: action.payload.genuses,
      }
    case FETCH_GENUSCAREINFO_MONTHS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_GENUSCAREINFO_MONTHS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_GENUSCAREINFO_MONTHS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        months: action.payload.months,
      }
    case FETCH_GENUSCAREINFO_ICONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_GENUSCAREINFO_ICONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_GENUSCAREINFO_ICONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        icons: action.payload.icons,
      }
    case SAVE_GENUSCAREINFO_FAILED:
      return {
        ...state,
        saving: false,
        saved: true,
        errorMessage: action.payload.error,
      }
    case SAVE_GENUSCAREINFO_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_GENUSCAREINFO_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_GENUSCAREINFO_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_GENUSCAREINFO_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_GENUSCAREINFO_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_GENUS_CARE_INFO_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        errorMessage: '',
        deleted: false,
      }
    default:
      return state
  }
}

export default genusCareInfo
