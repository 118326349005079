import {
  COUNT_USER_FAILED,
  COUNT_USER_SUCCEED,
  COUNT_USER_PROCESSING,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCEED,
  FETCH_USER_PROCESSING,
  GET_USER_FAILED,
  GET_USER_SUCCEED,
  GET_USER_PROCESSING,
  FETCH_USER_REGIONS_FAILED,
  FETCH_USER_REGIONS_SUCCEED,
  FETCH_USER_REGIONS_PROCESSING,
  SAVE_USER_FAILED,
  SAVE_USER_SUCCEED,
  SAVE_USER_PROCESSING,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCEED,
  DELETE_USER_PROCESSING,
} from '../../constants/user.constants'
import Parse from '../parse'
import debounce from 'p-debounce'

export const user = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_USER_PROCESSING,
      })
      let queryRole = new Parse.Query(Parse.Object.extend('_Role'))
      queryRole.equalTo('name', 'user')
      try {
        const role = await queryRole.first()
        let relation = new Parse.Relation(role, 'users')
        let queryUser = relation.query()
        searchText = searchText || ''
        if (searchText !== '') {
          queryUser.matches('email', searchText.trim(), 'i')
        }
        const total = await queryUser.count()
        dispatch({
          type: COUNT_USER_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_USER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  list(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_USER_PROCESSING,
      })
      let queryRole = new Parse.Query(Parse.Object.extend('_Role'))
      queryRole.equalTo('name', 'user')
      try {
        const role = await queryRole.first()
        let relation = new Parse.Relation(role, 'users')
        let queryUser = relation.query()
        queryUser.include('region')
        searchText = searchText || ''
        if (searchText !== '') {
          queryUser.matches('email', searchText.trim(), 'i')
        }
        queryUser.descending('updatedAt')
        queryUser.limit(rowsPerPage)
        queryUser.skip(page * rowsPerPage)
        const results = await queryUser.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          let object = results[i]
          list.push({
            [object.id]: {
              email: object.get('username'),
              paidCredits: object.get('paidCredits'),
              regionName: object.get('region')
                ? object.get('region').get('regionName')
                : '',
              subscriptionStatus: object.get('subscriptionStatus'),
              subscriptionCredits: object.get('subscriptionCredits'),
            },
          })
        }
        dispatch({
          type: FETCH_USER_SUCCEED,
          payload: {
            list: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_USER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_USER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('_User'))
      query.include('region')
      try {
        const user = await query.get(id)
        const record = {
          object_id: user.id,
          email: user.get('email'),
          password: '',
          region: {
            value: '',
            label: '',
          },
        }
        const region = user.get('region')
        if (region) {
          record.region.value = region.id
          record.region.label = region.get('regionName')
        }

        dispatch({
          type: GET_USER_SUCCEED,
          payload: {
            record,
          },
        })
      } catch (error) {
        dispatch({
          type: GET_USER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getRegions(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_USER_REGIONS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('userName', searchText);
        query.matches('regionName', searchText.trim(), 'i')
      }
      query.limit(10000)
      try {
        const results = await query.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('regionName'),
          })
        }
        dispatch({
          type: FETCH_USER_REGIONS_SUCCEED,
          payload: {
            regions: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_USER_REGIONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_USER_PROCESSING,
      })
      let user
      let newUser = false
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        const userQuery = new Parse.Query(Parse.User)
        user = await userQuery.get(values.object_id)
      } else {
        newUser = true
        user = new Parse.User()
      }
      user.set('email', values.email)
      if (values.password) {
        user.set('password', values.password)
      }
      if (values.region && values.region.value) {
        let regionQuery = new Parse.Query(Parse.Object.extend('Region'))
        let region = await regionQuery.get(values.region.value)
        user.set('region', region.toPointer())
      }
      user.set('username', values.email)
      user.set('userStatus', 'Authorized User')
      try {
        await user.save(null)
        if (newUser) {
          let queryRole = new Parse.Query(Parse.Object.extend('_Role'))
          queryRole.equalTo('name', 'user')
          const role = await queryRole.first()
          role.relation('users').add(newUser)
          await role.save()
          await Parse.Cloud.run('UpdatePromoCodeAddUsersInCMS', {
            objectId: newUser.id,
          })
        } else {
          dispatch({
            type: SAVE_USER_SUCCEED,
          })
        }
      } catch (error) {
        dispatch({
          type: SAVE_USER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_USER_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('_User'))
      try {
        const users = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(users)
        dispatch({
          type: DELETE_USER_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_USER_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  select: debounce(async email => {
    let userQuery = new Parse.Query(Parse.User)
    try {
      const users = await userQuery.equalTo('email', email).find()
      return users.map(user => {
        const userJSON = user.toJSON()
        return {
          value: userJSON.objectId,
          label: userJSON.email || userJSON.username,
        }
      })
    } catch (err) {
      return []
    }
  }, 1000),
}
