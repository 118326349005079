import { master as masterAPI } from '../api'
import { HIDE_MASTER_ALERT } from '../constants/master.constants'

export default {
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      dispatch(masterAPI.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      dispatch(masterAPI.get(id))
    }
  },
  count(query) {
    return dispatch => {
      dispatch(masterAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(masterAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(masterAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_MASTER_ALERT,
      })
    }
  },
}
