import { applyMiddleware, createStore, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import reducers from '../reducers'

let applyMiddlewares = applyMiddleware(thunk)

const createStoreWithMiddleware = compose(applyMiddlewares)(createStore)

const persistConfig = {
  key: 'smartplant-cms',
  storage,
  blacklist: ['form'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStoreWithMiddleware(
  persistedReducer,
  typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
)

let persistor = persistStore(store)

export { store, persistor }
