import { auth as authAPI } from '../api'
import { HIDE_AUTH_ALERT } from '../constants/auth.constants'

export const auth = {
  login(values) {
    return dispatch => {
      dispatch(authAPI.login(values))
    }
  },
  logout() {
    return dispatch => {
      dispatch(authAPI.logout())
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_AUTH_ALERT,
      })
    }
  },
}
