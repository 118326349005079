export const COUNT_MASTER_PROCESSING = 'COUNT_MASTER_PROCESSING'
export const COUNT_MASTER_SUCCEED = 'COUNT_MASTER_SUCCEED'
export const COUNT_MASTER_FAILED = 'COUNT_MASTER_FAILED'
export const FETCH_MASTER_PROCESSING = 'FETCH_MASTER_PROCESSING'
export const FETCH_MASTER_SUCCEED = 'FETCH_MASTER_SUCCEED'
export const FETCH_MASTER_FAILED = 'FETCH_MASTER_FAILED'
export const GET_MASTER_PROCESSING = 'GET_MASTER_PROCESSING'
export const GET_MASTER_SUCCEED = 'GET_MASTER_SUCCEED'
export const GET_MASTER_FAILED = 'GET_MASTER_FAILED'
export const SAVE_MASTER_PROCESSING = 'SAVE_MASTER_PROCESSING'
export const SAVE_MASTER_SUCCEED = 'SAVE_MASTER_SUCCEED'
export const SAVE_MASTER_FAILED = 'SAVE_MASTER_FAILED'
export const DELETE_MASTER_PROCESSING = 'DELETE_MASTER_PROCESSING'
export const DELETE_MASTER_SUCCEED = 'DELETE_MASTER_SUCCEED'
export const DELETE_MASTER_FAILED = 'DELETE_MASTER_FAILED'
export const HIDE_MASTER_ALERT = 'HIDE_MASTER_ALERT'
