import { expert as expertAPI } from '../api/modules/expert.api'
import { HIDE_EXPERT_ALERT, NEW_EXPERT } from '../constants/expert.constants'
export const expert = {
  list(page, rowsPerPage, query) {
    return dispatch => {
      dispatch(expertAPI.list(page, rowsPerPage, query))
    }
  },
  getExpert(id) {
    return dispatch => {
      dispatch(expertAPI.get(id))
    }
  },
  getExpertRegions(query) {
    return dispatch => {
      dispatch(expertAPI.getRegions(query))
    }
  },
  newExpert() {
    return dispatch => {
      dispatch({
        type: NEW_EXPERT,
      })
    }
  },
  count(query) {
    return dispatch => {
      dispatch(expertAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(expertAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(expertAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_EXPERT_ALERT,
      })
    }
  },
}
