export default {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#ffffff' : '#434343',
    fontWeight: state.isFocused ? 'bold' : 'normal',
    backgroundColor: state.isFocused ? '#434343' : '#ffffff',
  }),
  container: (provided) => ({
    ...provided,
    flexGrow: 1,
    paddingRight: 20,
  }),
}
