import {
  COUNT_REGION_FAILED,
  COUNT_REGION_SUCCEED,
  COUNT_REGION_PROCESSING,
  FETCH_REGION_FAILED,
  FETCH_REGION_SUCCEED,
  FETCH_REGION_PROCESSING,
  GET_REGION_PROCESSING,
  GET_REGION_FAILED,
  GET_REGION_SUCCEED,
  FETCH_REGION_SUBSCRIPTIONCURRENCIES_FAILED,
  FETCH_REGION_SUBSCRIPTIONCURRENCIES_SUCCEED,
  FETCH_REGION_SUBSCRIPTIONCURRENCIES_PROCESSING,
  SAVE_REGION_FAILED,
  SAVE_REGION_SUCCEED,
  SAVE_REGION_PROCESSING,
  DELETE_REGION_FAILED,
  DELETE_REGION_SUCCEED,
  DELETE_REGION_PROCESSING,
  HEMISPHERES,
} from '../../constants'
import Parse from '../parse'

export const region = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_REGION_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('region', searchText.trim(), 'i')
      }
      try {
        const total = await query.count()
        return dispatch({
          type: COUNT_REGION_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_REGION_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  list(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_REGION_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      query.include('subscriptionCurrency')
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('region', searchText.trim(), 'i')
      }
      query.limit(rowsPerPage)
      query.skip(page * rowsPerPage)
      query.descending('createdAt')
      try {
        const results = await query.find()
        var list = []
        list = results.map(r => {
          return {
            [r.id]: {
              regionName: r.get('regionName'),
              hemisphere: HEMISPHERES[r.get('hemisphere')],
              subscriptionCurrency: r.get('subscriptionCurrency')
                ? r.get('subscriptionCurrency').get('name')
                : '',
            },
          }
        })
        dispatch({
          type: FETCH_REGION_SUCCEED,
          payload: {
            list: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_REGION_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_REGION_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      try {
        const region = await query
          .include('plant')
          .include('retailersGroup')
          .include('retailersGroups')
          .include('subscriptionCurrency')
          .get(id)
        const subscriptionCurrency = region.get('subscriptionCurrency')
        return dispatch({
          type: GET_REGION_SUCCEED,
          payload: {
            record: {
              object_id: region.id,
              regionName: region.get('regionName'),
              hemisphere: {
                value: region.get('hemisphere'),
                label: HEMISPHERES[region.get('hemisphere')],
              },
              subscriptionCurrency: {
                value: subscriptionCurrency ? subscriptionCurrency.id : '',
                label: subscriptionCurrency ? subscriptionCurrency.get('name') : '',
              },
            },
          },
        })
      } catch (error) {
        dispatch({
          type: GET_REGION_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getSubscriptionCurrencies() {
    return async dispatch => {
      dispatch({
        type: FETCH_REGION_SUBSCRIPTIONCURRENCIES_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('SubscriptionCurrency'))
      let results = []
      try {
        results = await query.limit(10000).find()
      } catch (error) {
        return dispatch({
          type: FETCH_REGION_SUBSCRIPTIONCURRENCIES_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        list.push({
          value: object.id,
          label: object.get('name'),
        })
      }
      return dispatch({
        type: FETCH_REGION_SUBSCRIPTIONCURRENCIES_SUCCEED,
        payload: {
          subscriptionCurrencies: list,
        },
      })
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_REGION_PROCESSING,
      })
      let regionModel = new Parse.Object.extend('Region')
      let region = new regionModel()
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        region.id = values.object_id
      }
      for (var k in values) {
        if (k !== 'object_id') {
          if (k === 'subscriptionCurrency' && values[k]) {
            let subscriptionCurrencyModel = new Parse.Object.extend(
              'SubscriptionCurrency'
            )
            let subscriptionCurrency = new subscriptionCurrencyModel()
            subscriptionCurrency.id = values[k]
            region.set(k, subscriptionCurrency)
          } else {
            region.set(k, values[k])
          }
        }
      }
      try {
        await region.save(null)
        dispatch({
          type: SAVE_REGION_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: SAVE_REGION_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_REGION_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      try {
        const regions = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(regions)
        dispatch({
          type: DELETE_REGION_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_REGION_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
