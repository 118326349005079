export const COUNT_REGION_PROCESSING = 'COUNT_REGION_PROCESSING'
export const COUNT_REGION_SUCCEED = 'COUNT_REGION_SUCCEED'
export const COUNT_REGION_FAILED = 'COUNT_REGION_FAILED'
export const FETCH_REGION_PROCESSING = 'FETCH_REGION_PROCESSING'
export const FETCH_REGION_SUCCEED = 'FETCH_REGION_SUCCEED'
export const FETCH_REGION_FAILED = 'FETCH_REGION_FAILED'
export const FETCH_REGION_SUBSCRIPTIONCURRENCIES_PROCESSING =
  'FETCH_REGION_SUBSCRIPTIONCURRENCIES_PROCESSING'
export const FETCH_REGION_SUBSCRIPTIONCURRENCIES_SUCCEED =
  'FETCH_REGION_SUBSCRIPTIONCURRENCIES_SUCCEED'
export const FETCH_REGION_SUBSCRIPTIONCURRENCIES_FAILED =
  'FETCH_REGION_SUBSCRIPTIONCURRENCIES_FAILED'
export const GET_REGION_PROCESSING = 'GET_REGION_PROCESSING'
export const GET_REGION_SUCCEED = 'GET_REGION_SUCCEED'
export const GET_REGION_FAILED = 'GET_REGION_FAILED'
export const SAVE_REGION_PROCESSING = 'SAVE_REGION_PROCESSING'
export const SAVE_REGION_SUCCEED = 'SAVE_REGION_SUCCEED'
export const SAVE_REGION_FAILED = 'SAVE_REGION_FAILED'
export const DELETE_REGION_PROCESSING = 'DELETE_REGION_PROCESSING'
export const DELETE_REGION_SUCCEED = 'DELETE_REGION_SUCCEED'
export const DELETE_REGION_FAILED = 'DELETE_REGION_FAILED'
export const HIDE_REGION_ALERT = 'HIDE_REGION_ALERT'
export const HEMISPHERES = {
  northernHemisphere: 'Northern Hemisphere',
  southernHemisphere: 'Southern Hemisphere',
}
