import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import { categoryCareInfo } from '../../actions'
import CustomTable from '../Table/CustomTable'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash.debounce'
import CategoryCareInfoForm from './CategoryCareInfoForm'
import CustomSnackbar from 'components/CustomSnackbar'

class CategoryCareInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      fields: [
        {
          id: 'category',
          label: 'Category',
        },
        { id: 'alexa', label: 'Alexa' },
        {
          id: 'careField1',
          label: 'Action',
        },
        {
          id: 'careField2',
          label: 'Try This',
        },
        {
          id: 'careField3',
          label: 'Recommendations',
        },
        {
          id: 'monthId',
          label: 'Months',
        },
      ],
    }
  }
  componentDidMount() {
    this.props.dispatch(categoryCareInfo.count())
    this._getCategoryCareInfo()
  }
  _getCategoryCareInfo() {
    const query = qs.parse(this.props.location.search)
    const searchText = this.props.searchText || query.searchText || ''
    this.props.dispatch(
      categoryCareInfo.fetch(
        this.state.page,
        this.state.rowsPerPage,
        searchText
      )
    )
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getCategoryCareInfo()
    })
  }
  handleSearch = debounce(query => {
    const search = qs.stringify({
      searchText: query,
    })
    this.props.history.push({ search })
    this.props.dispatch(categoryCareInfo.count(query))
    this.props.dispatch(
      categoryCareInfo.list(this.state.page, this.state.rowsPerPage, query)
    )
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getCategoryCareInfo()
    })
  }
  handleDeleteAction = ids => {
    this.props.dispatch(categoryCareInfo.delete(ids))
  }
  hideAlert() {
    this.props.dispatch(categoryCareInfo.hideAlert())
  }
  render() {
    const { errorMessage, deleted } = this.props
    return (
      <div>
        <CustomSnackbar variant="info" open={deleted} message="Deleted" />
        <CustomSnackbar
          variant="error"
          open={Boolean(errorMessage)}
          message={errorMessage}
          handleClose={() => this.handleCloseError()}
        />
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/categoryCareInfo/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Care Calendar Category'}
          total={this.props.total}
          history={this.props.history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={this.props.list}
          duplicateForm={<CategoryCareInfoForm />}
          editPath="/categoryCareInfo/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/categoryCareInfo/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

CategoryCareInfo.propTypes = {
  total: PropTypes.number,
  list: PropTypes.array,
  searchText: PropTypes.string,
  errorMessage: PropTypes.string,
  deleted: PropTypes.bool,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  total: state.categoryCareInfo.total,
  list: state.categoryCareInfo.list,
  deleted: state.categoryCareInfo.deleted,
  errorMessage: state.categoryCareInfo.errorMessage,
  searchText: state.categoryCareInfo.searchText,
})

export default withRouter(connect(mapStateToProps)(CategoryCareInfo))
