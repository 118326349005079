export const COUNT_CATEGORYCAREINFO_PROCESSING =
  'COUNT_CATEGORYCAREINFO_PROCESSING'
export const COUNT_CATEGORYCAREINFO_SUCCEED = 'COUNT_CATEGORYCAREINFO_SUCCEED'
export const COUNT_CATEGORYCAREINFO_FAILED = 'COUNT_CATEGORYCAREINFO_FAILED'
export const FETCH_CATEGORYCAREINFO_PROCESSING =
  'FETCH_CATEGORYCAREINFO_PROCESSING'
export const FETCH_CATEGORYCAREINFO_SUCCEED = 'FETCH_CATEGORYCAREINFO_SUCCEED'
export const FETCH_CATEGORYCAREINFO_FAILED = 'FETCH_CATEGORYCAREINFO_FAILED'
export const FETCH_CATEGORYCAREINFO_REGIONS_PROCESSING =
  'FETCH_CATEGORYCAREINFO_REGIONS_PROCESSING'
export const FETCH_CATEGORYCAREINFO_REGIONS_SUCCEED =
  'FETCH_CATEGORYCAREINFO_REGIONS_SUCCEED'
export const FETCH_CATEGORYCAREINFO_REGIONS_FAILED =
  'FETCH_CATEGORYCAREINFO_REGIONS_FAILED'
export const GET_CATEGORYCAREINFO_PROCESSING = 'GET_CATEGORYCAREINFO_PROCESSING'
export const GET_CATEGORYCAREINFO_SUCCEED = 'GET_CATEGORYCAREINFO_SUCCEED'
export const GET_CATEGORYCAREINFO_FAILED = 'GET_CATEGORYCAREINFO_FAILED'
export const FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_PROCESSING =
  'FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_PROCESSING'
export const FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_SUCCEED =
  'FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_SUCCEED'
export const FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_FAILED =
  'FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_FAILED'
export const FETCH_CATEGORYCAREINFO_MONTHS_PROCESSING =
  'FETCH_CATEGORYCAREINFO_MONTHS_PROCESSING'
export const FETCH_CATEGORYCAREINFO_MONTHS_SUCCEED =
  'FETCH_CATEGORYCAREINFO_MONTHS_SUCCEED'
export const FETCH_CATEGORYCAREINFO_MONTHS_FAILED =
  'FETCH_CATEGORYCAREINFO_MONTHS_FAILED'
export const FETCH_CATEGORYCAREINFO_PRODUCTS_PROCESSING =
  'FETCH_CATEGORYCAREINFO_PRODUCTS_PROCESSING'
export const FETCH_CATEGORYCAREINFO_PRODUCTS_SUCCEED =
  'FETCH_CATEGORYCAREINFO_PRODUCTS_SUCCEED'
export const FETCH_CATEGORYCAREINFO_PRODUCTS_FAILED =
  'FETCH_CATEGORYCAREINFO_PRODUCTS_FAILED'
export const FETCH_CATEGORYCAREINFO_ICONS_FAILED =
  'FETCH_CATEGORYCAREINFO_ICONS_FAILED'
export const FETCH_CATEGORYCAREINFO_ICONS_SUCCEED =
  'FETCH_CATEGORYCAREINFO_ICONS_SUCCEED'
export const FETCH_CATEGORYCAREINFO_ICONS_PROCESSING =
  'FETCH_CATEGORYCAREINFO_ICONS_PROCESSING'
export const SAVE_CATEGORYCAREINFO_PROCESSING =
  'SAVE_CATEGORYCAREINFO_PROCESSING'
export const SAVE_CATEGORYCAREINFO_SUCCEED = 'SAVE_CATEGORYCAREINFO_SUCCEED'
export const SAVE_CATEGORYCAREINFO_FAILED = 'SAVE_CATEGORYCAREINFO_FAILED'
export const DELETE_CATEGORYCAREINFO_PROCESSING =
  'DELETE_CATEGORYCAREINFO_PROCESSING'
export const DELETE_CATEGORYCAREINFO_SUCCEED = 'DELETE_CATEGORYCAREINFO_SUCCEED'
export const DELETE_CATEGORYCAREINFO_FAILED = 'DELETE_CATEGORYCAREINFO_FAILED'
export const HIDE_CATEGORY_CARE_INFO_ALERT = 'HIDE_CATEGORY_CARE_INFO_ALERT'
