import careInfoApi from 'api/modules/careInfo.api'
import {
  HIDE_CAREINFO_ALERT,
  FETCH_CAREINFO_PROCESSING,
  FETCH_CAREINFO_FAILED,
  FETCH_CAREINFO_SUCCEED,
  SAVE_CAREINFO_PROCESSING,
  SAVE_CAREINFO_FAILED,
  SAVE_CAREINFO_SUCCEED,
} from '../constants/careInfo.constants'

export default {
  fetch(params) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_CAREINFO_PROCESSING,
      })
      try {
        let list = await careInfoApi.fetch(params)
        dispatch({
          type: FETCH_CAREINFO_SUCCEED,
          payload: { list },
        })
      } catch (error) {
        dispatch({
          type: FETCH_CAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return (dispatch) => {
      dispatch(careInfoApi.get(id))
    }
  },
  getGenusCategories(query) {
    return (dispatch) => {
      dispatch(careInfoApi.getGenusCategories(query))
    }
  },
  getMonths(query) {
    return (dispatch) => {
      dispatch(careInfoApi.getMonths(query))
    }
  },
  getRegions() {
    return (dispatch) => {
      dispatch(careInfoApi.getRegions())
    }
  },
  getProducts(query) {
    return (dispatch) => {
      dispatch(careInfoApi.getProducts(query))
    }
  },
  getIcons(query) {
    return (dispatch) => {
      dispatch(careInfoApi.getIcons(query))
    }
  },
  count(query) {
    return (dispatch) => {
      dispatch(careInfoApi.count(query))
    }
  },
  save(values) {
    return async (dispatch) => {
      dispatch({
        type: SAVE_CAREINFO_PROCESSING,
      })
      try {
        await dispatch(careInfoApi.save(values))
        dispatch({
          type: SAVE_CAREINFO_SUCCEED,
        })
      } catch (err) {
        dispatch({
          type: SAVE_CAREINFO_FAILED,
          payload: {
            error: `Error: ${err.code} ${err.message}`,
          },
        })
      }
    }
  },
  delete(id) {
    return (dispatch) => {
      dispatch(careInfoApi.delete(id))
    }
  },
  hideAlert() {
    return (dispatch) => {
      dispatch({
        type: HIDE_CAREINFO_ALERT,
      })
    }
  },
}
