import {
  COUNT_PLANT_FAILED,
  COUNT_PLANT_SUCCEED,
  COUNT_PLANT_PROCESSING,
  FETCH_PLANT_FAILED,
  FETCH_PLANT_SUCCEED,
  FETCH_PLANT_PROCESSING,
  GET_PLANT_FAILED,
  GET_PLANT_SUCCEED,
  GET_PLANT_PROCESSING,
  FETCH_PLANT_GENUS_FAILED,
  FETCH_PLANT_GENUS_SUCCEED,
  FETCH_PLANT_GENUS_PROCESSING,
  FETCH_PLANT_NAME_FAILED,
  FETCH_PLANT_NAME_SUCCEED,
  FETCH_PLANT_NAME_PROCESSING,
  SAVE_PLANT_FAILED,
  SAVE_PLANT_SUCCEED,
  SAVE_PLANT_PROCESSING,
  DELETE_PLANT_FAILED,
  DELETE_PLANT_SUCCEED,
  DELETE_PLANT_PROCESSING,
  SELECT_SNAPP_PHOTO,
  HIDE_PLANT_ALERT,
  SAVE_SNAPP_PHOTO_FAILED,
  SAVE_SNAPP_PHOTO_SUCCEED,
  SAVE_SNAPP_PHOTO_PROCESSING,
  MERGE_PLANTS_FAILED,
  MERGE_PLANTS_SUCCEED,
  MERGE_PLANTS_PROCESSING,
} from '../constants/plant.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  genus: [],
  photos: [],
  plantPhotos: [],
  record: {
    object_id: '',
    latinName: '',
    plantDescription: '',
    genus: '',
    commonName: '',
    alexaSpokenDescription: '',
    AlexaSearch: '',
    heightAndWidth: '',
    bloomSeason: '',
    lightRecommendation: '',
    wateringRecommendation: '',
    pruningNeeds: '',
    hardiness: '',
    usdaUkPlantingZones: '',
    timeOfYearToPlant: '',
    soilTypeRecommendation: '',
    buyURL: '',
    visible: false,
  },
  savingSnappPhoto: false,
  savedSnappPhoto: false,
  mergingPlants: false,
  mergingPlantsSuccess: false,
  mergingPlantsFailure: false,
}

const plant = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLANT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
        list: [],
      }
    case FETCH_PLANT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case FETCH_PLANT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: '',
      }
    case GET_PLANT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        record: initialState.record,
        errorMessage: action.payload.error,
      }
    case GET_PLANT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        record: initialState.record,
        plantPhotos: initialState.plantPhotos,
        errorMessage: '',
      }
    case GET_PLANT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        photos: action.payload.photos,
        plantPhotos: action.payload.plantPhotos,
        errorMessage: '',
      }
    case COUNT_PLANT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_PLANT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_PLANT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_PLANT_GENUS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_PLANT_GENUS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_PLANT_GENUS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        genus: action.payload.genus,
      }
    case FETCH_PLANT_NAME_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_PLANT_NAME_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_PLANT_NAME_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        namePlants: action.payload.namePlants,
      }

    case SAVE_PLANT_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        errorMessage: action.payload.error,
      }
    case SAVE_PLANT_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_PLANT_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_PLANT_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_PLANT_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_PLANT_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_PLANT_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        savedSnappPhoto: false,
        errorMessage: '',
        deleted: false,
        mergingPlantsSuccess: false,
        mergingPlantsFailure: false,
      }
    case SELECT_SNAPP_PHOTO:
      return {
        ...state,
        photos: action.payload.photos,
      }
    case SAVE_SNAPP_PHOTO_FAILED:
      return {
        ...state,
        savingSnappPhoto: false,
        savedSnappPhoto: false,
        errorMessage: action.payload.error,
      }
    case SAVE_SNAPP_PHOTO_PROCESSING:
      return {
        ...state,
        savingSnappPhoto: true,
        savedSnappPhoto: false,
      }
    case SAVE_SNAPP_PHOTO_SUCCEED:
      return {
        ...state,
        savingSnappPhoto: false,
        savedSnappPhoto: true,
      }
    case MERGE_PLANTS_FAILED:
      return {
        ...state,
        mergingPlants: false,
        mergingPlantsSuccess: false,
        mergingPlantsFailure: true,
        errorMessage: action.payload.error,
      }
    case MERGE_PLANTS_SUCCEED:
      return {
        ...state,
        mergingPlants: false,
        mergingPlantsSuccess: true,
        mergingPlantsFailure: false,
      }
    case MERGE_PLANTS_PROCESSING:
      return {
        ...state,
        mergingPlants: true,
        mergingPlantsSuccess: false,
        mergingPlantsFailure: false,
      }
    default:
      return state
  }
}

export default plant
