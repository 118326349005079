import plantApi from 'api/modules/plant.api'
import {
  SELECT_SNAPP_PHOTO,
  HIDE_PLANT_ALERT,
} from '../constants/plant.constants'
export default {
  fetch(page, rowsPerPage, query, genusQuery) {
    return dispatch => {
      return dispatch(plantApi.fetch(page, rowsPerPage, query, genusQuery))
    }
  },
  get(id) {
    return dispatch => {
      dispatch(plantApi.get(id))
    }
  },
  getGenus(query) {
    return dispatch => {
      dispatch(plantApi.getGenus(query))
    }
  },
  getNamePlants(query) {
    return dispatch => {
      dispatch(plantApi.getNamePlants(query))
    }
  },
  count(query, genusQuery = '') {
    return dispatch => {
      dispatch(plantApi.count(query, genusQuery))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(plantApi.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(plantApi.delete(id))
    }
  },
  selectSnappPhoto(selectedPhoto, photos) {
    return dispatch => {
      dispatch({
        type: SELECT_SNAPP_PHOTO,
        payload: {
          photos: photos.map(photo =>
            photo.alt === selectedPhoto
              ? {
                  ...photo,
                  selected: true,
                }
              : {
                  ...photo,
                  selected: false,
                }
          ),
        },
      })
    }
  },
  saveSnappPhoto(plantId, snappId) {
    return dispatch => {
      dispatch(plantApi.saveSnappPhoto(plantId, snappId))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_PLANT_ALERT,
      })
    }
  },
  mergePlants(primary, selected) {
    return dispatch => {
      dispatch(plantApi.mergePlants(primary, selected))
    }
  },
}
