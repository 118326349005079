import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import logo from './smartplant-logo.png'

const Home = ({ state, actions }) => (
  <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <h1 className="App-title">Welcome to SmartPlant</h1>
    </header>
    <p className="App-intro">SmartPlant Dashboard</p>
  </div>
)

const mapStateToProps = state => ({
	isLoggedIn: state.auth.isLoggedIn,	
	userId: state.auth.userId,
	token: state.auth.token
})

export default withRouter(connect(mapStateToProps)(Home))
