import {
  COUNT_EXPERT_FAILED,
  COUNT_EXPERT_SUCCEED,
  COUNT_EXPERT_PROCESSING,
  FETCH_EXPERT_FAILED,
  FETCH_EXPERT_SUCCEED,
  FETCH_EXPERT_PROCESSING,
  GET_EXPERT_FAILED,
  GET_EXPERT_SUCCEED,
  GET_EXPERT_PROCESSING,
  FETCH_EXPERT_REGIONS_FAILED,
  FETCH_EXPERT_REGIONS_SUCCEED,
  FETCH_EXPERT_REGIONS_PROCESSING,
  SAVE_EXPERT_FAILED,
  SAVE_EXPERT_SUCCEED,
  SAVE_EXPERT_PROCESSING,
  DELETE_EXPERT_FAILED,
  DELETE_EXPERT_SUCCEED,
  DELETE_EXPERT_PROCESSING,
} from '../../constants'
import Parse from '../parse'

export const expert = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_EXPERT_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('ExpertDetails'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('expertName', searchText.trim(), 'i')
      }
      try {
        const total = await query.count()
        dispatch({
          type: COUNT_EXPERT_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_EXPERT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  list(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_EXPERT_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('ExpertDetails'))
      query.include('user')
      query.include('region')
      query.include('expertRegions')
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('expertName', searchText.trim(), 'i')
      }
      query.descending('updatedAt')
      query.limit(rowsPerPage)
      query.skip(page * rowsPerPage)
      try {
        const results = await query.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          let object = results[i]
          let userObject = object.get('user')
          /* let expertRegions = object.get("expertRegions")
          if (expertRegions) {
            var regionName = "";
            for (var j = 0; j < expertRegions.length; j++) {
              regionName += expertRegions[j].get("regionName")
              if ((expertRegions.length - 1) !== j) {
                regionName += ", "
              }
            }
          } */
          if (userObject) {
            var firstName = userObject.get('firstName')
            var lastName = userObject.get('lastName')
            var email = userObject.get('email')
          }
          list.push({
            [object.id]: {
              firstName: firstName,
              lastName: lastName,
              // "regionName": regionName,
              snappIdentifiedCount: object.get('snappIdentifiedCount'),
              email: email,
              job: object.get('job'),
              phoneNumber: object.get('phoneNumber'),
            },
          })
        }
        dispatch({
          type: FETCH_EXPERT_SUCCEED,
          payload: {
            list: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_EXPERT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_EXPERT_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('ExpertDetails'))
      query
        .include('user')
        .include('region')
        .include('expertRegions')
      try {
        const expert = await query.get(id)
        dispatch({
          type: GET_EXPERT_SUCCEED,
          payload: {
            record: expert.toJSON(),
          },
        })
      } catch (error) {
        dispatch({
          type: GET_EXPERT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getRegions(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_EXPERT_REGIONS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('expertName', searchText);
        query.matches('regionName', searchText.trim(), 'i')
      }
      query.limit(10000)
      try {
        const results = await query.find()
        var list = results.map(region => region.toJSON())
        dispatch({
          type: FETCH_EXPERT_REGIONS_SUCCEED,
          payload: {
            regions: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_EXPERT_REGIONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  save(values) {
    return async dispatch => {
      delete values.ACL
      delete values.user.ACL
      dispatch({
        type: SAVE_EXPERT_PROCESSING,
      })
      const ExpertDetails = Parse.Object.extend('ExpertDetails')
      let expertDetails = new ExpertDetails()
      if (values.objectId) {
        const expertDetailsQuery = new Parse.Query(ExpertDetails)
        try {
          expertDetails = await expertDetailsQuery.get(values.objectId)
        } catch (err) {
          console.error(err)
          return dispatch({
            type: SAVE_EXPERT_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      }

      let user

      const userQuery = new Parse.Query(Parse.User)
      try {
        user = await userQuery.get(values.user.value)
      } catch (err) {
        console.error(err)
        return dispatch({
          type: SAVE_EXPERT_FAILED,
          payload: {
            error: `Error: ${err.code} ${err.message}`,
          },
        })
      }

      try {
        var roleQuery = new Parse.Query(Parse.Role)
        roleQuery.equalTo('name', 'expert')
        const role = await roleQuery.first()
        role.relation('users').add(user)
        await role.save(null)
      } catch (err) {
        return dispatch({
          type: SAVE_EXPERT_FAILED,
          payload: {
            error: `Error: ${err.code} ${err.message}`,
          },
        })
      }

      try {
        expertDetails.save({ ...values, user: user.toPointer() })
      } catch (err) {
        return dispatch({
          type: SAVE_EXPERT_FAILED,
          payload: {
            error: `Error: ${err.code} ${err.message}`,
          },
        })
      }

      /* if (!values.user.objectId) {
        // Update user info before expert details.
        try {
          await Parse.Cloud.run('SendLoginDetailsExpert', {
            Expertemailid: values.email,
            Password: values.password,
          })
        } catch (error) {
          return dispatch({
            type: SAVE_EXPERT_FAILED,
            payload: {
              error: `Error: ${error.code} ${error.message}`,
            },
          })
        }
      } */

      return dispatch({
        type: SAVE_EXPERT_SUCCEED,
      })
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_EXPERT_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('ExpertDetails'))
      try {
        const experts = await query.containedIn('objectId', ids).find()
        await Parse.Object.destroyAll(experts)
        dispatch({
          type: DELETE_EXPERT_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: DELETE_EXPERT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
