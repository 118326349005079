export const COUNT_GENUS_PROCESSING = 'COUNT_GENUS_PROCESSING'
export const COUNT_GENUS_SUCCEED = 'COUNT_GENUS_SUCCEED'
export const COUNT_GENUS_FAILED = 'COUNT_GENUS_FAILED'
export const FETCH_GENUS_PROCESSING = 'FETCH_GENUS_PROCESSING'
export const FETCH_GENUS_SUCCEED = 'FETCH_GENUS_SUCCEED'
export const FETCH_GENUS_FAILED = 'FETCH_GENUS_FAILED'
export const FETCH_GENUS_CATEGORIES_PROCESSING =
  'FETCH_GENUS_CATEGORIES_PROCESSING'
export const FETCH_GENUS_CATEGORIES_SUCCEED = 'FETCH_GENUS_CATEGORIES_SUCCEED'
export const FETCH_GENUS_CATEGORIES_FAILED = 'FETCH_GENUS_CATEGORIES_FAILED'
export const GET_GENUS_PROCESSING = 'GET_GENUS_PROCESSING'
export const GET_GENUS_SUCCEED = 'GET_GENUS_SUCCEED'
export const GET_GENUS_FAILED = 'GET_GENUS_FAILED'
export const FETCH_GENUS_PRODUCTS_PROCESSING = 'FETCH_GENUS_PRODUCTS_PROCESSING'
export const FETCH_GENUS_PRODUCTS_SUCCEED = 'FETCH_GENUS_PRODUCTS_SUCCEED'
export const FETCH_GENUS_PRODUCTS_FAILED = 'FETCH_GENUS_PRODUCTS_FAILED'
export const SAVE_GENUS_PROCESSING = 'SAVE_GENUS_PROCESSING'
export const SAVE_GENUS_SUCCEED = 'SAVE_GENUS_SUCCEED'
export const SAVE_GENUS_FAILED = 'SAVE_GENUS_FAILED'
export const DELETE_GENUS_PROCESSING = 'DELETE_GENUS_PROCESSING'
export const DELETE_GENUS_SUCCEED = 'DELETE_GENUS_SUCCEED'
export const DELETE_GENUS_FAILED = 'DELETE_GENUS_FAILED'
export const HIDE_GENUS_ALERT = 'HIDE_GENUS_ALERT'
