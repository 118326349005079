import {
  COUNT_CATEGORY_FAILED,
  COUNT_CATEGORY_SUCCEED,
  COUNT_CATEGORY_PROCESSING,
  FETCH_CATEGORY_FAILED,
  FETCH_CATEGORY_SUCCEED,
  FETCH_CATEGORY_PROCESSING,
  GET_CATEGORY_FAILED,
  GET_CATEGORY_SUCCEED,
  GET_CATEGORY_PROCESSING,
  FETCH_CATEGORY_PRODUCTS_FAILED,
  FETCH_CATEGORY_PRODUCTS_SUCCEED,
  FETCH_CATEGORY_PRODUCTS_PROCESSING,
  SAVE_CATEGORY_FAILED,
  SAVE_CATEGORY_SUCCEED,
  SAVE_CATEGORY_PROCESSING,
  DELETE_CATEGORY_FAILED,
  DELETE_CATEGORY_SUCCEED,
  DELETE_CATEGORY_PROCESSING,
  HIDE_CATEGORY_ALERT,
} from '../constants/category.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  categories: [],
  products: [],
  record: null,
}

const category = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_CATEGORY_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case FETCH_CATEGORY_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: '',
      }
    case GET_CATEGORY_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case GET_CATEGORY_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_CATEGORY_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
      }
    case COUNT_CATEGORY_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_CATEGORY_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_CATEGORY_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_CATEGORY_PRODUCTS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CATEGORY_PRODUCTS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CATEGORY_PRODUCTS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        products: action.payload.products,
      }
    case SAVE_CATEGORY_FAILED:
      return {
        ...state,
        saving: false,
        saved: true,
        errorMessage: action.payload.error,
      }
    case SAVE_CATEGORY_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_CATEGORY_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_CATEGORY_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_CATEGORY_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_CATEGORY_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_CATEGORY_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        errorMessage: '',
        deleted: false,
      }
    default:
      return state
  }
}

export default category
