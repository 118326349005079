import { unmatchedBarcode as unmatchedBarcodeAPI } from '../api/modules/unmatchedBarcode.api'
import {
  SELECT_PLANT,
  HIDE_UNMATCHED_BARCODE_ALERT,
  TOGGLE_ADDING_NEW_PLANT,
  SET_UNMATCHED_BARCODE_PAGE,
} from '../constants/unmatchedBarcode.constants'
export const unmatchedBarcode = {
  count(query) {
    return dispatch => {
      return dispatch(unmatchedBarcodeAPI.count(query))
    }
  },
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      return dispatch(unmatchedBarcodeAPI.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(unmatchedBarcodeAPI.get(id))
    }
  },
  getNext(page) {
    return dispatch => {
      return dispatch(unmatchedBarcodeAPI.getNext(page))
    }
  },
  selectedPlant(plant) {
    return dispatch => {
      return dispatch({
        type: SELECT_PLANT,
        payload: {
          plant: plant,
        },
      })
    }
  },
  toggleAddingNewPlant(addingNewPlant) {
    return dispatch => {
      return dispatch({
        type: TOGGLE_ADDING_NEW_PLANT,
        payload: addingNewPlant,
      })
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(unmatchedBarcodeAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      return dispatch(unmatchedBarcodeAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      return dispatch({
        type: HIDE_UNMATCHED_BARCODE_ALERT,
      })
    }
  },
  setUnmatchedBarcodePage(currentPage) {
    return dispatch => {
      return dispatch({
        type: SET_UNMATCHED_BARCODE_PAGE,
        payload: currentPage,
      })
    }
  },
}
