import {
  COUNT_EXPERT_FAILED,
  COUNT_EXPERT_SUCCEED,
  COUNT_EXPERT_PROCESSING,
  FETCH_EXPERT_FAILED,
  FETCH_EXPERT_SUCCEED,
  FETCH_EXPERT_PROCESSING,
  NEW_EXPERT,
  GET_EXPERT_FAILED,
  GET_EXPERT_SUCCEED,
  GET_EXPERT_PROCESSING,
  FETCH_EXPERT_REGIONS_FAILED,
  FETCH_EXPERT_REGIONS_SUCCEED,
  FETCH_EXPERT_REGIONS_PROCESSING,
  SAVE_EXPERT_FAILED,
  SAVE_EXPERT_SUCCEED,
  SAVE_EXPERT_PROCESSING,
  DELETE_EXPERT_FAILED,
  DELETE_EXPERT_SUCCEED,
  DELETE_EXPERT_PROCESSING,
  HIDE_EXPERT_ALERT,
} from '../constants/expert.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  regions: [],
  record: {},
}

const expert = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXPERT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_EXPERT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case FETCH_EXPERT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: '',
      }
    case NEW_EXPERT:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: {},
        errorMessage: '',
      }
    case GET_EXPERT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case GET_EXPERT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case GET_EXPERT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        errorMessage: '',
      }
    case COUNT_EXPERT_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_EXPERT_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_EXPERT_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_EXPERT_REGIONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_EXPERT_REGIONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_EXPERT_REGIONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        regions: action.payload.regions,
      }
    case SAVE_EXPERT_FAILED:
      return {
        ...state,
        saving: false,
        saved: false,
        errorMessage: action.payload.error,
      }
    case SAVE_EXPERT_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_EXPERT_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_EXPERT_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_EXPERT_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_EXPERT_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_EXPERT_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        errorMessage: '',
        deleted: false,
      }
    default:
      return state
  }
}

export default expert
