import { categoryCareInfo as categoryCareInfoAPI } from '../api'
import { HIDE_CATEGORY_CARE_INFO_ALERT } from '../constants/categoryCareInfo.constants'
export const categoryCareInfo = {
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      dispatch(categoryCareInfoAPI.fetch(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      dispatch(categoryCareInfoAPI.get(id))
    }
  },
  getGenusCategories(query) {
    return dispatch => {
      dispatch(categoryCareInfoAPI.getGenusCategories(query))
    }
  },
  getMonths(query) {
    return dispatch => {
      dispatch(categoryCareInfoAPI.getMonths(query))
    }
  },
  getRegions() {
    return dispatch => {
      dispatch(categoryCareInfoAPI.getRegions())
    }
  },
  getProducts(query) {
    return dispatch => {
      dispatch(categoryCareInfoAPI.getProducts(query))
    }
  },
  getIcons(query) {
    return dispatch => {
      dispatch(categoryCareInfoAPI.getIcons(query))
    }
  },
  count(query) {
    return dispatch => {
      dispatch(categoryCareInfoAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(categoryCareInfoAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(categoryCareInfoAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_CATEGORY_CARE_INFO_ALERT,
      })
    }
  },
}
