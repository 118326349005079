import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, Menu, Button, MenuItem } from '@material-ui/core'
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons'

import { auth as authActions } from '../../actions'
import headerLinksStyle from '../../variables/styles/headerLinksStyle'
import userActions from 'actions/user.actions'
class HeaderLinks extends React.Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  }
  signOut = () => {
    this.props.logout()
  }
  render() {
    const { toggleSidebar, sidebarOpen } = this.props
    return (
      <>
        <Button onClick={() => toggleSidebar()}>
          {sidebarOpen ? <CloseIcon /> : <MenuIcon />}
        </Button>
        <Menu
          id="main-menu"
          anchorEl={this.menu}
          open={Boolean(this.menu)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.signOut}>Sign out</MenuItem>
        </Menu>
      </>
    )
  }
}

const mapStateToProps = state => ({
  sidebarOpen: state.user.sidebarOpen,
})
const mapDispatchToProps = { ...authActions, ...userActions }

export default withStyles(headerLinksStyle)(
  connect(mapStateToProps, mapDispatchToProps)(HeaderLinks)
)
