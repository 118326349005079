import { lazy } from 'react'

import {
  CameraAlt,
  LocalFlorist,
  ViewWeek,
  Star,
  Public,
  SortByAlpha,
  LocalOffer,
  Today,
  Person,
  People,
  Accessibility,
  Settings,
} from '@material-ui/icons'
import CareInfoForm from 'components/CareInfo/CareInfoForm'

const PlantForm = lazy(() => import(/* webpackChunkName: "PlantForm" */ '../components/Plant/PlantForm'))
const Genus = lazy(() => import(/* webpackChunkName: "GenusList" */ '../components/Genus/GenusList'))
const GenusForm = lazy(() => import(/* webpackChunkName: "GenusForm" */ '../components/Genus/GenusForm'))
const Product = lazy(() => import(/* webpackChunkName: "ProductList" */ '../components/Product/ProductList'))
const ProductForm = lazy(() => import(/* webpackChunkName: "ProductForm" */ '../components/Product/ProductForm'))
const SnappList = lazy(() => import(/* webpackChunkName: "SnappList" */ '../components/Snapp/SnappList'))
const SnappForm = lazy(() => import(/* webpackChunkName: "SnappForm" */ '../components/Snapp/SnappForm'))
const PlantList = lazy(() => import(/* webpackChunkName: "PlantList" */ '../components/Plant/PlantList'))
const PlantLocationList = lazy(() =>
  import(/* webpackChunkName: "PlantLocationList" */ '../components/PlantLocation/PlantLocationList')
)
const PlantLocationForm = lazy(() =>
  import(/* webpackChunkName: "PlantLocationForm" */ '../components/PlantLocation/PlantLocationForm')
)
const Barcode = lazy(() => import(/* webpackChunkName: "BarcodeList" */ '../components/Barcode/BarcodeList'))
const BarcodeForm = lazy(() => import(/* webpackChunkName: "BarcodeForm" */ '../components/Barcode/BarcodeForm'))
const BarcodeImport = lazy(() => import(/* webpackChunkName: "BarcodeImport" */ '../components/Barcode/BarcodeImport'))
const MatchBarcode = lazy(() => import(/* webpackChunkName: "MatchBarcode" */ '../components/Barcode/MatchBarcode'))
const Region = lazy(() => import(/* webpackChunkName: "RegionList" */ '../components/Region/RegionList'))
const RegionForm = lazy(() => import(/* webpackChunkName: "RegionForm" */ '../components/Region/RegionForm'))
const Category = lazy(() => import(/* webpackChunkName: "CategoryList" */ '../components/Category/CategoryList'))
const CategoryForm = lazy(() => import(/* webpackChunkName: "CategoryForm" */ '../components/Category/CategoryForm'))
const Retailer = lazy(() => import(/* webpackChunkName: "RetailerList" */ '../components/Retailer/RetailerList'))
const RetailerForm = lazy(() => import(/* webpackChunkName: "RetailerForm" */ '../components/Retailer/RetailerForm'))
const RetailersGroup = lazy(() =>
  import(/* webpackChunkName: "RetailersGroupList" */ '../components/RetailersGroup/RetailersGroupList')
)
const RetailersGroupForm = lazy(() =>
  import(/* webpackChunkName: "RetailersGroupForm" */ '../components/RetailersGroup/RetailersGroupForm')
)
const Expert = lazy(() => import(/* webpackChunkName: "ExpertList" */ '../components/Expert/ExpertList'))
const ExpertForm = lazy(() => import(/* webpackChunkName: "ExpertForm" */ '../components/Expert/ExpertForm'))
const Master = lazy(() => import(/* webpackChunkName: "Master" */ '../components/Master/Master'))
const MasterForm = lazy(() => import(/* webpackChunkName: "MasterForm" */ '../components/Master/MasterForm'))
const CareInfo = lazy(() => import(/* webpackChunkName: "CareInfoList" */ '../components/CareInfo/CareInfoList'))
const GenusCareInfo = lazy(() =>
  import(/* webpackChunkName: "GenusCareInfoList" */ '../components/GenusCareInfo/GenusCareInfoList')
)
const GenusCareInfoForm = lazy(() =>
  import(/* webpackChunkName: "GenusCareInfoForm" */ '../components/GenusCareInfo/GenusCareInfoForm')
)
const CategoryCareInfo = lazy(() =>
  import(/* webpackChunkName: "CategoryCareInfoList" */ '../components/CategoryCareInfo/CategoryCareInfoList')
)
const CategoryCareInfoForm = lazy(() =>
  import(/* webpackChunkName: "CategoryCareInfoForm" */ '../components/CategoryCareInfo/CategoryCareInfoForm')
)

const appRoutes = [
  {
    path: '/snapps',
    sidebarName: 'snapps',
    navbarName: 'snapps',
    icon: CameraAlt,
    component: SnappList,
    routes: [
      {
        action: 'add',
        path: '/snapps/add',
        component: SnappForm,
      },
      {
        action: 'edit',
        path: '/snapps/edit/:id',
        component: SnappForm,
      },
      {
        action: 'delete',
        path: '/snapps/delete/:id',
        component: SnappList,
      },
    ],
  },
  {
    path: '/plant',
    sidebarName: 'plant',
    navbarName: 'plant',
    icon: LocalFlorist,
    component: PlantList,
    routes: [
      {
        action: 'add',
        path: '/plant/add',
        component: PlantForm,
      },
      {
        action: 'edit',
        path: '/plant/edit/:id',
        component: PlantForm,
      },
      {
        action: 'delete',
        path: '/plant/delete/:id',
        component: PlantList,
      },
    ],
  },
  {
    path: '/plantlocation',
    sidebarName: 'plantlocation',
    navbarName: 'plant',
    icon: LocalFlorist,
    component: PlantLocationList,
    routes: [
      {
        action: 'add',
        path: '/plantlocation/add',
        component: PlantLocationForm,
      },
      {
        action: 'edit',
        path: '/plantlocation/edit/:id',
        component: PlantLocationForm,
      },
      {
        action: 'delete',
        path: '/plantlocation/delete/:id',
        component: PlantLocationList,
      },
    ],
  },
  {
    path: '/barcode',
    sidebarName: 'barcode',
    navbarName: 'barcode',
    icon: ViewWeek,
    component: Barcode,
    routes: [
      {
        action: 'add',
        path: '/barcode/add',
        component: BarcodeForm,
      },
      {
        action: 'edit',
        path: '/barcode/edit/:id',
        component: BarcodeForm,
      },
      {
        action: 'delete',
        path: '/barcode/delete/:id',
        component: Barcode,
      },
      {
        action: 'import',
        path: '/barcode/import',
        component: BarcodeImport,
      },
      {
        action: 'match',
        path: '/barcode/match',
        component: MatchBarcode,
      },
      {
        action: 'match-index',
        path: '/barcode/match/:index',
        component: MatchBarcode,
      },
    ],
  },
  {
    path: '/product',
    sidebarName: 'product',
    navbarName: 'product',
    icon: Star,
    component: Product,
    routes: [
      {
        action: 'add',
        path: '/product/add',
        component: ProductForm,
      },
      {
        action: 'edit',
        path: '/product/edit/:id',
        component: ProductForm,
      },
      {
        action: 'delete',
        path: '/product/delete/:id',
        component: Product,
      },
    ],
    breakLine: true,
  },
  {
    path: '/regions',
    sidebarName: 'regions',
    navbarName: 'regions',
    icon: Public,
    component: Region,
    routes: [
      {
        action: 'add',
        path: '/regions/add',
        component: RegionForm,
      },
      {
        action: 'edit',
        path: '/regions/edit/:id',
        component: RegionForm,
      },
      {
        action: 'delete',
        path: '/regions/delete/:id',
        component: Region,
      },
    ],
  },
  {
    path: '/genus',
    sidebarName: 'genus',
    navbarName: 'genus',
    icon: SortByAlpha,
    component: Genus,
    routes: [
      {
        action: 'add',
        path: '/genus/add',
        component: GenusForm,
      },
      {
        action: 'edit',
        path: '/genus/edit/:id',
        component: GenusForm,
      },
      {
        action: 'delete',
        path: '/genus/delete/:id',
        component: Genus,
      },
    ],
  },
  {
    path: '/categories',
    sidebarName: 'categories',
    navbarName: 'categories',
    icon: LocalOffer,
    component: Category,
    routes: [
      {
        action: 'add',
        path: '/categories/add',
        component: CategoryForm,
      },
      {
        action: 'edit',
        path: '/categories/edit/:id',
        component: CategoryForm,
      },
      {
        action: 'delete',
        path: '/categories/delete/:id',
        component: Category,
      },
    ],
  },
  {
    path: '/careinfo',
    sidebarName: 'careinfo',
    navbarName: 'careinfo',
    icon: LocalOffer,
    component: CareInfo,
    routes: [
      {
        action: 'add',
        path: '/careinfo/add',
        component: CareInfoForm,
      },
      {
        action: 'edit',
        path: '/careinfo/edit/:id',
        component: CareInfoForm,
      },
      {
        action: 'delete',
        path: '/careinfo/delete/:id',
        component: Category,
      },
    ],
  },
  {
    path: '#care_calendar',
    sidebarName: 'care calendar',
    navbarName: 'care calendar',
    icon: Today,
    // component: DashboardPage,
    children: [
      {
        path: '/categoryCareInfo',
        sidebarName: 'category',
        navbarName: 'category',
        icon: LocalOffer,
        component: CategoryCareInfo,
        routes: [
          {
            action: 'add',
            path: '/categoryCareInfo/add',
            component: CategoryCareInfoForm,
          },
          {
            action: 'edit',
            path: '/categoryCareInfo/edit/:id',
            component: CategoryCareInfoForm,
          },
          {
            action: 'delete',
            path: '/categoryCareInfo/delete/:id',
            component: CategoryCareInfo,
          },
        ],
      },
      {
        path: '/genusCareInfo',
        sidebarName: 'genus',
        navbarName: 'genus',
        icon: SortByAlpha,
        component: GenusCareInfo,
        routes: [
          {
            action: 'add',
            path: '/genusCareInfo/add',
            component: GenusCareInfoForm,
          },
          {
            action: 'edit',
            path: '/genusCareInfo/edit/:id',
            component: GenusCareInfoForm,
          },
          {
            action: 'delete',
            path: '/genusCareInfo/delete/:id',
            component: GenusCareInfo,
          },
        ],
      },
    ],
    breakLine: true,
  },
  {
    path: '/retailers',
    sidebarName: 'retailers',
    navbarName: 'retailer',
    icon: Person,
    component: Retailer,
    routes: [
      {
        action: 'add',
        path: '/retailers/add',
        component: RetailerForm,
      },
      {
        action: 'edit',
        path: '/retailers/edit/:id',
        component: RetailerForm,
      },
      {
        action: 'delete',
        path: '/retailers/delete/:id',
        component: Retailer,
      },
    ],
  },
  {
    path: '/retailersGroups',
    sidebarName: 'retailer groups',
    navbarName: 'retailer groups',
    icon: People,
    component: RetailersGroup,
    routes: [
      {
        action: 'add',
        path: '/retailersGroups/add',
        component: RetailersGroupForm,
      },
      {
        action: 'edit',
        path: '/retailersGroups/edit/:id',
        component: RetailersGroupForm,
      },
      {
        action: 'delete',
        path: '/retailersGroups/delete/:id',
        component: RetailersGroup,
      },
    ],
    breakLine: true,
  },
  {
    path: '/expert',
    sidebarName: 'expert',
    navbarName: 'expert',
    icon: Accessibility,
    component: Expert,
    routes: [
      {
        action: 'add',
        path: '/expert/add',
        component: ExpertForm,
      },
      {
        action: 'edit',
        path: '/expert/edit/:id',
        component: ExpertForm,
      },
      {
        action: 'delete',
        path: '/expert/delete/:id',
        component: Expert,
      },
    ],
  },
  // {
  //   path: '/user',
  //   sidebarName: 'user',
  //   navbarName: 'user',
  //   icon: Face,
  //   component: User,
  //   routes: [
  //     {
  //       action: 'add',
  //       path: '/user/add',
  //       component: UserForm,
  //     },
  //     {
  //       action: 'edit',
  //       path: '/user/edit/:id',
  //       component: UserForm,
  //     },
  //     {
  //       action: 'delete',
  //       path: '/user/delete/:id',
  //       component: User,
  //     },
  //   ],
  //   breakLine: true,
  // },
  {
    path: '/master',
    sidebarName: 'master',
    navbarName: 'master',
    icon: Settings,
    component: Master,
    routes: [
      {
        action: 'add',
        path: '/master/add',
        component: MasterForm,
      },
      {
        action: 'edit',
        path: '/master/edit/:id',
        component: MasterForm,
      },
      {
        action: 'delete',
        path: '/master/delete/:id',
        component: Master,
      },
    ],
  },
  // {
  //   path: '/notifications',
  //   sidebarName: 'notifications',
  //   navbarName: 'notifications',
  //   icon: Notifications,
  //   component: DashboardPage,
  // },
  // {
  //   path: '/reports',
  //   sidebarName: 'reports',
  //   navbarName: 'reports',
  //   icon: Info,
  //   component: Report,
  // },
  // {
  //   path: '/marketing',
  //   sidebarName: 'marketing',
  //   navbarName: 'marketing',
  //   icon: Message,
  //   component: Marketing,
  // },
  {
    redirect: true,
    path: '/',
    to: '/dashboard',
    navbarName: 'Redirect',
  },
]

export default appRoutes
