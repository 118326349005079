import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Menu } from '@material-ui/icons'
import { AppBar, Toolbar, IconButton, Hidden, withStyles } from '@material-ui/core'
import cx from 'classnames'

import userActions from 'actions/user.actions'
import headerStyle from '../../variables/styles/headerStyle.jsx'

import HeaderLinks from './HeaderLinks'

class Header extends PureComponent {
  static propTypes = {
    toggleSidebar: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
  }

  makeBrand() {
    var name = 'SmartPlant'
    return name
  }

  handleDrawerToggle = () => {
    this.props.toggleSidebar()
  }

  render() {
    const { classes, color } = this.props
    const appBarClasses = cx({
      [' ' + classes[color]]: color,
    })

    return (
      <AppBar className={classes.appBar + appBarClasses} position="sticky">
        <Toolbar>
          <div className={classes.flex}></div>
          <Hidden smDown implementation="css">
            <HeaderLinks />
          </Hidden>
          <Hidden mdUp>
            <IconButton
              className={classes.appResponsive}
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  ...userActions,
}

export default withStyles(headerStyle)(connect(mapStateToProps, mapDispatchToProps)(Header))
