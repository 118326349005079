import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qs from 'query-string'
import { genusCareInfo } from '../../actions'
import CustomTable from '../Table/CustomTable'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button, withStyles } from '@material-ui/core'
import debounce from 'lodash.debounce'
import GenusCareInfoForm from './GenusCareInfoForm'
import { overlay, loadingSpinner } from '../../variables/styles'
import CustomSnackbar from 'components/CustomSnackbar'

class GenusCareInfoList extends Component {
  state = {
    page: 0,
    rowsPerPage: 10,
    fields: [
      { id: 'genus', label: 'Genus' },
      {
        id: 'careField1',
        label: 'Action',
      },
      {
        id: 'careField2',
        label: 'Try This',
      },
      {
        id: 'careField3',
        label: 'Recommendations',
      },
      {
        id: 'monthId',
        label: 'Months',
      },
    ],
  }
  static propTypes = {
    total: PropTypes.number,
    list: PropTypes.array,
    searchText: PropTypes.string,
    errorMessage: PropTypes.string,
    deleted: PropTypes.bool,
    history: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    fetching: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
  }
  componentDidMount() {
    this.props.dispatch(genusCareInfo.count())
    this._getGenusCareInfo()
  }
  _getGenusCareInfo() {
    const query = qs.parse(this.props.location.search)
    const searchText = this.props.searchText || query.searchText || ''
    this.props.dispatch(
      genusCareInfo.fetch(this.state.page, this.state.rowsPerPage, searchText)
    )
  }
  handleChangePage = page => {
    let that = this
    this.setState({ page: page }, () => {
      that._getGenusCareInfo()
    })
  }
  handleSearch = debounce(query => {
    const search = qs.stringify({
      searchText: query,
    })
    this.props.history.push({ search })
    this.props.dispatch(genusCareInfo.count(query))
    this.props.dispatch(
      genusCareInfo.list(this.state.page, this.state.rowsPerPage, query)
    )
  }, 1000)
  handleChangeRowsPerPage = rowsPerPage => {
    let that = this
    this.setState({ rowsPerPage: rowsPerPage }, () => {
      that._getGenusCareInfo()
    })
  }
  handleDeleteAction = ids => {
    this.props.dispatch(genusCareInfo.delete(ids))
  }
  hideAlert() {
    this.props.dispatch(genusCareInfo.hideAlert())
  }
  render() {
    const {
      total,
      list,
      errorMessage,
      history,
    } = this.props
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(errorMessage)}
          message={errorMessage && errorMessage.message}
          handleClose={() => this.handleCloseError()}
        />
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push('/genusCareInfo/add')}
          >
            <AddIcon /> Add New
          </Button>
        </div>
        <CustomTable
          title={'Care Calendar Genus'}
          total={total}
          history={history}
          tableHeaderColor="primary"
          tableHead={this.state.fields}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDeleteAction}
          data={list}
          duplicateForm={<GenusCareInfoForm />}
          editPath="/genusCareInfo/edit/"
          actions={[
            {
              label: 'edit',
              icon: <EditIcon />,
              path: '/genusCareInfo/edit/',
              has_id: true,
              color: 'primary',
            },
            {
              label: 'delete',
              icon: <DeleteIcon />,
              has_id: true,
              color: 'secondary',
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  total: state.genusCareInfo.total,
  list: state.genusCareInfo.list,
  deleted: state.genusCareInfo.deleted,
  errorMessage: state.genusCareInfo.errorMessage,
  searchText: state.genusCareInfo.searchText,
})

const styles = () => ({
  loadingSpinner,
  overlay,
})

export default withStyles(styles)(connect(mapStateToProps)(GenusCareInfoList))
