// ##############################
// // // RegularCard styles
// #############################

import {
  card,
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
} from '../styles'

const regularCardStyle = {
  card,
  cardPlain: {
    // background: 'transparent',
    boxShadow: 'none',
  },
  cardHeader: {},
  cardPlainHeader: {
    marginLeft: 0,
    marginRight: 0,
  },
  orangeCardHeader,
  greenCardHeader,
  redCardHeader,
  blueCardHeader,
  purpleCardHeader,
  cardTitle: {
    color: '#FFFFFF',
    marginTop: '0',
    marginBottom: '5px',
    fontSize: '1.125em',
    textAlign: 'center',
  },
  cardSubtitle: {
    marginBottom: '0',
    color: 'rgba(255, 255, 255, 0.62)',
    margin: '0 0 10px',
    textAlign: 'center',
  },
  cardActions: {
    padding: '14px',
    display: 'block',
    height: 'auto',
  },
  media: {
    paddingTop: '128px',
  },
}

export default regularCardStyle
