import {
  SEND_PUSH_NOTIFICATION_PROCESSING,
  SEND_PUSH_NOTIFICATION_SUCCEED,
  SEND_PUSH_NOTIFICATION_FAILED,
  DISMISS_PUSH_MARKETING_SUCCEED,
  SEND_EMAIL_MARKETING_PROCESSING,
  SEND_EMAIL_MARKETING_SUCCEED,
  SEND_EMAIL_MARKETING_FAILED,
  DISMISS_EMAIL_MARKETING_SUCCEED,
  GET_EMAIL_TEMPLATES_PROCESSING,
  GET_EMAIL_TEMPLATES_SUCCEED,
  GET_EMAIL_TEMPLATES_FAILED
} from '../constants'

const initialState = {
  sendingPushNotification: false,
  sentPushNotification: false,
  failedPushNotification: false,
  sendingEmailNotification: false,
  sentEmailNotification: false,
  failedEmailNotification: false,
  fetchingEmailTemplates: false,
  fetchedEmailTemplates: false,
  failedEmailTemplates: false,
  errorMessage: '',
  emailTemplates: []
}

const plant = (state = initialState, action) => {
  switch (action.type) {
    case SEND_PUSH_NOTIFICATION_PROCESSING:
      return {
        ...state,
        sendingPushNotification: true,
        sentPushNotification: false,
        failedPushNotification: false
      }
    case SEND_PUSH_NOTIFICATION_SUCCEED:
      return {
        ...state,
        sendingPushNotification: false,
        sentPushNotification: true,
        failedPushNotification: false
      }
    case SEND_PUSH_NOTIFICATION_FAILED:
      return {
        ...state,
        sendingPushNotification: false,
        sentPushNotification: false,
        failedPushNotification: true,
        errorMessage: action.payload.error.message
      }
    case DISMISS_PUSH_MARKETING_SUCCEED:
      return {
        ...state,
        sentPushNotification: false
      }
    case SEND_EMAIL_MARKETING_PROCESSING:
      return {
        ...state,
        sendingEmailNotification: true,
        sentEmailNotification: false,
        failedEmailNotification: false
      }
    case SEND_EMAIL_MARKETING_SUCCEED:
      return {
        ...state,
        sendingEmailNotification: false,
        sentEmailNotification: true,
        failedEmailNotification: false
      }
    case SEND_EMAIL_MARKETING_FAILED:
      return {
        ...state,
        sendingEmailNotification: false,
        sentEmailNotification: false,
        failedEmailNotification: true,
        errorMessage: action.payload.error.message
      }
    case DISMISS_EMAIL_MARKETING_SUCCEED:
      return {
        ...state,
        sentEmailNotification: false
      }
    case GET_EMAIL_TEMPLATES_PROCESSING:
      return {
        ...state,
        fetchingEmailTemplates: true,
        fetchedEmailTemplates: false,
        failedEmailTemplates: false
      }
    case GET_EMAIL_TEMPLATES_SUCCEED:
      return {
        ...state,
        fetchingEmailTemplates: false,
        fetchedEmailTemplates: true,
        failedEmailTemplates: false,
        emailTemplates: action.payload.emailTemplates
      }
    case GET_EMAIL_TEMPLATES_FAILED:
      return {
        ...state,
        fetchingEmailTemplates: false,
        fetchedEmailTemplates: false,
        failedEmailTemplates: true,
        errorMessage: action.payload.error.message
      }
    default:
      return state
  }
}

export default plant
