import {
  COUNT_SNAPP_FAILED,
  COUNT_SNAPP_SUCCEED,
  COUNT_SNAPP_PROCESSING,
  FETCH_SNAPP_FAILED,
  FETCH_SNAPP_SUCCEED,
  FETCH_SNAPP_PROCESSING,
  GET_SNAPP_FAILED,
  GET_SNAPP_SUCCEED,
  GET_SNAPP_PROCESSING,
  FETCH_SNAPP_USERS_FAILED,
  FETCH_SNAPP_USERS_SUCCEED,
  FETCH_SNAPP_USERS_PROCESSING,
  SAVE_SNAPP_FAILED,
  SAVE_SNAPP_SUCCEED,
  SAVE_SNAPP_PROCESSING,
  DELETE_SNAPP_FAILED,
  DELETE_SNAPP_SUCCEED,
  DELETE_SNAPP_PROCESSING,
  ASSIGN_EXPERT_FAILED,
  ASSIGN_EXPERT_SUCCEED,
  ASSIGN_EXPERT_PROCESSING,
  ASSIGN_PLANT_FAILED,
  ASSIGN_PLANT_SUCCEED,
  ASSIGN_PLANT_PROCESSING,
} from '../../constants'
import Parse from '../parse'
import moment from 'moment'

export const snapp = {
  count(searchText) {
    return async (dispatch) => {
      dispatch({
        type: COUNT_SNAPP_PROCESSING,
      })
      let innerQuery = new Parse.Query(Parse.Object.extend('_User'))
      let query = new Parse.Query(Parse.Object.extend('Snapp'))
      query.descending('createdAt')
      searchText = searchText || ''
      if (searchText !== '') {
        innerQuery.matches('email', searchText.trim(), 'i')
        query.matchesQuery('snappUser', innerQuery)
      }
      try {
        const total = await query.count()
        return dispatch({
          type: COUNT_SNAPP_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        return dispatch({
          type: COUNT_SNAPP_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  fetch(page, rowsPerPage, searchText) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_SNAPP_PROCESSING,
      })
      let innerQuery = new Parse.Query(Parse.Object.extend('_User'))
      let query = new Parse.Query(Parse.Object.extend('Snapp'))
      query.include('snappUser').include('region').descending('createdAt')
      searchText = searchText || ''
      if (searchText !== '') {
        innerQuery.matches('email', searchText.trim(), 'i')
        query.matchesQuery('snappUser', innerQuery)
      }
      try {
        const results = await query
          .limit(rowsPerPage)
          .skip(page * rowsPerPage)
          .find()
        var list = []
        results.forEach((object) => {
          const sendForIdentificationDate = object.get('sendForIdentification')
          let sendForIdentification = ''
          if (sendForIdentificationDate) sendForIdentification = moment(sendForIdentificationDate).fromNow()
          const image = object.get('thumbImage')
          let snappImage = ''
          if (image) {
            snappImage = image.url()
          }
          let snappRegion = ''
          let region = object.get('region')
          if (region) {
            snappRegion = region.get('regionName')
          }
          let user = ''
          let snappUser = object.get('snappUser')
          if (snappUser) {
            user = object.get('snappUser').get('username')
          }
          list.push({
            [object.id]: {
              snappImage,
              user,
              snappType: object.get('snappType'),
              snappRegion,
              sendForIdentification,
            },
          })
        })
        return dispatch({
          type: FETCH_SNAPP_SUCCEED,
          payload: { list },
        })
      } catch (error) {
        return dispatch({
          type: FETCH_SNAPP_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  get(id) {
    return async (dispatch) => {
      dispatch({
        type: GET_SNAPP_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Snapp'))
      let parseSnapp = {}
      try {
        parseSnapp = await query.include('expertIdentifiedPlant').include('expert').include('assignedExpert').get(id)
      } catch (error) {
        return dispatch({
          type: GET_SNAPP_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      let selectedPlant = parseSnapp.get('expertIdentifiedPlant')
        ? {
            value: parseSnapp.get('expertIdentifiedPlant').id,
            label: parseSnapp.get('expertIdentifiedPlant').get('commonName'),
            plantDescription: parseSnapp.get('expertIdentifiedPlant').get('plantDescription'),
          }
        : { value: '', label: '' }
      let selectedUser = parseSnapp.get('assignedExpert')
        ? {
            value: parseSnapp.get('assignedExpert').id,
            label:
              parseSnapp.get('assignedExpert').get('firstName') +
              ' ' +
              parseSnapp.get('assignedExpert').get('lastName'),
          }
        : { value: '', label: '' }
      let regionName = ''
      let region = parseSnapp.get('region')
      if (region) {
        regionName = region.get('regionName')
      }
      const image = parseSnapp.get('snappImage')
      let snappImage = ''
      if (image) {
        snappImage = image.url()
      }
      const record = {
        id,
        snappType: parseSnapp.get('snappType'),
        regionName,
        createdAt: parseSnapp.get('createdAt'),
        snappImage,
      }
      return dispatch({
        type: GET_SNAPP_SUCCEED,
        payload: {
          record,
          selectedPlant,
          selectedUser,
        },
      })
    }
  },
  getUsers(searchText) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_SNAPP_USERS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('ExpertDetails'))
      query.include('user')
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('snappName', searchText);
        query.matches('username', searchText.trim(), 'i')
      }
      let results = []
      try {
        results = await query.limit(50).find()
      } catch (error) {
        return dispatch({
          type: FETCH_SNAPP_USERS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      results.forEach((object) => {
        const parseUser = object.get('user')
        if (parseUser && parseUser.id) {
          const value = parseUser.id
          const label = parseUser.get('firstName') + ' ' + parseUser.get('lastName')
          list.push({ value, label })
        }
      })
      return dispatch({
        type: FETCH_SNAPP_USERS_SUCCEED,
        payload: {
          users: list,
        },
      })
    }
  },
  save(values) {
    return async (dispatch) => {
      dispatch({
        type: SAVE_SNAPP_PROCESSING,
      })
      let SnappModel = new Parse.Object.extend('Snapp')
      let snapp = new SnappModel()
      if (values.object_id) {
        snapp.id = values.object_id
      }
      // The file has been saved to Parse.
      for (var k in values) {
        if (k !== 'object_id') {
          if (k === 'Regions' && values[k]) {
            var users = []
            var list = values[k]
            for (var i = 0; i < list.length; i++) {
              let RegionModel = new Parse.Object.extend('Region')
              let region = new RegionModel()
              region.id = list[i]
              users.push(region)
            }
            snapp.set(k, users)
          } else {
            snapp.set(k, values[k])
          }
        }
      }
      try {
        if (values['snappImage'] && typeof values['snappImage'] === 'object') {
          let fileName = values['snappImage'].name
          let parseFile = new Parse.File(fileName, values['snappImage'])
          await parseFile.save()
          snapp.set('snappImage', parseFile)
          await snapp.save(null)
          dispatch({
            type: SAVE_SNAPP_SUCCEED,
          })
        } else {
          await snapp.save(null)
          dispatch({
            type: SAVE_SNAPP_SUCCEED,
          })
        }
      } catch (error) {
        dispatch({
          type: SAVE_SNAPP_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async (dispatch) => {
      dispatch({
        type: DELETE_SNAPP_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Snapp'))
      query
        .containedIn('objectId', ids)
        .find()
        .then((snapps) => {
          Parse.Object.destroyAll(snapps)
            .then(() => {
              dispatch({
                type: DELETE_SNAPP_SUCCEED,
              })
            })
            .catch((error) => {
              dispatch({
                type: DELETE_SNAPP_FAILED,
                payload: {
                  error: `Error: ${error.code} ${error.message}`,
                },
              })
            })
        })
        .catch((error) => {
          dispatch({
            type: DELETE_SNAPP_FAILED,
            payload: {
              error: `Error: ${error.code} ${error.message}`,
            },
          })
        })
    }
  },
  assignPlant(id, plantId, commentPlant = 'None') {
    return async (dispatch) => {
      dispatch({
        type: ASSIGN_PLANT_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Snapp'))
      const snapp = await query.get(id, {})
      // Save comment first
      let snappCommentsModel = Parse.Object.extend('SnappComments')
      let comments = new snappCommentsModel()
      try {
        await snapp.save({
          comments,
          snapp,
          expert: Parse.User.current(),
        })
      } catch (error) {
        return dispatch({
          type: ASSIGN_PLANT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      let plantModel = Parse.Object.extend('Plant')
      let expertIdentifiedPlant = new plantModel()
      let expertIdentifiedDate = new Date()
      expertIdentifiedPlant.id = plantId
      const savedSnapp = await snapp.save({
        expertIdentifiedDate,
        expertIdentifiedPlant,
        expert: Parse.User.current(),
        userCommentStatus: false,
        isSnappIdentified: true,
      })
      var plantPhotoModel = Parse.Object.extend('PlantPhoto')
      var plantPhoto = new Parse.Query(plantPhotoModel)
      plantPhoto.equalTo('plantId', expertIdentifiedPlant)
      const responsecount = await plantPhoto.count()
      if (responsecount <= 0) {
        let plantPhoto = new Parse.Object('PlantPhoto')
        try {
          await plantPhoto.save({
            plantId,
            plantImage: savedSnapp.get('snappImage'),
          })
          await this.sendEmailNotification(savedSnapp.id, commentPlant)
          dispatch({
            type: ASSIGN_PLANT_SUCCEED,
          })
        } catch (error) {
          return dispatch({
            type: ASSIGN_PLANT_FAILED,
            payload: {
              error: `Error: ${error.code} ${error.message}`,
            },
          })
        }
      } else {
        // console.log("Already the plant has photo")
        dispatch({
          type: ASSIGN_PLANT_SUCCEED,
        })
        try {
          await this.sendEmailNotification(snapp.id, commentPlant)
          dispatch({
            type: ASSIGN_PLANT_SUCCEED,
          })
        } catch (error) {
          return dispatch({
            type: ASSIGN_PLANT_FAILED,
            payload: {
              error: `Error: ${error.code} ${error.message}`,
            },
          })
        }
      }
    }
  },
  assignExpert(id, expertId, commentExpert) {
    return async (dispatch) => {
      dispatch({
        type: ASSIGN_EXPERT_PROCESSING,
      })
      let userQuery = new Parse.Query(Parse.User)
      const user = await userQuery.get(expertId)
      let snappQuery = new Parse.Query(Parse.Object.extend('Snapp'))
      let snapp = {}
      try {
        snapp = await snappQuery.get(id)
      } catch (error) {
        dispatch({
          type: ASSIGN_EXPERT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      snapp.set('assignedExpert', user)
      if (commentExpert) {
        snapp.set('assignedComments', '<b>' + Parse.User.current().get('firstName') + '</b> : ' + commentExpert)
      }
      try {
        await snapp.save(null)
      } catch (error) {
        dispatch({
          type: ASSIGN_EXPERT_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      await Parse.Cloud.run('sendEmailSnappAssignedExpert', {
        email: user.get('email'),
        assignedBy: Parse.User.current().get('firstName'),
      })
      dispatch({
        type: ASSIGN_EXPERT_SUCCEED,
      })
    }
  },
  sendEmailNotification(snappId, comment) {
    return new Promise((resolve, reject) => {
      var categoryProductArray = []
      var productArray = []
      var temProductArray = []
      var arr = []
      let snappModel = Parse.Object.extend('Snapp')
      let snapp = new Parse.Query(snappModel)
      snapp.equalTo('objectId', snappId)
      snapp.include('expertIdentifiedPlant.genus.genusProduct.categoryType.recommendedProducts')
      snapp.include('snappUser.region')
      snapp.include('expertIdentifiedPlant.genus.product')
      snapp.include('expertIdentifiedPlant.genus.categoryType.recommendedProducts')
      snapp.descending('updatedAt')
      snapp
        .first()
        .then((results) => {
          let userObject = results.get('snappUser')
          let regionObject = userObject.get('region')
          let plantObject = results.get('expertIdentifiedPlant')
          var genusObject = plantObject.get('genus')
          if (genusObject) {
            var genusProduct = genusObject.get('genusProduct')
            if (genusProduct) {
              for (var i = 0; i < genusProduct.length; i++) {
                var genusProductRegionArray = []
                if (genusProduct[i] && genusProduct[i].get('Regions') !== undefined) {
                  for (var j = 0; j < genusProduct[i].get('Regions').length; j++) {
                    genusProductRegionArray.push(genusProduct[i].get('Regions')[j].id)
                  }
                  if (regionObject && genusProduct[i].get('Regions')) {
                    if (genusProductRegionArray.indexOf(regionObject.id) !== -1) {
                      productArray.push({
                        productImage: genusProduct[i].get('productImage').url(),
                        productName: genusProduct[i].get('productName'),
                        productLink: genusProduct[i].get('productLink'),
                        updateAt: genusProduct[i].get('updateAt'),
                      })
                    }
                  }
                }
              }
              // productArray = _.sortBy(productArray, function(o) { var dt = new Date(o.updateAt); return -dt; });
            } else {
              genusProduct = []
            }

            if (productArray.length < 3) {
              let categoryObject = genusObject.get('categoryType')
              if (categoryObject) {
                let categoryProduct = categoryObject.get('recommendedProducts')
                if (categoryProduct) {
                  var limit = 3 - productArray.length
                  for (var k = 0; k < categoryProduct.length; k++) {
                    var categoryProductRegionArray = []
                    if (categoryProduct[k] && categoryProduct[k].get('Regions') !== undefined) {
                      for (var l = 0; l < categoryProduct[k].get('Regions').length; l++) {
                        categoryProductRegionArray.push(categoryProduct[k].get('Regions')[l].id)
                      }
                      if (regionObject && categoryProduct[k].get('Regions')) {
                        if (categoryProductRegionArray.indexOf(regionObject.id) !== -1) {
                          categoryProductArray.push({
                            productImage: categoryProduct[k].get('productImage').url(),
                            productName: categoryProduct[k].get('productName'),
                            productLink: categoryProduct[k].get('productLink'),
                            updateAt: categoryProduct[k].get('updateAt'),
                          })
                        }
                      }
                    }
                  }
                  // categoryProductArray = _.sortBy(categoryProductArray, function(o) { var dt = new Date(o.updateAt); return -dt; });
                  if (categoryProductArray.length <= limit) {
                    limit = categoryProductArray.length
                  }

                  for (var m = 0; m < limit; m++) {
                    temProductArray.push({
                      productImage: categoryProductArray[m].productImage,
                      productName: categoryProductArray[m].productName,
                      productLink: categoryProductArray[m].productLink,
                      updateAt: categoryProductArray[m].get('updateAt'),
                    })
                  }

                  arr = [].concat(productArray, temProductArray)
                } else {
                  arr = productArray
                }
              } else {
                arr = productArray
              }
            } else {
              arr = productArray
            }
          }
          Parse.Cloud.run('SendIdentificationEmailTemplatePushNotification', {
            snappId: snappId,
            expertComment: comment,
            type: 'snappIdentify',
            productArr: arr,
          })
            .then((result) => {
              resolve(result)
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
