import {
  COUNT_BARCODE_FAILED,
  COUNT_BARCODE_SUCCEED,
  COUNT_BARCODE_PROCESSING,
  FETCH_BARCODE_FAILED,
  FETCH_BARCODE_SUCCEED,
  FETCH_BARCODE_PROCESSING,
  GET_BARCODE_FAILED,
  GET_BARCODE_SUCCEED,
  GET_BARCODE_PROCESSING,
  FETCH_BARCODE_RETAILERSGROUPS_FAILED,
  FETCH_BARCODE_RETAILERSGROUPS_SUCCEED,
  FETCH_BARCODE_RETAILERSGROUPS_PROCESSING,
  SAVE_BARCODE_FAILED,
  SAVE_BARCODE_SUCCEED,
  SAVE_BARCODE_PROCESSING,
  DELETE_BARCODE_FAILED,
  DELETE_BARCODE_SUCCEED,
  DELETE_BARCODE_PROCESSING,
  IMPORT_BARCODE_FAILED,
  IMPORT_BARCODE_SUCCEED,
  IMPORT_BARCODE_PROCESSING,
  SELECT_PLANT,
  HIDE_BARCODE_ALERT,
} from '../constants/barcode.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  importing: false,
  imported: false,
  errorMessage: '',
  list: [],
  total: 0,
  retailersGroups: [],
  plantDescription: '',
  currentPage: 0,
  record: {
    id: '',
    name: '',
    description: '',
    barcode: '',
    plant: '',
    retailersGroups: '',
  },
}

const barcode = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BARCODE_FAILED:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload.error,
        list: [],
      }
    case FETCH_BARCODE_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case FETCH_BARCODE_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: '',
        deleted: false,
      }
    case GET_BARCODE_FAILED:
      return {
        ...state,
        ...initialState,
        errorMessage: action.payload.error,
        record: initialState.record,
      }
    case GET_BARCODE_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case GET_BARCODE_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        plantDescription: action.payload.plantDescription,
        errorMessage: '',
      }
    case COUNT_BARCODE_FAILED:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload.error,
      }
    case COUNT_BARCODE_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_BARCODE_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_BARCODE_RETAILERSGROUPS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_BARCODE_RETAILERSGROUPS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_BARCODE_RETAILERSGROUPS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        retailersGroups: action.payload.retailersGroups,
      }
    case SAVE_BARCODE_FAILED:
      return {
        ...state,
        saving: false,
        errorMessage: action.payload.error,
      }
    case SAVE_BARCODE_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_BARCODE_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_BARCODE_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_BARCODE_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_BARCODE_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case IMPORT_BARCODE_FAILED:
      return {
        ...state,
        importing: false,
        errorMessage: action.payload.error,
      }
    case IMPORT_BARCODE_PROCESSING:
      return {
        ...state,
        importing: true,
        imported: false,
      }
    case IMPORT_BARCODE_SUCCEED:
      return {
        ...state,
        importing: false,
        imported: true,
      }
    case SELECT_PLANT:
      return {
        ...state,
        selectedPlant: action.payload.plant,
      }
    case HIDE_BARCODE_ALERT:
      return {
        ...state,
        deleted: false,
        fetched: false,
        saved: false,
        imported: false,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default barcode
