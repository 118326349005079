export const COUNT_CATEGORY_PROCESSING = 'COUNT_CATEGORY_PROCESSING'
export const COUNT_CATEGORY_SUCCEED = 'COUNT_CATEGORY_SUCCEED'
export const COUNT_CATEGORY_FAILED = 'COUNT_CATEGORY_FAILED'
export const FETCH_CATEGORY_PROCESSING = 'FETCH_CATEGORY_PROCESSING'
export const FETCH_CATEGORY_SUCCEED = 'FETCH_CATEGORY_SUCCEED'
export const FETCH_CATEGORY_FAILED = 'FETCH_CATEGORY_FAILED'
export const GET_CATEGORY_PROCESSING = 'GET_CATEGORY_PROCESSING'
export const GET_CATEGORY_SUCCEED = 'GET_CATEGORY_SUCCEED'
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED'
export const FETCH_CATEGORY_PRODUCTS_PROCESSING =
  'FETCH_CATEGORY_PRODUCTS_PROCESSING'
export const FETCH_CATEGORY_PRODUCTS_SUCCEED = 'FETCH_CATEGORY_PRODUCTS_SUCCEED'
export const FETCH_CATEGORY_PRODUCTS_FAILED = 'FETCH_CATEGORY_PRODUCTS_FAILED'
export const SAVE_CATEGORY_PROCESSING = 'SAVE_CATEGORY_PROCESSING'
export const SAVE_CATEGORY_SUCCEED = 'SAVE_CATEGORY_SUCCEED'
export const SAVE_CATEGORY_FAILED = 'SAVE_CATEGORY_FAILED'
export const DELETE_CATEGORY_PROCESSING = 'DELETE_CATEGORY_PROCESSING'
export const DELETE_CATEGORY_SUCCEED = 'DELETE_CATEGORY_SUCCEED'
export const DELETE_CATEGORY_FAILED = 'DELETE_CATEGORY_FAILED'
export const HIDE_CATEGORY_ALERT = 'HIDE_CATEGORY_ALERT'
