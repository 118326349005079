import Parse from '../parse'
export const auth = {
  login(values) {
    return dispatch => {
      dispatch({
        type: 'LOGIN_REQUEST'
      })
      Parse.User.logIn(values.email, values.password)
        .then(user => {
          dispatch({
            type: 'LOGIN_SUCCESS',
            meta: {},
            payload: {
              objectId: user.id,
              sessionToken: user.get('sessionToken')
            }
          })
        })
        .catch(err => {
          dispatch({
            type: 'LOGIN_FAILURE',
            meta: {},
            payload: err
          })
        })
    }
  },
  logout() {
    return dispatch => {
      Parse.User.logOut().then(() => {
        // var currentUser = Parse.User.current();  // this will now be null
        dispatch({
          type: 'LOGOUT'
        })
      })
    }
  },
  signup(user) {
    return new Promise((resolve, reject) => {})
  }
}
