import {
  COUNT_CAREINFO_FAILED,
  COUNT_CAREINFO_SUCCEED,
  COUNT_CAREINFO_PROCESSING,
  GET_CAREINFO_FAILED,
  GET_CAREINFO_SUCCEED,
  GET_CAREINFO_PROCESSING,
  FETCH_CAREINFO_REGIONS_FAILED,
  FETCH_CAREINFO_REGIONS_SUCCEED,
  FETCH_CAREINFO_REGIONS_PROCESSING,
  FETCH_CAREINFO_PRODUCTS_FAILED,
  FETCH_CAREINFO_PRODUCTS_SUCCEED,
  FETCH_CAREINFO_PRODUCTS_PROCESSING,
  FETCH_CAREINFO_MONTHS_FAILED,
  FETCH_CAREINFO_MONTHS_SUCCEED,
  FETCH_CAREINFO_MONTHS_PROCESSING,
  FETCH_CAREINFO_GENUSCATEGORIES_FAILED,
  FETCH_CAREINFO_GENUSCATEGORIES_SUCCEED,
  FETCH_CAREINFO_GENUSCATEGORIES_PROCESSING,
  FETCH_CAREINFO_ICONS_FAILED,
  FETCH_CAREINFO_ICONS_SUCCEED,
  FETCH_CAREINFO_ICONS_PROCESSING,
  DELETE_CAREINFO_FAILED,
  DELETE_CAREINFO_SUCCEED,
  DELETE_CAREINFO_PROCESSING,
} from 'constants/careInfo.constants'
import Parse from '../parse'
import pick from 'lodash.pick'
import cronstrue from 'cronstrue'

const CareInfo = new Parse.Object.extend('CareInfo')
const GenusCategory = Parse.Object.extend('GenusCategory')
const Region = Parse.Object.extend('Region')
const Month = Parse.Object.extend('Month')
const CareIcon = Parse.Object.extend('CareIcon')

export default {
  count(searchText) {
    return async (dispatch) => {
      dispatch({
        type: COUNT_CAREINFO_PROCESSING,
      })
      let query = new Parse.Query(CareInfo)
      searchText = searchText || ''
      if (searchText !== '') {
        let categoryQuery = new Parse.Query(Parse.Object.extend('GenusCategory'))
        categoryQuery.matches('name', searchText.trim(), 'i')
        query.matchesQuery('categoryId', categoryQuery)
      }
      try {
        const total = await query.count()
        dispatch({
          type: COUNT_CAREINFO_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_CAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },

  async fetch(params) {
    let query = new Parse.Query(CareInfo)
    query.include('categoryId').include('monthId')
    if (params.categories && params.categories.length > 0) {
      let categoryQuery = new Parse.Query(Parse.Object.extend('GenusCategory'))
      categoryQuery.containedIn(
        'objectId',
        params.categories.map((c) => c.value)
      )
      query.matchesQuery('categories', categoryQuery)
    }

    if (params.genera && params.genera.length > 0) {
      let genusQuery = new Parse.Query(Parse.Object.extend('Genus'))
      genusQuery.containedIn(
        'objectId',
        params.genera.map((c) => c.value)
      )
      query.matchesQuery('genera', genusQuery)
    }

    if (params.plants && params.plants.length > 0) {
      let plantQuery = new Parse.Query(Parse.Object.extend('Plant'))
      plantQuery.containedIn(
        'objectId',
        params.plants.map((c) => c.value)
      )
      query.matchesQuery('plants', plantQuery)
    }

    let results = []

    results = await query
      .limit(params.rowsPerPage)
      .skip(params.page * params.rowsPerPage)
      .include(['months', 'plants', 'genera', 'categories', 'regions', 'icon'])
      .descending('createdAt')
      .find()

    var list = results.map((r) => {
      const obj = r.toJSON()
      return {
        [r.id]: {
          ...obj,
          plants: obj.plants ? obj.plants.map((c) => c.latinName).join(', ') : [],
          genera: obj.genera ? obj.genera.map((c) => c.genusName).join(', ') : [],
          categories: obj.categories ? obj.categories.map((c) => c.name).join(', ') : [],
          regions: obj.regsions
            ? obj.regions
                .map((r) => r.regionName)
                .join(', ')
                .slice(0, 100)
            : [],
          months: obj.months ? obj.months.map((m) => m.seasonName).join(', ') : [],
          icon: obj.icon ? obj.icon.url : '',
          notificationSchedule: obj.notificationSchedule ? cronstrue.toString(obj.notificationSchedule) : 'No schedule',
        },
      }
    })
    return list
  },

  get(id) {
    return async (dispatch) => {
      dispatch({
        type: GET_CAREINFO_PROCESSING,
      })
      let query = new Parse.Query(CareInfo)
      let obj = {}
      try {
        obj = await query
          .include('months')
          .include('plants')
          .include('genera')
          .include('categories')
          .include('regions')
          .get(id)
      } catch (error) {
        dispatch({
          type: GET_CAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      const careInfo = obj.toJSON()
      const record = {
        ...careInfo,
        plants: careInfo.plants.map((p) => ({ value: p.id, label: p.latinName, ...p })),
        genera: careInfo.genera.map((g) => ({ value: g.id, label: g.genusName, ...g })),
        categories: careInfo.categories.map((c) => ({ value: c.id, label: c.name, ...c })),
        regions: careInfo.regions.map((r) => ({ value: r.id, label: r.regionName, ...r })),
        months: careInfo.months.map((m) => ({ value: m.id, label: m.seasonName, ...m })),
        icon: { value: careInfo.icon.id, label: careInfo.icon.name, ...careInfo.icon },
      }
      return dispatch({
        type: GET_CAREINFO_SUCCEED,
        payload: {
          record,
        },
      })
    }
  },

  getRegions(searchText) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_CAREINFO_REGIONS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('regionName', searchText.trim(), 'i')
      }
      query.limit(10000)
      try {
        const results = await query.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('regionName'),
          })
        }
        dispatch({
          type: FETCH_CAREINFO_REGIONS_SUCCEED,
          payload: {
            regions: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_CAREINFO_REGIONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },

  getGenusCategories(searchText) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_CAREINFO_GENUSCATEGORIES_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('GenusCategory'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      try {
        const results = await query.limit(10000).find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('name'),
          })
        }
        dispatch({
          type: FETCH_CAREINFO_GENUSCATEGORIES_SUCCEED,
          payload: {
            genusCategories: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_CAREINFO_GENUSCATEGORIES_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },

  getMonths(searchText) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_CAREINFO_MONTHS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Month'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('seasonName', searchText.trim(), 'i')
      }
      try {
        const results = await query.limit(10000).find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('seasonName'),
          })
        }
        dispatch({
          type: FETCH_CAREINFO_MONTHS_SUCCEED,
          payload: {
            months: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_CAREINFO_MONTHS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },

  getProducts(searchText) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_CAREINFO_PRODUCTS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Products'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('productName', searchText.trim(), 'i')
      }
      query.limit(10000)
      let results = []
      try {
        results = await query.find()
      } catch (error) {
        dispatch({
          type: FETCH_CAREINFO_PRODUCTS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        list.push({
          value: object.id,
          label: object.get('productName'),
        })
      }
      dispatch({
        type: FETCH_CAREINFO_PRODUCTS_SUCCEED,
        payload: {
          products: list,
        },
      })
    }
  },

  getIcons(searchText) {
    return async (dispatch) => {
      dispatch({
        type: FETCH_CAREINFO_ICONS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('CareIcon'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      let results = []
      try {
        results = await query.limit(10000).find()
      } catch (error) {
        return dispatch({
          type: FETCH_CAREINFO_ICONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        const iconName = object.get('name')
        if (iconName != null) {
          list.push({
            value: object.id,
            label: object.get('name'),
          })
        }
      }
      return dispatch({
        type: FETCH_CAREINFO_ICONS_SUCCEED,
        payload: {
          icons: list,
        },
      })
    }
  },

  async save(values) {
    let careInfo = new CareInfo()
    if (values.object_id) {
      let careInfoQuery = new Parse.Query(CareInfo)
      careInfo = await careInfoQuery.get(values.object_id)
    }

    careInfo.set(pick(values, ['careField1', 'careField2', 'careField3', 'careDetails', 'careDescription', 'alexa']))

    if (values.categoryId && values.categoryId.value) {
      const CategoryIdQuery = new Parse.Query(GenusCategory)
      const categoryId = await CategoryIdQuery.get(values.categoryId.value)
      careInfo.set('categoryId', categoryId.toPointer())
    }
    if (values.icon && values.icon.value) {
      const CareIconQuery = new Parse.Query(CareIcon)
      const careIcon = await CareIconQuery.get(values.icon.value)
      careInfo.set('icon', careIcon.toPointer())
    }
    if (values.monthId && values.monthId.length > 0) {
      // months is an array
      const monthQuery = new Parse.Query(Month)
      let objectIds = values.monthId.map((m) => m.value)
      const months = await monthQuery.containedIn('objectId', objectIds).find()
      careInfo.set(
        'monthId',
        months.map((m) => m.toPointer())
      )
    }
    if (values.regions && values.regions.length > 0) {
      // regions is a relation
      const regionQuery = new Parse.Query(Region)
      const regions = await regionQuery
        .containedIn(
          'objectId',
          values.regions.map((r) => r.value)
        )
        .find()
      const regionRelation = careInfo.relation('regions')
      regionRelation.add(regions)
      const currentRegions = await regionRelation.query().find()
      const regionsToRemove = currentRegions.filter((cR) => values.regions.findIndex((r) => r.value === cR.id) === -1)
      if (regionsToRemove && regionsToRemove.length > 0) {
        regionRelation.remove(regionsToRemove)
      }
    }
    await careInfo.save(null)
  },

  delete(ids) {
    return async (dispatch) => {
      dispatch({
        type: DELETE_CAREINFO_PROCESSING,
      })
      let query = new Parse.Query(CareInfo)
      try {
        const categoryCareInfos = await query.containedIn('objectId', ids).find()
        if (categoryCareInfos.length > 0) {
          await Parse.Object.destroyAll(categoryCareInfos)
          dispatch({
            type: DELETE_CAREINFO_SUCCEED,
          })
        } else {
          dispatch({
            type: DELETE_CAREINFO_SUCCEED,
          })
        }
      } catch (error) {
        dispatch({
          type: DELETE_CAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
