export const COUNT_EXPERT_PROCESSING = 'COUNT_EXPERT_PROCESSING'
export const COUNT_EXPERT_SUCCEED = 'COUNT_EXPERT_SUCCEED'
export const COUNT_EXPERT_FAILED = 'COUNT_EXPERT_FAILED'
export const FETCH_EXPERT_PROCESSING = 'FETCH_EXPERT_PROCESSING'
export const FETCH_EXPERT_SUCCEED = 'FETCH_EXPERT_SUCCEED'
export const NEW_EXPERT = 'NEW_EXPERT'
export const FETCH_EXPERT_FAILED = 'FETCH_EXPERT_FAILED'
export const GET_EXPERT_PROCESSING = 'GET_EXPERT_PROCESSING'
export const GET_EXPERT_SUCCEED = 'GET_EXPERT_SUCCEED'
export const GET_EXPERT_FAILED = 'GET_EXPERT_FAILED'
export const FETCH_EXPERT_REGIONS_PROCESSING = 'FETCH_EXPERT_REGIONS_PROCESSING'
export const FETCH_EXPERT_REGIONS_SUCCEED = 'FETCH_EXPERT_REGIONS_SUCCEED'
export const FETCH_EXPERT_REGIONS_FAILED = 'FETCH_EXPERT_REGIONS_FAILED'
export const SAVE_EXPERT_PROCESSING = 'SAVE_EXPERT_PROCESSING'
export const SAVE_EXPERT_SUCCEED = 'SAVE_EXPERT_SUCCEED'
export const SAVE_EXPERT_FAILED = 'SAVE_EXPERT_FAILED'
export const DELETE_EXPERT_PROCESSING = 'DELETE_EXPERT_PROCESSING'
export const DELETE_EXPERT_SUCCEED = 'DELETE_EXPERT_SUCCEED'
export const DELETE_EXPERT_FAILED = 'DELETE_EXPERT_FAILED'
export const HIDE_EXPERT_ALERT = 'HIDE_EXPERT_ALERT'
