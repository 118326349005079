import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, Select, Chip, withStyles, MenuItem } from '@material-ui/core'

class CustomMultiSelect extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    classes: PropTypes.object,
  }

  static defaultProps = {
    placeholder: 'Enter something',
    multiple: false,
  }

  fileInputRef = React.createRef()

  render() {
    const { input, options, placeholder, classes, label } = this.props
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor={input.name}>{label}</InputLabel>
        <Select
          value={input.value}
          onChange={input.onChange}
          placeholder={placeholder}
          multiple
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((item, i) => (
                <Chip
                  key={i}
                  label={item.label}
                  className={classes.chip}
                  onDelete={() => {
                    input.onChange(input.value.filter((i) => i.value !== item.value))
                  }}
                />
              ))}
            </div>
          )}
        >
          {options &&
            options
              .filter(
                (item) => input.value && input.value.findIndex((selected) => selected.value === item.value) === -1
              )
              .map((item) => (
                <MenuItem key={item.value} value={item}>
                  {item.label}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    )
  }
}

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
})

export default withStyles(styles)(CustomMultiSelect)
