import {
  COUNT_REGION_FAILED,
  COUNT_REGION_SUCCEED,
  COUNT_REGION_PROCESSING,
  FETCH_REGION_FAILED,
  FETCH_REGION_SUCCEED,
  FETCH_REGION_PROCESSING,
  GET_REGION_FAILED,
  GET_REGION_SUCCEED,
  GET_REGION_PROCESSING,
  FETCH_REGION_SUBSCRIPTIONCURRENCIES_FAILED,
  FETCH_REGION_SUBSCRIPTIONCURRENCIES_SUCCEED,
  FETCH_REGION_SUBSCRIPTIONCURRENCIES_PROCESSING,
  SAVE_REGION_FAILED,
  SAVE_REGION_SUCCEED,
  SAVE_REGION_PROCESSING,
  DELETE_REGION_FAILED,
  DELETE_REGION_SUCCEED,
  DELETE_REGION_PROCESSING,
  HIDE_REGION_ALERT,
} from '../constants/region.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  errorMessage: '',
  list: [],
  total: 0,
  subscriptionCurrencies: [],
  record: {
    object_id: '',
    regionName: '',
    hemisphere: '',
    subscriptionCurrency: '',
  },
}

const region = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGION_FAILED:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload.error,
        list: [],
      }
    case FETCH_REGION_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_REGION_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
      }
    case GET_REGION_FAILED:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload.error,
        record: initialState.record,
      }
    case GET_REGION_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_REGION_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
        selectedPlant: action.payload.selectedPlant,
      }
    case COUNT_REGION_FAILED:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload.error,
      }
    case COUNT_REGION_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_REGION_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_REGION_SUBSCRIPTIONCURRENCIES_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_REGION_SUBSCRIPTIONCURRENCIES_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_REGION_SUBSCRIPTIONCURRENCIES_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        subscriptionCurrencies: action.payload.subscriptionCurrencies,
      }
    case SAVE_REGION_FAILED:
      return {
        ...state,
        saving: false,
        errorMessage: action.payload.error,
      }
    case SAVE_REGION_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_REGION_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_REGION_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_REGION_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_REGION_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_REGION_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        errorMessage: '',
        deleted: false,
      }
    default:
      return state
  }
}

export default region
