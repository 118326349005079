// ##############################
// // // Header styles
// #############################

import { primaryColor, infoColor, successColor, warningColor, dangerColor } from '../styles'

const headerStyle = (theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  flex: {
    flex: 1,
  },
  title: {
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    top: '10px',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  appResponsive: {
    top: '8px',
  },
  primary: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
  },
  info: {
    backgroundColor: infoColor,
    color: '#FFFFFF',
  },
  success: {
    backgroundColor: successColor,
    color: '#FFFFFF',
  },
  warning: {
    backgroundColor: warningColor,
    color: '#FFFFFF',
  },
  danger: {
    backgroundColor: dangerColor,
    color: '#FFFFFF',
  },
})

export default headerStyle
