export const COUNT_RETAILERSGROUP_PROCESSING = 'COUNT_RETAILERSGROUP_PROCESSING'
export const COUNT_RETAILERSGROUP_SUCCEED = 'COUNT_RETAILERSGROUP_SUCCEED'
export const COUNT_RETAILERSGROUP_FAILED = 'COUNT_RETAILERSGROUP_FAILED'
export const FETCH_RETAILERSGROUP_PROCESSING = 'FETCH_RETAILERSGROUP_PROCESSING'
export const FETCH_RETAILERSGROUP_SUCCEED = 'FETCH_RETAILERSGROUP_SUCCEED'
export const FETCH_RETAILERSGROUP_FAILED = 'FETCH_RETAILERSGROUP_FAILED'
export const GET_RETAILERSGROUP_PROCESSING = 'GET_RETAILERSGROUP_PROCESSING'
export const GET_RETAILERSGROUP_SUCCEED = 'GET_RETAILERSGROUP_SUCCEED'
export const GET_RETAILERSGROUP_FAILED = 'GET_RETAILERSGROUP_FAILED'
export const FETCH_RETAILERSGROUP_REGIONS_PROCESSING =
  'FETCH_RETAILERSGROUP_REGIONS_PROCESSING'
export const FETCH_RETAILERSGROUP_REGIONS_SUCCEED =
  'FETCH_RETAILERSGROUP_REGIONS_SUCCEED'
export const FETCH_RETAILERSGROUP_REGIONS_FAILED =
  'FETCH_RETAILERSGROUP_REGIONS_FAILED'
export const FETCH_RETAILERSGROUP_PRODUCTS_PROCESSING =
  'FETCH_RETAILERSGROUP_PRODUCTS_PROCESSING'
export const FETCH_RETAILERSGROUP_PRODUCTS_SUCCEED =
  'FETCH_RETAILERSGROUP_PRODUCTS_SUCCEED'
export const FETCH_RETAILERSGROUP_PRODUCTS_FAILED =
  'FETCH_RETAILERSGROUP_PRODUCTS_FAILED'
export const SAVE_RETAILERSGROUP_PROCESSING = 'SAVE_RETAILERSGROUP_PROCESSING'
export const SAVE_RETAILERSGROUP_SUCCEED = 'SAVE_RETAILERSGROUP_SUCCEED'
export const SAVE_RETAILERSGROUP_FAILED = 'SAVE_RETAILERSGROUP_FAILED'
export const SAVE_RETAILERSGROUP_PRODUCTS_PROCESSING =
  'SAVE_RETAILERSGROUP_PRODUCTS_PROCESSING'
export const SAVE_RETAILERSGROUP_PRODUCTS_SUCCEED =
  'SAVE_RETAILERSGROUP_PRODUCTS_SUCCEED'
export const SAVE_RETAILERSGROUP_PRODUCTS_FAILED =
  'SAVE_RETAILERSGROUP_PRODUCTS_FAILED'
export const DELETE_RETAILERSGROUP_PROCESSING =
  'DELETE_RETAILERSGROUP_PROCESSING'
export const DELETE_RETAILERSGROUP_SUCCEED = 'DELETE_RETAILERSGROUP_SUCCEED'
export const DELETE_RETAILERSGROUP_FAILED = 'DELETE_RETAILERSGROUP_FAILED'
export const HIDE_RETAILERS_GROUP_ALERT = 'HIDE_RETAILERS_GROUP_ALERT'
