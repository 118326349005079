import { barcode as barcodeAPI } from '../api'
import {
  SELECT_PLANT,
  HIDE_BARCODE_ALERT,
} from '../constants/barcode.constants'
export const barcode = {
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      return dispatch(barcodeAPI.fetch(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(barcodeAPI.get(id))
    }
  },
  getRetailersGroups(query) {
    return dispatch => {
      return dispatch(barcodeAPI.getRetailersGroups(query))
    }
  },
  selectedPlant(plant) {
    return dispatch => {
      return dispatch({
        type: SELECT_PLANT,
        payload: {
          plant: plant,
        },
      })
    }
  },
  count(query) {
    return dispatch => {
      return dispatch(barcodeAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(barcodeAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      return dispatch(barcodeAPI.delete(id))
    }
  },
  import(values, file) {
    return dispatch => {
      return dispatch(barcodeAPI.import(values, file))
    }
  },
  hideAlert() {
    return dispatch => {
      return dispatch({
        type: HIDE_BARCODE_ALERT,
      })
    }
  },
}
