import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'

import { IconButton, Snackbar, SnackbarContent, withStyles } from '@material-ui/core'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const styles1 = (theme) => ({
  /* success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  }, */
})

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent)

const styles2 = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
})

class CustomSnackbar extends React.Component {
  state = {
    isOpen: false,
  }
  static propTypes = {
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string,
    message: PropTypes.string.isRequired,
    autoHideDuration: PropTypes.number,
    onClose: PropTypes.func,
  }
  static defaultProps = {
    message: '',
    variant: 'success',
    autoHideDuration: 6000,
  }

  componentDidUpdate() {
    if (this.props.open && !this.state.isOpen) {
      this.setState({ isOpen: true })
    }
  }

  onClose = () => {
    this.setState({ isOpen: false })
  }

  render() {
    const { open, variant, message, autoHideDuration, onClose } = this.props
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          autoHideDuration={autoHideDuration}
          onClose={onClose}
        >
          <MySnackbarContentWrapper variant={variant} message={message} onClose={onClose} />
        </Snackbar>
      </div>
    )
  }
}

export default withStyles(styles2)(CustomSnackbar)
