import { product as productAPI } from '../api'
import { HIDE_PRODUCT_ALERT } from '../constants/product.constants'
export const product = {
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      return dispatch(productAPI.fetch(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      return dispatch(productAPI.get(id))
    }
  },
  getRegions(query) {
    return dispatch => {
      return dispatch(productAPI.getRegions(query))
    }
  },
  count(query) {
    return dispatch => {
      return dispatch(productAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      return dispatch(productAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      return dispatch(productAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      return dispatch({
        type: HIDE_PRODUCT_ALERT,
      })
    }
  },
}
