import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import {
  Button,
  CardActions,
  Fade,
  withStyles,
  CircularProgress,
} from '@material-ui/core'

import { overlay, loadingSpinner } from '../../variables/styles'
import RegularCard from '../Cards/RegularCard.js'
import { genusCareInfo as genusCareInfoActions } from '../../actions'
import formPropTypes from '../../constants/formPropTypes'
import CustomTextField from 'components/Input/CustomTextField'
import CustomSnackbar from 'components/CustomSnackbar'
import CustomSelect from 'components/Input/CustomSelect'
import CustomMultiSelect from 'components/Input/CustomMultiSelect'

export const fields = [
  'object_id',
  'genusId',
  'careField1',
  'careField2',
  'careField3',
  'careDetails',
  'products',
  'monthId',
  'careDescription',
  'alexa',
]

const validate = values => {
  const errors = {}
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  values.object_id = props.initialValues.object_id
  try {
    await props.save(values)
  } catch (err) {
    throw new SubmissionError({ _error: err.message })
  }
}

class GenusCareInfoForm extends Component {
  static propTypes = {
    ...formPropTypes,
    genuses: PropTypes.array.isRequired,
    // genusCategories: PropTypes.array,
    icons: PropTypes.array.isRequired,
    regions: PropTypes.array.isRequired,
    months: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    fields: PropTypes.array.isRequired,
    duplicateId: PropTypes.object,
    closeDuplicate: PropTypes.func,
  }
  async componentDidMount() {
    await this.props.getRegions()
    await this.props.getMonths()
    await this.props.getGenera()
    await this.props.getProducts()
    await this.props.getIcons()
    // this.props.dispatch(careIcon.get())
    if (
      this.props.duplicateId !== undefined &&
      this.props.duplicateId !== null
    ) {
      await this.props.get(this.props.duplicateId)
    } else {
      await this.props.get(this.props.match.params.id)
    }
  }
  hideAlert = () => {
    this.props.hideAlert()
    this.props.reset()
  }
  backToList = () => {
    this.props.hideAlert()
    this.props.history.push('/genusCareInfo')
  }
  render() {
    const {
      submitting,
      submitSucceeded,
      record,
      genuses,
      regions,
      months,
      products,
      icons,
      handleSubmit,
      fetching,
      error,
      duplicateId,
      closeDuplicate,
      classes,
      pristine,
    } = this.props
    let title = ''
    if (duplicateId !== undefined && duplicateId !== null) {
      title =
        'Duplicate ' +
        (record !== null && record.genusId ? record.genusId.label : '')
    } else {
      title =
        (this.props.match.params.id ? 'Edit ' : 'Add new') +
        (record !== null && record.genusId ? record.genusId.label : '')
    }
    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(error)}
          message={error && error.message}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar
          variant="info"
          open={Boolean(submitting)}
          message="Saving"
        />
        <CustomSnackbar
          variant="success"
          open={Boolean(submitSucceeded)}
          message="Saved"
          onClose={() => this.hideAlert()}
        />
        <form onSubmit={handleSubmit}>
          <RegularCard
            cardTitle={title}
            content={
              <>
                <Fade in={this.props.fetching}>
                  <div
                    className={classes.overlay}
                    style={{ zIndex: this.props.fetching ? 10 : 0 }}
                  >
                    <CircularProgress className={classes.loadingSpinner} />
                  </div>
                </Fade>
                <div>
                  <Field
                    name="genusId"
                    label="Genus"
                    placeholder="Select a genus"
                    options={genuses}
                    component={CustomSelect}
                  />
                </div>
                <div>
                  <Field
                    name="careField1"
                    component={CustomTextField}
                    label="Care field 1"
                  />
                </div>
                <div>
                  <Field
                    name="careField2"
                    component={CustomTextField}
                    label="Care field 2"
                  />
                </div>
                <div>
                  <Field
                    name="careField3"
                    component={CustomTextField}
                    label="Care field 3"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="careDetails"
                    component={CustomTextField}
                    label="Details"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="careDescription"
                    component={CustomTextField}
                    label="Description"
                  />
                </div>
                <div>
                  <Field
                    multiline={true}
                    name="alexa"
                    component={CustomTextField}
                    label="Alexa"
                  />
                </div>
                <div>
                  <Field
                    name="products"
                    label="Products"
                    options={products}
                    placeholder="Select products"
                    component={CustomMultiSelect}
                  />
                </div>
                <div>
                  <Field
                    name="regions"
                    label="Regions"
                    options={regions}
                    placeholder="Select regions"
                    component={CustomMultiSelect}
                  />
                </div>
                <div>
                  <Field
                    name="monthId"
                    label="Months"
                    options={months}
                    placeholder="Select months"
                    component={CustomMultiSelect}
                  />
                </div>
                <div>
                  <Field
                    name="icon"
                    label="Icon"
                    options={icons}
                    placeholder="Select icon"
                    component={CustomSelect}
                  />
                </div>
              </>
            }
            footer={
              duplicateId ? (
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={fetching || pristine}
                  >
                    Duplicate
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={closeDuplicate}
                  >
                    Cancel
                  </Button>
                </CardActions>
              ) : (
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={fetching || pristine}
                  >
                    Submit
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.props.history.push('/categoryCareInfo')}
                  >
                    Back to list
                  </Button>
                </CardActions>
              )
            }
          />
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ genusCareInfo }) => ({
  record: genusCareInfo.record,
  genuses: genusCareInfo.genuses,
  regions: genusCareInfo.regions,
  months: genusCareInfo.months,
  products: genusCareInfo.products,
  icons: genusCareInfo.icons,
  fetching: genusCareInfo.fetching,
  fetched: genusCareInfo.fetched,
  saving: genusCareInfo.saving,
  saved: genusCareInfo.saved,
  fields,
  initialValues: genusCareInfo.record,
})

const mapDispatchToActions = { ...genusCareInfoActions }

const styles = () => ({
  overlay,
  loadingSpinner,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToActions
  )(
    reduxForm({
      form: 'genusCareInfo',
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(GenusCareInfoForm)
  )
)
