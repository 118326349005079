import categoryApi from 'api/modules/category.api'
import { HIDE_CATEGORY_ALERT } from '../constants/category.constants'
export const category = {
  list(page, rowsPerPage, query) {
    return (dispatch) => {
      dispatch(categoryApi.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return (dispatch) => {
      dispatch(categoryApi.get(id))
    }
  },
  getCategories() {
    return (dispatch) => {
      dispatch(categoryApi.getCategories())
    }
  },
  getProducts(query) {
    return (dispatch) => {
      dispatch(categoryApi.getProducts(query))
    }
  },
  count(query) {
    return (dispatch) => {
      dispatch(categoryApi.count(query))
    }
  },
  save(values) {
    return (dispatch) => {
      dispatch(categoryApi.save(values))
    }
  },
  delete(id) {
    return (dispatch) => {
      dispatch(categoryApi.delete(id))
    }
  },
  hideAlert() {
    return (dispatch) => {
      dispatch({
        type: HIDE_CATEGORY_ALERT,
      })
    }
  },
}
