export const FETCH_CARE_ICONS_PROCESSING = 'FETCH_CARE_ICONS_PROCESSING'
export const FETCH_CARE_ICONS_SUCCEED = 'FETCH_CARE_ICONS_SUCCEED'
export const FETCH_CARE_ICONS_FAILED = 'FETCH_CARE_ICONS_FAILED'
export const COUNT_CARE_ICONS_PROCESSING = 'COUNT_CARE_ICONS_PROCESSING'
export const COUNT_CARE_ICONS_SUCCEED = 'COUNT_CARE_ICONS_SUCCEED'
export const COUNT_CARE_ICONS_FAILED = 'COUNT_CARE_ICONS_FAILED'
export const GET_CARE_ICONS_PROCESSING = 'GET_CARE_ICONS_PROCESSING'
export const GET_CARE_ICONS_SUCCEED = 'GET_CARE_ICONS_SUCCEED'
export const GET_CARE_ICONS_FAILED = 'GET_CARE_ICONS_FAILED'

