import axios from 'axios'

import {
  SEND_PUSH_NOTIFICATION_PROCESSING,
  SEND_PUSH_NOTIFICATION_SUCCEED,
  SEND_PUSH_NOTIFICATION_FAILED,
  SEND_EMAIL_MARKETING_PROCESSING,
  SEND_EMAIL_MARKETING_SUCCEED,
  SEND_EMAIL_MARKETING_FAILED,
  GET_EMAIL_TEMPLATES_PROCESSING,
  GET_EMAIL_TEMPLATES_SUCCEED,
  GET_EMAIL_TEMPLATES_FAILED
} from '../../constants'
import Parse from '../parse'

const axiosSendgridInstance = axios.create({
  baseURL: process.env.REACT_APP_SENDGRID_API_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`
  }
})

export const marketing = {
  sendPushNotification(push) {
    return async dispatch => {
      dispatch({
        type: SEND_PUSH_NOTIFICATION_PROCESSING
      })
      try {
        await Parse.Cloud.run('sendPushNotification', push)
        dispatch({
          type: SEND_PUSH_NOTIFICATION_SUCCEED
        })
      } catch (error) {
        dispatch({
          type: SEND_PUSH_NOTIFICATION_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`
          }
        })
      }
    }
  },
  sendEmailNotification(email) {
    return async dispatch => {
      dispatch({
        type: SEND_EMAIL_MARKETING_PROCESSING
      })
      try {
        await Parse.Cloud.run('sendEmailNotification', email)
        dispatch({
          type: SEND_EMAIL_MARKETING_SUCCEED
        })
      } catch (error) {
        dispatch({
          type: SEND_EMAIL_MARKETING_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`
          }
        })
      }
    }
  },
  getEmailTemplates() {
    return async dispatch => {
      dispatch({
        type: GET_EMAIL_TEMPLATES_PROCESSING
      })
      try {
        const response = await axiosSendgridInstance.get('/templates?generations=dynamic')
        dispatch({
          type: GET_EMAIL_TEMPLATES_SUCCEED,
          payload: { emailTemplates: response.data.templates }
        })
      } catch (error) {
        dispatch({
          type: GET_EMAIL_TEMPLATES_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`
          }
        })
      }
    }
  }
}
