export const COUNT_GENUSCAREINFO_PROCESSING = 'COUNT_GENUSCAREINFO_PROCESSING'
export const COUNT_GENUSCAREINFO_SUCCEED = 'COUNT_GENUSCAREINFO_SUCCEED'
export const COUNT_GENUSCAREINFO_FAILED = 'COUNT_GENUSCAREINFO_FAILED'
export const FETCH_GENUSCAREINFO_PROCESSING = 'FETCH_GENUSCAREINFO_PROCESSING'
export const FETCH_GENUSCAREINFO_SUCCEED = 'FETCH_GENUSCAREINFO_SUCCEED'
export const FETCH_GENUSCAREINFO_FAILED = 'FETCH_GENUSCAREINFO_FAILED'
export const FETCH_GENUSCAREINFO_REGIONS_PROCESSING =
  'FETCH_GENUSCAREINFO_REGIONS_PROCESSING'
export const FETCH_GENUSCAREINFO_REGIONS_SUCCEED =
  'FETCH_GENUSCAREINFO_REGIONS_SUCCEED'
export const FETCH_GENUSCAREINFO_REGIONS_FAILED =
  'FETCH_GENUSCAREINFO_REGIONS_FAILED'
export const GET_GENUSCAREINFO_PROCESSING = 'GET_GENUSCAREINFO_PROCESSING'
export const GET_GENUSCAREINFO_SUCCEED = 'GET_GENUSCAREINFO_SUCCEED'
export const GET_GENUSCAREINFO_FAILED = 'GET_GENUSCAREINFO_FAILED'
export const FETCH_GENUSCAREINFO_GENUSES_PROCESSING =
  'FETCH_GENUSCAREINFO_GENUSES_PROCESSING'
export const FETCH_GENUSCAREINFO_GENUSES_SUCCEED =
  'FETCH_GENUSCAREINFO_GENUSES_SUCCEED'
export const FETCH_GENUSCAREINFO_GENUSES_FAILED =
  'FETCH_GENUSCAREINFO_GENUSES_FAILED'
export const FETCH_GENUSCAREINFO_MONTHS_PROCESSING =
  'FETCH_GENUSCAREINFO_MONTHS_PROCESSING'
export const FETCH_GENUSCAREINFO_MONTHS_SUCCEED =
  'FETCH_GENUSCAREINFO_MONTHS_SUCCEED'
export const FETCH_GENUSCAREINFO_MONTHS_FAILED =
  'FETCH_GENUSCAREINFO_MONTHS_FAILED'
export const FETCH_GENUSCAREINFO_PRODUCTS_PROCESSING =
  'FETCH_GENUSCAREINFO_PRODUCTS_PROCESSING'
export const FETCH_GENUSCAREINFO_ICONS_SUCCEED =
  'FETCH_GENUSCAREINFO_ICONS_SUCCEED'
export const FETCH_GENUSCAREINFO_ICONS_FAILED =
  'FETCH_GENUSCAREINFO_ICONS_FAILED'
export const FETCH_GENUSCAREINFO_ICONS_PROCESSING =
  'FETCH_GENUSCAREINFO_ICONS_PROCESSING'
export const FETCH_GENUSCAREINFO_PRODUCTS_SUCCEED =
  'FETCH_GENUSCAREINFO_PRODUCTS_SUCCEED'
export const FETCH_GENUSCAREINFO_PRODUCTS_FAILED =
  'FETCH_GENUSCAREINFO_PRODUCTS_FAILED'
export const SAVE_GENUSCAREINFO_PROCESSING = 'SAVE_GENUSCAREINFO_PROCESSING'
export const SAVE_GENUSCAREINFO_SUCCEED = 'SAVE_GENUSCAREINFO_SUCCEED'
export const SAVE_GENUSCAREINFO_FAILED = 'SAVE_GENUSCAREINFO_FAILED'
export const DELETE_GENUSCAREINFO_PROCESSING = 'DELETE_GENUSCAREINFO_PROCESSING'
export const DELETE_GENUSCAREINFO_SUCCEED = 'DELETE_GENUSCAREINFO_SUCCEED'
export const DELETE_GENUSCAREINFO_FAILED = 'DELETE_GENUSCAREINFO_FAILED'
export const HIDE_GENUS_CARE_INFO_ALERT = 'HIDE_GENUS_CARE_INFO_ALERT'
