import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Select,
  withStyles,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'

class CustomSelect extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
  }

  static defaultProps = {
    placeholder: 'Enter something',
    multiple: false,
  }

  fileInputRef = React.createRef()

  render() {
    const { input, options, placeholder, classes, label } = this.props
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor={input.name}>{label}</InputLabel>
        <Select
          value={input.value}
          onChange={input.onChange}
          options={options}
          inputProps={{
            name: input.name,
            id: input.name,
          }}
          renderValue={selected => {
            if (!selected.value) {
              return <em>{placeholder}</em>
            }

            return selected.label
          }}
        >
          {options &&
            options.length > 0 &&
            options
              .filter(item => item.value !== input.value.value)
              .map(item => (
                <MenuItem key={item.value} value={item}>
                  {item.label}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    )
  }
}

const styles = theme => ({
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
    width: '100%',
  },
  select: {
    width: '100%',
  },
})

export default withStyles(styles)(CustomSelect)
