import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import cx from 'classnames'
import { withStyles } from '@material-ui/core'
import Header from 'components/Header/Header'
import Footer from 'components/Footer'
import Sidebar from 'components/Sidebar/index'
import appRoutes from '../routes/app'
import appStyle from '../variables/styles/appStyle.jsx'
import logo from '../assets/img/logo_white.svg'
import Dashboard from '../components/Home'
import Login from '../components/Login'
import GenusCareInfo from '../components/GenusCareInfo/GenusCareInfoList'
import GenusCareInfoForm from '../components/GenusCareInfo/GenusCareInfoForm'
import CategoryCareInfo from '../components/CategoryCareInfo/CategoryCareInfoList'
import CategoryCareInfoForm from '../components/CategoryCareInfo/CategoryCareInfoForm'

const RouteWithSubRoutes = route => (
  <div id="route-container">
    <Route exact path={route.path} component={route.component} />
    {route.routes.map(sub => {
      return (
        <Route
          exact
          path={sub.path}
          component={sub.component}
          key={sub.action}
        />
      )
    })}
  </div>
)

const switchRoutes = (
  <Switch>
    <Route path="/login" component={Login} key="login" />
    <Route path="/dashboard" component={Dashboard} key="dashboard" />
    <Route
      exact
      path="/genusCareInfo"
      component={GenusCareInfo}
      key="genuscareinfo"
    />
    <Route
      exact
      path="/genusCareInfo/add"
      component={GenusCareInfoForm}
      key="genuscareinfo_add"
    />
    <Route
      exact
      path="/genusCareInfo/edit/:id"
      component={GenusCareInfoForm}
      key="genuscareinfo_edit"
    />
    <Route
      exact
      path="/genusCareInfo/delete/:id"
      component={GenusCareInfo}
      key="genuscareinfo_delete"
    />
    <Route
      exact
      path="/categoryCareInfo"
      component={CategoryCareInfo}
      key="categorycareinfo"
    />
    <Route
      exact
      path="/categoryCareInfo/add"
      component={CategoryCareInfoForm}
      key="categorycareinfo_add"
    />
    <Route
      exact
      path="/categoryCareInfo/edit/:id"
      component={CategoryCareInfoForm}
      key="categorycareinfo_edit"
    />
    <Route
      exact
      path="/categoryCareInfo/delete/:id"
      component={CategoryCareInfo}
      key="categorycareinfo_delete"
    />
    {appRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />
      if (prop.routes !== undefined) {
        return <RouteWithSubRoutes key={key} {...prop} />
      } else {
        return <Route path={prop.path} component={prop.component} key={key} />
      }
    })}
  </Switch>
)
class App extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    sidebarOpen: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    this.correctRoute()
  }
  componentDidUpdate() {
    this.correctRoute()
  }
  correctRoute() {
    if (this.props.isLoggedIn) {
      if (this.props.location.pathname === '/login') {
        this.props.history.push('/dashboard')
      }
    } else {
      if (this.props.location.pathname !== '/login') {
        this.props.history.push('/login')
      }
    }
  }
  render() {
    const { classes, sidebarOpen, ...rest } = this.props
    return (
      <div>
        {this.props.isLoggedIn ? (
          <div className={classes.root}>
            <Sidebar
              routes={appRoutes}
              logoText={'Creative Tim'}
              logo={logo}
              color="blue"
              dark
              {...rest}
            />
            <main
              className={cx(classes.content, {
                [classes.contentShift]: sidebarOpen,
              })}
            >
              <Header routes={appRoutes} {...rest} />
              <>{switchRoutes}</>
              <Footer />
            </main>
          </div>
        ) : (
          <div>{switchRoutes}</div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userId: state.auth.userId,
    isLoggedIn: state.auth.isLoggedIn,
    sidebarOpen: state.user.sidebarOpen,
  }
}

export default withStyles(appStyle)(connect(mapStateToProps)(App))
