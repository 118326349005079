export const COUNT_PLANT_PROCESSING = 'COUNT_PLANT_PROCESSING'
export const COUNT_PLANT_SUCCEED = 'COUNT_PLANT_SUCCEED'
export const COUNT_PLANT_FAILED = 'COUNT_PLANT_FAILED'
export const FETCH_PLANT_PROCESSING = 'FETCH_PLANT_PROCESSING'
export const FETCH_PLANT_SUCCEED = 'FETCH_PLANT_SUCCEED'
export const FETCH_PLANT_FAILED = 'FETCH_PLANT_FAILED'
export const GET_PLANT_PROCESSING = 'GET_PLANT_PROCESSING'
export const GET_PLANT_SUCCEED = 'GET_PLANT_SUCCEED'
export const GET_PLANT_FAILED = 'GET_PLANT_FAILED'
export const FETCH_PLANT_GENUS_PROCESSING = 'FETCH_PLANT_GENUS_PROCESSING'
export const FETCH_PLANT_GENUS_SUCCEED = 'FETCH_PLANT_GENUS_SUCCEED'
export const FETCH_PLANT_GENUS_FAILED = 'FETCH_PLANT_GENUS_FAILED'
export const FETCH_PLANT_NAME_FAILED = 'FETCH_PLANT_NAME_FAILED'
export const FETCH_PLANT_NAME_SUCCEED = 'FETCH_PLANT_NAME_SUCCEED'
export const FETCH_PLANT_NAME_PROCESSING = 'FETCH_PLANT_NAME_PROCESSING'
export const SAVE_PLANT_PROCESSING = 'SAVE_PLANT_PROCESSING'
export const SAVE_PLANT_SUCCEED = 'SAVE_PLANT_SUCCEED'
export const SAVE_PLANT_FAILED = 'SAVE_PLANT_FAILED'
export const DELETE_PLANT_PROCESSING = 'DELETE_PLANT_PROCESSING'
export const DELETE_PLANT_SUCCEED = 'DELETE_PLANT_SUCCEED'
export const DELETE_PLANT_FAILED = 'DELETE_PLANT_FAILED'
export const SELECT_SNAPP_PHOTO = 'SELECT_SNAPP_PHOTO'
export const SAVE_SNAPP_PHOTO_FAILED = 'SAVE_SNAPP_PHOTO_FAILED'
export const SAVE_SNAPP_PHOTO_SUCCEED = 'SAVE_SNAPP_PHOTO_SUCCEED'
export const SAVE_SNAPP_PHOTO_PROCESSING = 'SAVE_SNAPP_PHOTO_PROCESSING'
export const HIDE_PLANT_ALERT = 'HIDE_PLANT_ALERT'
export const MERGE_PLANTS_FAILED = 'MERGE_PLANTS_FAILED'
export const MERGE_PLANTS_SUCCEED = 'MERGE_PLANTS_SUCCEED'
export const MERGE_PLANTS_PROCESSING = 'MERGE_PLANTS_PROCESSING'
