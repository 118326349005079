import React from 'react'
import { withStyles } from '@material-ui/core'
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import cx from 'classnames'

import regularCardStyle from '../../variables/styles/regularCardStyle'

function RegularCard({ ...props }) {
  const {
    classes,
    headerColor,
    plainCard,
    mediaUrl,
    mediaTitle,
    cardTitle,
    cardSubtitle,
    content,
    footer,
  } = props
  const plainCardClasses = cx({
    [' ' + classes.cardPlain]: plainCard,
  })
  const cardPlainHeaderClasses = cx({
    [' ' + classes.cardPlainHeader]: plainCard,
  })
  return (
    <Card className={classes.card + plainCardClasses} width={1}>
      {mediaUrl !== undefined ? (
        <CardMedia image={mediaUrl} title={mediaTitle} />
      ) : null}
      <CardHeader
        classes={{
          root:
            classes.cardHeader +
            ' ' +
            classes[headerColor + 'CardHeader'] +
            cardPlainHeaderClasses,
          title: classes.cardTitle,
          subheader: classes.cardSubtitle,
        }}
        title={cardTitle}
        subheader={cardSubtitle}
      />
      <CardContent>{content}</CardContent>
      {footer !== undefined ? (
        <CardActions className={classes.cardActions}>{footer}</CardActions>
      ) : null}
    </Card>
  )
}

RegularCard.defaultProps = {
  headerColor: 'green',
}

RegularCard.propTypes = {
  plainCard: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf(['orange', 'green', 'red', 'blue', 'purple']),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
}

export default withStyles(regularCardStyle)(RegularCard)
