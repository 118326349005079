import {
  COUNT_UNMATCHED_BARCODE_FAILED,
  COUNT_UNMATCHED_BARCODE_SUCCEED,
  COUNT_UNMATCHED_BARCODE_PROCESSING,
  FETCH_UNMATCHED_BARCODE_FAILED,
  FETCH_UNMATCHED_BARCODE_SUCCEED,
  FETCH_UNMATCHED_BARCODE_PROCESSING,
  GET_UNMATCHED_BARCODE_PROCESSING,
  GET_UNMATCHED_BARCODE_FAILED,
  GET_UNMATCHED_BARCODE_SUCCEED,
  SAVE_UNMATCHED_BARCODE_FAILED,
  SAVE_UNMATCHED_BARCODE_SUCCEED,
  SAVE_UNMATCHED_BARCODE_PROCESSING,
  DELETE_UNMATCHED_BARCODE_FAILED,
  DELETE_UNMATCHED_BARCODE_SUCCEED,
  DELETE_UNMATCHED_BARCODE_PROCESSING,
} from '../../constants/unmatchedBarcode.constants'
import Parse from '../parse'
const parseObject = 'UnmatchedBarcode'

export const unmatchedBarcode = {
  count() {
    return async dispatch => {
      dispatch({
        type: COUNT_UNMATCHED_BARCODE_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      query.equalTo('matched', false)
      try {
        const total = await query.count()
        return dispatch({
          type: COUNT_UNMATCHED_BARCODE_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (err) {
        return dispatch({
          type: COUNT_UNMATCHED_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
  list(page, rowsPerPage, searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_UNMATCHED_BARCODE_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      query.include('plant').include('retailersGroup')
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('barcode', searchText.trim(), 'i')
      }
      try {
        const results = await query
          .limit(rowsPerPage)
          .skip(page * rowsPerPage)
          .descending('createdAt')
          .find()
        var list = []
        list = results.map(r => {
          return {
            [r.id]: {
              name: r.get('barcode'),
              plantCommonName: r.get('plant')
                ? r.get('plant').get('commonName')
                : '',
              plantLatinName: r.get('plant')
                ? r.get('plant').get('latinName')
                : '',
              retailerGroups: r.get('retailersGroup')
                ? r.get('retailersGroup').get('name')
                : '',
            },
          }
        })
        dispatch({
          type: FETCH_UNMATCHED_BARCODE_SUCCEED,
          payload: {
            list: list,
          },
        })
      } catch (err) {
        dispatch({
          type: FETCH_UNMATCHED_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_UNMATCHED_BARCODE_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      try {
        const parseUnmatchedBarcode = await query.get(id)
        const record = {
          id: parseUnmatchedBarcode.id,
          barcode: parseUnmatchedBarcode.get('barcode'),
          latinName: parseUnmatchedBarcode.get('latinName'),
          plantDescription: parseUnmatchedBarcode.get('plantDescription'),
          bloomSeason: parseUnmatchedBarcode.get('bloomSeason'),
          lightRecommendation: parseUnmatchedBarcode.get('lightRecommendation'),
          heightAndWidth: parseUnmatchedBarcode.get('heightAndWidth'),
          pruningNeeds: parseUnmatchedBarcode.get('pruningNeeds'),
          wateringRecommendation: parseUnmatchedBarcode.get('wateringRecommendation'),
          buyURL: parseUnmatchedBarcode.get('buyURL'),
          timeOfYearToPlant: parseUnmatchedBarcode.get('timeOfYearToPlant'),
          soilTypeRecommendation: parseUnmatchedBarcode.get('soilTypeRecommendation'),
          hardiness: parseUnmatchedBarcode.get('hardiness'),
          usdaUkPlantingZones: parseUnmatchedBarcode.get('usdaUkPlantingZones'),

        }
        let barcodeGenus = parseUnmatchedBarcode.get('genus')
        if (barcodeGenus) {
          let parseGenus = await barcodeGenus.fetch()
          record.genus = parseGenus
            ? {
                value: parseGenus.id,
                label: parseGenus.get('genusName'),
              }
            : {}
        }
        dispatch({
          type: GET_UNMATCHED_BARCODE_SUCCEED,
          payload: { record },
        })
      } catch (err) {
        dispatch({
          type: GET_UNMATCHED_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
  getNext(page) {
    return async dispatch => {
      dispatch({
        type: GET_UNMATCHED_BARCODE_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      try {
        const parseUnmatchedBarcodes = await query
          .equalTo('matched', false)
          .skip(page)
          .limit(1)
          .find()
        if (parseUnmatchedBarcodes.length === 0) {
          return false
        }
        const parseUnmatchedBarcode = parseUnmatchedBarcodes[0]
        const record = {
          id: parseUnmatchedBarcode.id,
          barcode: parseUnmatchedBarcode.get('barcode'),
          latinName: parseUnmatchedBarcode.get('latinName'),
          plantDescription: parseUnmatchedBarcode.get('plantDescription'),
          bloomSeason: parseUnmatchedBarcode.get('bloomSeason'),
          lightRecommendation: parseUnmatchedBarcode.get('lightRecommendation'),
          heightAndWidth: parseUnmatchedBarcode.get('heightAndWidth'),
          pruningNeeds: parseUnmatchedBarcode.get('pruningNeeds'),
        }
        let barcodeGenus = parseUnmatchedBarcode.get('genus')
        if (barcodeGenus) {
          let parseGenus = await barcodeGenus.fetch()
          record.genus = parseGenus
            ? {
                value: parseGenus.id,
                label: parseGenus.get('genusName'),
              }
            : {}
        }
        let barcodeRetailersGroup = parseUnmatchedBarcode.get('retailersGroup')
        if (barcodeRetailersGroup) {
          let parseRetailersGroup = await barcodeRetailersGroup.fetch()
          record.retailersGroup = parseRetailersGroup
            ? {
                value: parseRetailersGroup.id,
                label: parseRetailersGroup.get('name'),
              }
            : {}
        }
        return dispatch({
          type: GET_UNMATCHED_BARCODE_SUCCEED,
          payload: { record },
        })
      } catch (err) {
        return dispatch({
          type: GET_UNMATCHED_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_UNMATCHED_BARCODE_PROCESSING,
      })
      if (!values.id) return
      // if we're adding a new plant, create it first
      const Plant = Parse.Object.extend('Plant')
      const Barcode = Parse.Object.extend('Barcode')
      const barcode = new Barcode()
      let plant = {}
      if (values.addingNewPlant) {
        plant = new Plant()
        // add all the plant data
        plant.set({
          latinName: values.barcode.latinName,
          commonName: values.barcode.commonName,
          plantDescription: values.barcode.plantDescription,
          bloomSeason: values.barcode.bloomSeason || '',
          lightRecommendation: values.barcode.lightRecommendation || '',
          heightAndWidth: values.barcode.heightAndWidth || '',
          pruningNeeds: values.barcode.pruningNeeds || '',
        })

        // try to get the genus
        const genusQuery = new Parse.Query(Parse.Object.extend('Genus'))
        const genus = await genusQuery.get(values.barcode.genus.value)
        if (genus) {
          plant.set('genus', genus.toPointer())
        }
        try {
          await plant.save(null)
        } catch (err) {
          return dispatch({
            type: SAVE_UNMATCHED_BARCODE_FAILED,
            payload: {
              error: 'Error: ' + err.code + ' ' + err.message,
            },
          })
        }
        barcode.set('plant', plant.toPointer())
      } else {
        const plantQuery = new Parse.Query(Plant)
        plant = await plantQuery.get(values.barcode.plant.value)
        if (plant) {
          barcode.set('plant', plant.toPointer())
        }
      }
      const retailersGroupQuery = new Parse.Query(
        Parse.Object.extend('RetailersGroup')
      )
      const retailersGroup = await retailersGroupQuery.get(
        values.record.retailersGroup.value
      )
      if (retailersGroup) {
        let retailersGroupsRelation = barcode.relation('retailersGroups')

        retailersGroupsRelation.add(retailersGroup)
      }

      barcode.set({
        barcode: values.record.barcode,
      })

      const unmatchedBarcodeQuery = new Parse.Query(
        Parse.Object.extend(parseObject)
      )
      const unmatchedBarcode = await unmatchedBarcodeQuery.get(values.id)

      unmatchedBarcode.set({ matched: true })

      try {
        await unmatchedBarcode.save(null)
        await barcode.save(null)
        return dispatch({
          type: SAVE_UNMATCHED_BARCODE_SUCCEED,
        })
      } catch (err) {
        return dispatch({
          type: SAVE_UNMATCHED_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_UNMATCHED_BARCODE_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend(parseObject))
      try {
        const unmatchedBarcodes = await query
          .containedIn('objectId', ids)
          .find()
        await Parse.Object.destroyAll(unmatchedBarcodes)
        dispatch({
          type: DELETE_UNMATCHED_BARCODE_SUCCEED,
        })
      } catch (err) {
        dispatch({
          type: DELETE_UNMATCHED_BARCODE_FAILED,
          payload: {
            error: 'Error: ' + err.code + ' ' + err.message,
          },
        })
      }
    }
  },
}
