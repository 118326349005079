import {
  COUNT_GENUSCAREINFO_FAILED,
  COUNT_GENUSCAREINFO_SUCCEED,
  COUNT_GENUSCAREINFO_PROCESSING,
  FETCH_GENUSCAREINFO_FAILED,
  FETCH_GENUSCAREINFO_SUCCEED,
  FETCH_GENUSCAREINFO_PROCESSING,
  GET_GENUSCAREINFO_FAILED,
  GET_GENUSCAREINFO_SUCCEED,
  GET_GENUSCAREINFO_PROCESSING,
  FETCH_GENUSCAREINFO_REGIONS_FAILED,
  FETCH_GENUSCAREINFO_REGIONS_SUCCEED,
  FETCH_GENUSCAREINFO_REGIONS_PROCESSING,
  FETCH_GENUSCAREINFO_PRODUCTS_FAILED,
  FETCH_GENUSCAREINFO_PRODUCTS_SUCCEED,
  FETCH_GENUSCAREINFO_PRODUCTS_PROCESSING,
  FETCH_GENUSCAREINFO_MONTHS_FAILED,
  FETCH_GENUSCAREINFO_MONTHS_SUCCEED,
  FETCH_GENUSCAREINFO_MONTHS_PROCESSING,
  FETCH_GENUSCAREINFO_GENUSES_FAILED,
  FETCH_GENUSCAREINFO_GENUSES_SUCCEED,
  FETCH_GENUSCAREINFO_GENUSES_PROCESSING,
  FETCH_GENUSCAREINFO_ICONS_FAILED,
  FETCH_GENUSCAREINFO_ICONS_SUCCEED,
  FETCH_GENUSCAREINFO_ICONS_PROCESSING,
  SAVE_GENUSCAREINFO_FAILED,
  SAVE_GENUSCAREINFO_SUCCEED,
  SAVE_GENUSCAREINFO_PROCESSING,
  DELETE_GENUSCAREINFO_FAILED,
  DELETE_GENUSCAREINFO_SUCCEED,
  DELETE_GENUSCAREINFO_PROCESSING,
} from '../../constants'
import Parse from '../parse'
import pick from 'lodash.pick'

const GenusCareInfo = Parse.Object.extend('GenusCareInfo')
const Genus = Parse.Object.extend('Genus')
const Products = Parse.Object.extend('Products')
const Region = Parse.Object.extend('Region')
const Month = Parse.Object.extend('Month')
const CareIcon = Parse.Object.extend('CareIcon')

export const genusCareInfo = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_GENUSCAREINFO_PROCESSING,
      })
      let query = new Parse.Query(GenusCareInfo)
      searchText = searchText || ''
      if (searchText !== '') {
        let genusQuery = new Parse.Query(Parse.Object.extend('Genus'))
        genusQuery.matches('genusName', searchText.trim(), 'i')
        query.matchesQuery('genusId', genusQuery)
      }
      let total = ''
      try {
        total = await query.count()
      } catch (error) {
        return dispatch({
          type: COUNT_GENUSCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      return dispatch({
        type: COUNT_GENUSCAREINFO_SUCCEED,
        payload: {
          total: total,
        },
      })
    }
  },
  fetch(page, rowsPerPage, searchText = '') {
    return async dispatch => {
      dispatch({
        type: FETCH_GENUSCAREINFO_PROCESSING,
        payload: {
          searchText,
        },
      })
      let query = new Parse.Query(GenusCareInfo)
      query.include('genusId').include('monthId')
      searchText = searchText || ''
      if (searchText !== '') {
        let genusQuery = new Parse.Query(Parse.Object.extend('Genus'))
        genusQuery.matches('genusName', searchText.trim(), 'i')
        query.matchesQuery('genusId', genusQuery)
      }
      let results = []
      try {
        results = await query
          .limit(rowsPerPage)
          .skip(page * rowsPerPage)
          .descending('createdAt')
          .find()
      } catch (error) {
        return dispatch({
          type: FETCH_GENUSCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = results.map(r => {
        const genus = r.get('genusId') ? r.get('genusId').get('genusName') : ''
        const careField1 = r.get('careField1')
          ? r.get('careField1').slice(0, 25) + '…'
          : ''
        const careField2 = r.get('careField2')
          ? r.get('careField2').slice(0, 25) + '…'
          : ''
        const careField3 = r.get('careField3')
          ? r.get('careField3').slice(0, 25) + '…'
          : ''
        const monthId = r.get('monthId')
          ? r
              .get('monthId')
              .map(month => month.get('seasonName'))
              .join(', ')
          : ''
        return {
          [r.id]: {
            genus,
            careField1,
            careField2,
            careField3,
            monthId,
          },
        }
      })
      return dispatch({
        type: FETCH_GENUSCAREINFO_SUCCEED,
        payload: { list },
      })
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_GENUSCAREINFO_PROCESSING,
      })
      let query = new Parse.Query(GenusCareInfo)
      let genusCareInfo = {}
      try {
        genusCareInfo = await query
          .include('products')
          .include('regions')
          .include('genusId')
          .include('monthId')
          .include('icon')
          .get(id)
      } catch (error) {
        return dispatch({
          type: GET_GENUSCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      let genusId = {}
      const genusIdParse = genusCareInfo.get('genusId')
      if (genusIdParse) {
        genusId = {
          value: genusIdParse.id,
          label: genusIdParse.get('genusName'),
        }
      }
      let products = []
      let productsParse = genusCareInfo.get('products')
      if (productsParse) {
        products = productsParse.map(p => ({
          value: p.id,
          label: p.get('productName'),
        }))
      }
      let monthId = []
      let monthIdParse = genusCareInfo.get('monthId')
      if (monthIdParse) {
        monthId = monthIdParse.map(m => ({
          value: m.id,
          label: m.get('seasonName'),
        }))
      }
      let regions = []
      let regionsParse = genusCareInfo.get('regions')
      if (regionsParse) {
        regions = regionsParse.map(r => ({
          value: r.id,
          label: r.get('regionName'),
        }))
      }
      let icon = { value: '', label: '' }
      const iconParse = genusCareInfo.get('icon')
      if (iconParse) {
        icon.value = iconParse.id
        icon.label = iconParse.get('name')
      }
      const record = {
        object_id: genusCareInfo.id,
        genusId,
        careField1: genusCareInfo.get('careField1'),
        careField2: genusCareInfo.get('careField2'),
        careField3: genusCareInfo.get('careField3'),
        careDetails: genusCareInfo.get('careDescription'),
        alexa: genusCareInfo.get('alexa'),
        products,
        monthId,
        regions,
        icon,
      }
      return dispatch({
        type: GET_GENUSCAREINFO_SUCCEED,
        payload: {
          record,
        },
      })
    }
  },
  getRegions(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_GENUSCAREINFO_REGIONS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('regionName', searchText.trim(), 'i')
      }
      query.limit(10000)
      let results = []
      try {
        results = await query.find()
      } catch (error) {
        return dispatch({
          type: FETCH_GENUSCAREINFO_REGIONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        list.push({
          value: object.id,
          label: object.get('regionName'),
        })
      }
      return dispatch({
        type: FETCH_GENUSCAREINFO_REGIONS_SUCCEED,
        payload: {
          regions: list,
        },
      })
    }
  },
  getGenera(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_GENUSCAREINFO_GENUSES_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Genus'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('genusName', searchText.trim(), 'i')
      }
      let results = []
      try {
        results = await query.limit(10000).find()
      } catch (error) {
        return dispatch({
          type: FETCH_GENUSCAREINFO_GENUSES_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        list.push({
          value: object.id,
          label: object.get('genusName'),
        })
      }
      return dispatch({
        type: FETCH_GENUSCAREINFO_GENUSES_SUCCEED,
        payload: {
          genuses: list,
        },
      })
    }
  },
  getMonths(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_GENUSCAREINFO_MONTHS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Month'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('seasonName', searchText.trim(), 'i')
      }
      let results = []
      try {
        results = await query.limit(10000).find()
      } catch (error) {
        return dispatch({
          type: FETCH_GENUSCAREINFO_MONTHS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        list.push({
          value: object.id,
          label: object.get('seasonName'),
        })
      }
      return dispatch({
        type: FETCH_GENUSCAREINFO_MONTHS_SUCCEED,
        payload: {
          months: list,
        },
      })
    }
  },
  getProducts(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_GENUSCAREINFO_PRODUCTS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Products'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('productName', searchText.trim(), 'i')
      }
      query.limit(10000)
      let results = []
      try {
        results = await query.find()
      } catch (error) {
        return dispatch({
          type: FETCH_GENUSCAREINFO_PRODUCTS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        list.push({
          value: object.id,
          label: object.get('productName'),
        })
      }
      return dispatch({
        type: FETCH_GENUSCAREINFO_PRODUCTS_SUCCEED,
        payload: {
          products: list,
        },
      })
    }
  },
  getIcons(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_GENUSCAREINFO_ICONS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('CareIcon'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      let results = []
      try {
        results = await query.limit(10000).find()
      } catch (error) {
        return dispatch({
          type: FETCH_GENUSCAREINFO_ICONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        const iconName = object.get('name')
        if (iconName != null) {
          list.push({
            value: object.id,
            label: object.get('name'),
          })
        }
      }
      return dispatch({
        type: FETCH_GENUSCAREINFO_ICONS_SUCCEED,
        payload: {
          icons: list,
        },
      })
    }
  },
  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_GENUSCAREINFO_PROCESSING,
      })
      let genusCareInfo = new GenusCareInfo()
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        let genusCareInfoQuery = new Parse.Query(GenusCareInfo)
        try {
          genusCareInfo = await genusCareInfoQuery.get(values.object_id)
        } catch (err) {
          dispatch({
            type: SAVE_GENUSCAREINFO_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      }

      genusCareInfo.set(
        pick(values, [
          'careField1',
          'careField2',
          'careField3',
          'careDetails',
          'careDescription',
          'alexa',
        ])
      )

      if (values.genusId && values.genusId.value) {
        const GenusIdQuery = new Parse.Query(Genus)
        const genusId = await GenusIdQuery.get(values.genusId.value)
        genusCareInfo.set('genusId', genusId.toPointer())
      }
      if (values.icon && values.icon.value) {
        const CareIconQuery = new Parse.Query(CareIcon)
        const careIcon = await CareIconQuery.get(values.icon.value)
        genusCareInfo.set('icon', careIcon.toPointer())
      }
      if (values.monthId && values.monthId.length > 0) {
        // months is an array
        const monthQuery = new Parse.Query(Month)
        let objectIds = values.monthId.map(m => m.value)
        const months = await monthQuery
          .containedIn('objectId', objectIds)
          .find()
        genusCareInfo.set('monthId', months.map(m => m.toPointer()))
      }
      if (values.products && values.products.length > 0) {
        // products is an array
        const productsQuery = new Parse.Query(Products)
        let objectIds = values.products.map(p => p.value)
        const products = await productsQuery
          .containedIn('objectId', objectIds)
          .find()
        genusCareInfo.set('products', products.map(p => p.toPointer()))
      }
      if (values.regions && values.regions.length > 0) {
        // regions is an array
        const regionQuery = new Parse.Query(Region)
        const regions = await regionQuery
          .containedIn('objectId', values.regions.map(r => r.value))
          .find()
        genusCareInfo.set('regions', regions.map(r => r.toPointer()))
      }
      try {
        await genusCareInfo.save(null)
        return dispatch({
          type: SAVE_GENUSCAREINFO_SUCCEED,
        })
      } catch (error) {
        return dispatch({
          type: SAVE_GENUSCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_GENUSCAREINFO_PROCESSING,
      })
      let query = new Parse.Query(GenusCareInfo)
      let genusCareInfos = []
      try {
        genusCareInfos = await query.containedIn('objectId', ids).find()
      } catch (error) {
        return dispatch({
          type: DELETE_GENUSCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      if (genusCareInfos.length > 0) {
        await Parse.Object.destroyAll(genusCareInfos)
        return dispatch({
          type: DELETE_GENUSCAREINFO_SUCCEED,
        })
      } else {
        return dispatch({
          type: DELETE_GENUSCAREINFO_SUCCEED,
        })
      }
    }
  },
}
