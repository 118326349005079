import {
  COUNT_CATEGORYCAREINFO_FAILED,
  COUNT_CATEGORYCAREINFO_SUCCEED,
  COUNT_CATEGORYCAREINFO_PROCESSING,
  FETCH_CATEGORYCAREINFO_FAILED,
  FETCH_CATEGORYCAREINFO_SUCCEED,
  FETCH_CATEGORYCAREINFO_PROCESSING,
  GET_CATEGORYCAREINFO_FAILED,
  GET_CATEGORYCAREINFO_SUCCEED,
  GET_CATEGORYCAREINFO_PROCESSING,
  FETCH_CATEGORYCAREINFO_REGIONS_FAILED,
  FETCH_CATEGORYCAREINFO_REGIONS_SUCCEED,
  FETCH_CATEGORYCAREINFO_REGIONS_PROCESSING,
  FETCH_CATEGORYCAREINFO_PRODUCTS_FAILED,
  FETCH_CATEGORYCAREINFO_PRODUCTS_SUCCEED,
  FETCH_CATEGORYCAREINFO_PRODUCTS_PROCESSING,
  FETCH_CATEGORYCAREINFO_MONTHS_FAILED,
  FETCH_CATEGORYCAREINFO_MONTHS_SUCCEED,
  FETCH_CATEGORYCAREINFO_MONTHS_PROCESSING,
  FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_FAILED,
  FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_SUCCEED,
  FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_PROCESSING,
  FETCH_CATEGORYCAREINFO_ICONS_FAILED,
  FETCH_CATEGORYCAREINFO_ICONS_SUCCEED,
  FETCH_CATEGORYCAREINFO_ICONS_PROCESSING,
  SAVE_CATEGORYCAREINFO_FAILED,
  SAVE_CATEGORYCAREINFO_SUCCEED,
  SAVE_CATEGORYCAREINFO_PROCESSING,
  DELETE_CATEGORYCAREINFO_FAILED,
  DELETE_CATEGORYCAREINFO_SUCCEED,
  DELETE_CATEGORYCAREINFO_PROCESSING,
} from '../../constants'
import Parse from '../parse'
import pick from 'lodash.pick'

const CategoryCareInfo = new Parse.Object.extend('CategoryCareInfo')
const GenusCategory = Parse.Object.extend('GenusCategory')
const Products = Parse.Object.extend('Products')
const Region = Parse.Object.extend('Region')
const Month = Parse.Object.extend('Month')
const CareIcon = Parse.Object.extend('CareIcon')

export const categoryCareInfo = {
  count(searchText) {
    return async dispatch => {
      dispatch({
        type: COUNT_CATEGORYCAREINFO_PROCESSING,
      })
      let query = new Parse.Query(CategoryCareInfo)
      searchText = searchText || ''
      if (searchText !== '') {
        let categoryQuery = new Parse.Query(
          Parse.Object.extend('GenusCategory')
        )
        categoryQuery.matches('name', searchText.trim(), 'i')
        query.matchesQuery('categoryId', categoryQuery)
      }
      try {
        const total = await query.count()
        dispatch({
          type: COUNT_CATEGORYCAREINFO_SUCCEED,
          payload: {
            total: total,
          },
        })
      } catch (error) {
        dispatch({
          type: COUNT_CATEGORYCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  fetch(page, rowsPerPage, searchText = '', history) {
    return async dispatch => {
      dispatch({
        type: FETCH_CATEGORYCAREINFO_PROCESSING,
        payload: {
          searchText,
        },
      })
      let query = new Parse.Query(CategoryCareInfo)
      query.include('categoryId').include('monthId')
      searchText = searchText || ''
      if (searchText !== '') {
        let categoryQuery = new Parse.Query(
          Parse.Object.extend('GenusCategory')
        )
        categoryQuery.matches('name', searchText.trim(), 'i')
        query.matchesQuery('categoryId', categoryQuery)
      }
      let results = []
      try {
        results = await query
          .limit(rowsPerPage)
          .skip(page * rowsPerPage)
          .descending('createdAt')
          .find()
      } catch (error) {
        dispatch({
          type: FETCH_CATEGORYCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
        history.push('/login')
      }
      var list = results.map(r => {
        const category = r.get('categoryId')
          ? r.get('categoryId').get('name')
          : ''
        const careField1 = r.get('careField1')
          ? r.get('careField1').slice(0, 25) + '…'
          : ''
        const careField2 = r.get('careField2')
          ? r.get('careField2').slice(0, 25) + '…'
          : ''
        const careField3 = r.get('careField3')
          ? r.get('careField3').slice(0, 25) + '…'
          : ''
        const monthId = r.get('monthId')
          ? r
              .get('monthId')
              .map(month => month.get('seasonName'))
              .join(', ')
          : ''
        return {
          [r.id]: {
            category,
            alexa: r.get('Alexa'),
            careField1,
            careField2,
            careField3,
            monthId,
          },
        }
      })
      dispatch({
        type: FETCH_CATEGORYCAREINFO_SUCCEED,
        payload: {
          list: list,
        },
      })
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_CATEGORYCAREINFO_PROCESSING,
      })
      let query = new Parse.Query(CategoryCareInfo)
      let categoryCareInfo = []
      try {
        categoryCareInfo = await query
          .include('products')
          .include('categoryId')
          .include('monthId')
          .include('icon')
          .get(id)
      } catch (error) {
        dispatch({
          type: GET_CATEGORYCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      // regions are relations, we have to query differently
      let regions = []
      let regionsParse = await categoryCareInfo
        .relation('regions')
        .query()
        .find()
      categoryCareInfo._regions = regions
      regions = regionsParse.map(r => ({
        value: r.id,
        label: r.get('regionName'),
      }))

      let categoryId = {}
      const categoryIdParse = categoryCareInfo.get('categoryId')
      if (categoryIdParse) {
        categoryId = {
          value: categoryIdParse.id,
          label: categoryIdParse.get('name'),
        }
      }
      let products = []
      let productsParse = categoryCareInfo.get('products')
      if (productsParse) {
        products = productsParse.map(p => ({
          value: p.id,
          label: p.get('productName'),
        }))
      }
      let monthId = []
      let monthIdParse = categoryCareInfo.get('monthId')
      if (monthIdParse) {
        monthId = monthIdParse.map(m => ({
          value: m.id,
          label: m.get('seasonName'),
        }))
      }
      let icon = { value: '', label: '' }
      const iconParse = categoryCareInfo.get('icon')
      if (iconParse) {
        icon.value = iconParse.id
        icon.label = iconParse.get('name')
      }
      const record = {
        object_id: categoryCareInfo.id,
        categoryId,
        alexa: categoryCareInfo.get('alexa') || '',
        careField1: categoryCareInfo.get('careField1') || '',
        careField2: categoryCareInfo.get('careField2') || '',
        careField3: categoryCareInfo.get('careField3') || '',
        careDetails: categoryCareInfo.get('careDetails') || '',
        careDescription: categoryCareInfo.get('careDescription') || '',
        products,
        monthId,
        regions,
        icon,
      }
      return dispatch({
        type: GET_CATEGORYCAREINFO_SUCCEED,
        payload: {
          record,
        },
      })
    }
  },
  getRegions(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_CATEGORYCAREINFO_REGIONS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Region'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('regionName', searchText.trim(), 'i')
      }
      query.limit(10000)
      try {
        const results = await query.find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('regionName'),
          })
        }
        dispatch({
          type: FETCH_CATEGORYCAREINFO_REGIONS_SUCCEED,
          payload: {
            regions: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_CATEGORYCAREINFO_REGIONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getGenusCategories(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('GenusCategory'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      try {
        const results = await query.limit(10000).find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('name'),
          })
        }
        dispatch({
          type: FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_SUCCEED,
          payload: {
            genusCategories: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_CATEGORYCAREINFO_GENUSCATEGORIES_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getMonths(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_CATEGORYCAREINFO_MONTHS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Month'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('seasonName', searchText.trim(), 'i')
      }
      try {
        const results = await query.limit(10000).find()
        var list = []
        for (var i = 0; i < results.length; i++) {
          var object = results[i]
          list.push({
            value: object.id,
            label: object.get('seasonName'),
          })
        }
        dispatch({
          type: FETCH_CATEGORYCAREINFO_MONTHS_SUCCEED,
          payload: {
            months: list,
          },
        })
      } catch (error) {
        dispatch({
          type: FETCH_CATEGORYCAREINFO_MONTHS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  getProducts(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_CATEGORYCAREINFO_PRODUCTS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('Products'))
      searchText = searchText || ''
      if (searchText !== '') {
        // query.fullText('productName', searchText);
        query.matches('productName', searchText.trim(), 'i')
      }
      query.limit(10000)
      let results = []
      try {
        results = await query.find()
      } catch (error) {
        dispatch({
          type: FETCH_CATEGORYCAREINFO_PRODUCTS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        list.push({
          value: object.id,
          label: object.get('productName'),
        })
      }
      dispatch({
        type: FETCH_CATEGORYCAREINFO_PRODUCTS_SUCCEED,
        payload: {
          products: list,
        },
      })
    }
  },
  getIcons(searchText) {
    return async dispatch => {
      dispatch({
        type: FETCH_CATEGORYCAREINFO_ICONS_PROCESSING,
      })
      let query = new Parse.Query(Parse.Object.extend('CareIcon'))
      searchText = searchText || ''
      if (searchText !== '') {
        query.matches('name', searchText.trim(), 'i')
      }
      let results = []
      try {
        results = await query.limit(10000).find()
      } catch (error) {
        return dispatch({
          type: FETCH_CATEGORYCAREINFO_ICONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
      var list = []
      for (var i = 0; i < results.length; i++) {
        var object = results[i]
        const iconName = object.get('name')
        if (iconName != null) {
          list.push({
            value: object.id,
            label: object.get('name'),
          })
        }
      }
      return dispatch({
        type: FETCH_CATEGORYCAREINFO_ICONS_SUCCEED,
        payload: {
          icons: list,
        },
      })
    }
  },

  save(values) {
    return async dispatch => {
      dispatch({
        type: SAVE_CATEGORYCAREINFO_PROCESSING,
      })

      let categoryCareInfo = new CategoryCareInfo()
      if (
        values.object_id !== undefined &&
        values.object_id !== null &&
        values.object_id !== ''
      ) {
        let categoryCareInfoQuery = new Parse.Query(CategoryCareInfo)
        try {
          categoryCareInfo = await categoryCareInfoQuery.get(values.object_id)
        } catch (err) {
          dispatch({
            type: SAVE_CATEGORYCAREINFO_FAILED,
            payload: {
              error: `Error: ${err.code} ${err.message}`,
            },
          })
        }
      }

      categoryCareInfo.set(
        pick(values, [
          'careField1',
          'careField2',
          'careField3',
          'careDetails',
          'careDescription',
          'alexa',
        ])
      )

      if (values.categoryId && values.categoryId.value) {
        const CategoryIdQuery = new Parse.Query(GenusCategory)
        const categoryId = await CategoryIdQuery.get(values.categoryId.value)
        categoryCareInfo.set('categoryId', categoryId.toPointer())
      }
      if (values.icon && values.icon.value) {
        const CareIconQuery = new Parse.Query(CareIcon)
        const careIcon = await CareIconQuery.get(values.icon.value)
        categoryCareInfo.set('icon', careIcon.toPointer())
      }
      if (values.monthId && values.monthId.length > 0) {
        // months is an array
        const monthQuery = new Parse.Query(Month)
        let objectIds = values.monthId.map(m => m.value)
        const months = await monthQuery
          .containedIn('objectId', objectIds)
          .find()
        categoryCareInfo.set('monthId', months.map(m => m.toPointer()))
      }
      if (values.products && values.products.length > 0) {
        // products is an array
        const productsQuery = new Parse.Query(Products)
        const products = await productsQuery
          .containedIn('objectId', values.products.map(p => p.value))
          .find()
        categoryCareInfo.set('products', products.map(p => p.toPointer()))
      }
      if (values.regions && values.regions.length > 0) {
        // regions is a relation
        const regionQuery = new Parse.Query(Region)
        const regions = await regionQuery
          .containedIn('objectId', values.regions.map(r => r.value))
          .find()
        const regionRelation = categoryCareInfo.relation('regions')
        regionRelation.add(regions)
        const currentRegions = await regionRelation.query().find()
        const regionsToRemove = currentRegions.filter(
          cR => values.regions.findIndex(r => r.value === cR.id) === -1
        )
        if (regionsToRemove && regionsToRemove.length > 0) {
          regionRelation.remove(regionsToRemove)
        }
      }
      try {
        await categoryCareInfo.save(null)
        dispatch({
          type: SAVE_CATEGORYCAREINFO_SUCCEED,
        })
      } catch (error) {
        dispatch({
          type: SAVE_CATEGORYCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
  delete(ids) {
    return async dispatch => {
      dispatch({
        type: DELETE_CATEGORYCAREINFO_PROCESSING,
      })
      let query = new Parse.Query(CategoryCareInfo)
      try {
        const categoryCareInfos = await query
          .containedIn('objectId', ids)
          .find()
        if (categoryCareInfos.length > 0) {
          await Parse.Object.destroyAll(categoryCareInfos)
          dispatch({
            type: DELETE_CATEGORYCAREINFO_SUCCEED,
          })
        } else {
          dispatch({
            type: DELETE_CATEGORYCAREINFO_SUCCEED,
          })
        }
      } catch (error) {
        dispatch({
          type: DELETE_CATEGORYCAREINFO_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`,
          },
        })
      }
    }
  },
}
