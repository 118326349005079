/*eslint max-len: ["error", { "code": 200 }]*/

export const COUNT_PLANTLOCATION_PROCESSING = 'COUNT_PLANTLOCATION_PROCESSING'
export const COUNT_PLANTLOCATION_SUCCEED = 'COUNT_PLANTLOCATION_SUCCEED'
export const COUNT_PLANTLOCATION_FAILED = 'COUNT_PLANTLOCATION_FAILED'
export const FETCH_PLANTLOCATION_PROCESSING = 'FETCH_PLANTLOCATION_PROCESSING'
export const FETCH_PLANTLOCATION_SUCCEED = 'FETCH_PLANTLOCATION_SUCCEED'
export const FETCH_PLANTLOCATION_FAILED = 'FETCH_PLANTLOCATION_FAILED'
export const SET_PLANTLOCATION_PARAMS = 'SET_PLANTLOCATION_PARAMS'
export const FETCH_PLANTLOCATION_REGIONS_PROCESSING = 'FETCH_PLANTLOCATION_REGIONS_PROCESSING'
export const FETCH_PLANTLOCATION_REGIONS_SUCCEED = 'FETCH_PLANTLOCATION_REGIONS_SUCCEED'
export const FETCH_PLANTLOCATION_REGIONS_FAILED = 'FETCH_PLANTLOCATION_REGIONS_FAILED'
export const GET_PLANTLOCATION_PROCESSING = 'GET_PLANTLOCATION_PROCESSING'
export const GET_PLANTLOCATION_SUCCEED = 'GET_PLANTLOCATION_SUCCEED'
export const GET_PLANTLOCATION_FAILED = 'GET_PLANTLOCATION_FAILED'
export const SAVE_PLANTLOCATION_PROCESSING = 'SAVE_PLANTLOCATION_PROCESSING'
export const SAVE_PLANTLOCATION_SUCCEED = 'SAVE_PLANTLOCATION_SUCCEED'
export const SAVE_PLANTLOCATION_FAILED = 'SAVE_PLANTLOCATION_FAILED'
export const DELETE_PLANTLOCATION_PROCESSING = 'DELETE_PLANTLOCATION_PROCESSING'
export const DELETE_PLANTLOCATION_SUCCEED = 'DELETE_PLANTLOCATION_SUCCEED'
export const DELETE_PLANTLOCATION_FAILED = 'DELETE_PLANTLOCATION_FAILED'
export const HIDE_PLANTLOCATION_ALERT = 'HIDE_PLANTLOCATION_ALERT'
