import React, { Component } from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'

import { overlay, loadingSpinner } from 'variables/styles'

class Loading extends Component {
  render() {
    const { classes } = this.props
    return (
      <div
        className={classes.overlay}
        style={{ zIndex: this.props.fetching ? 10 : 0 }}
      >
        <CircularProgress className={classes.loadingSpinner} />
      </div>
    )
  }
}

const styles = theme => ({
  overlay,
  loadingSpinner,
})

export default withStyles(styles)(Loading)
