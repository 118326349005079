import { region as regionAPI } from '../api'
import { HIDE_REGION_ALERT } from '../constants/region.constants'
export const region = {
  fetch(page, rowsPerPage, query) {
    return dispatch => {
      dispatch(regionAPI.list(page, rowsPerPage, query))
    }
  },
  get(id) {
    return dispatch => {
      dispatch(regionAPI.get(id))
    }
  },
  getSubscriptionCurrencies(query) {
    return dispatch => {
      dispatch(regionAPI.getSubscriptionCurrencies(query))
    }
  },
  count(query) {
    return dispatch => {
      dispatch(regionAPI.count(query))
    }
  },
  save(values) {
    return dispatch => {
      dispatch(regionAPI.save(values))
    }
  },
  delete(id) {
    return dispatch => {
      dispatch(regionAPI.delete(id))
    }
  },
  hideAlert() {
    return dispatch => {
      dispatch({
        type: HIDE_REGION_ALERT,
      })
    }
  },
}
