/*eslint max-len: ["error", { "code": 200 }]*/

export const COUNT_CAREINFO_PROCESSING = 'COUNT_CAREINFO_PROCESSING'
export const COUNT_CAREINFO_SUCCEED = 'COUNT_CAREINFO_SUCCEED'
export const COUNT_CAREINFO_FAILED = 'COUNT_CAREINFO_FAILED'
export const FETCH_CAREINFO_PROCESSING = 'FETCH_CAREINFO_PROCESSING'
export const FETCH_CAREINFO_SUCCEED = 'FETCH_CAREINFO_SUCCEED'
export const FETCH_CAREINFO_FAILED = 'FETCH_CAREINFO_FAILED'
export const SET_CAREINFO_PARAMS = 'SET_CAREINFO_PARAMS'
export const FETCH_CAREINFO_REGIONS_PROCESSING = 'FETCH_CAREINFO_REGIONS_PROCESSING'
export const FETCH_CAREINFO_REGIONS_SUCCEED = 'FETCH_CAREINFO_REGIONS_SUCCEED'
export const FETCH_CAREINFO_REGIONS_FAILED = 'FETCH_CAREINFO_REGIONS_FAILED'
export const GET_CAREINFO_PROCESSING = 'GET_CAREINFO_PROCESSING'
export const GET_CAREINFO_SUCCEED = 'GET_CAREINFO_SUCCEED'
export const GET_CAREINFO_FAILED = 'GET_CAREINFO_FAILED'
export const FETCH_CAREINFO_GENUSCATEGORIES_PROCESSING = 'FETCH_CAREINFO_GENUSCATEGORIES_PROCESSING'
export const FETCH_CAREINFO_GENUSCATEGORIES_SUCCEED = 'FETCH_CAREINFO_GENUSCATEGORIES_SUCCEED'
export const FETCH_CAREINFO_GENUSCATEGORIES_FAILED = 'FETCH_CAREINFO_GENUSCATEGORIES_FAILED'
export const FETCH_CAREINFO_MONTHS_PROCESSING = 'FETCH_CAREINFO_MONTHS_PROCESSING'
export const FETCH_CAREINFO_MONTHS_SUCCEED = 'FETCH_CAREINFO_MONTHS_SUCCEED'
export const FETCH_CAREINFO_MONTHS_FAILED = 'FETCH_CAREINFO_MONTHS_FAILED'
export const FETCH_CAREINFO_PRODUCTS_PROCESSING = 'FETCH_CAREINFO_PRODUCTS_PROCESSING'
export const FETCH_CAREINFO_PRODUCTS_SUCCEED = 'FETCH_CAREINFO_PRODUCTS_SUCCEED'
export const FETCH_CAREINFO_PRODUCTS_FAILED = 'FETCH_CAREINFO_PRODUCTS_FAILED'
export const FETCH_CAREINFO_ICONS_FAILED = 'FETCH_CAREINFO_ICONS_FAILED'
export const FETCH_CAREINFO_ICONS_SUCCEED = 'FETCH_CAREINFO_ICONS_SUCCEED'
export const FETCH_CAREINFO_ICONS_PROCESSING = 'FETCH_CAREINFO_ICONS_PROCESSING'
export const SAVE_CAREINFO_PROCESSING = 'SAVE_CAREINFO_PROCESSING'
export const SAVE_CAREINFO_SUCCEED = 'SAVE_CAREINFO_SUCCEED'
export const SAVE_CAREINFO_FAILED = 'SAVE_CAREINFO_FAILED'
export const DELETE_CAREINFO_PROCESSING = 'DELETE_CAREINFO_PROCESSING'
export const DELETE_CAREINFO_SUCCEED = 'DELETE_CAREINFO_SUCCEED'
export const DELETE_CAREINFO_FAILED = 'DELETE_CAREINFO_FAILED'
export const HIDE_CAREINFO_ALERT = 'HIDE_CAREINFO_ALERT'
