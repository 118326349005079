import {
  COUNT_CAREINFO_FAILED,
  COUNT_CAREINFO_SUCCEED,
  COUNT_CAREINFO_PROCESSING,
  FETCH_CAREINFO_FAILED,
  FETCH_CAREINFO_SUCCEED,
  FETCH_CAREINFO_PROCESSING,
  SET_CAREINFO_PARAMS,
  GET_CAREINFO_FAILED,
  GET_CAREINFO_SUCCEED,
  GET_CAREINFO_PROCESSING,
  FETCH_CAREINFO_REGIONS_FAILED,
  FETCH_CAREINFO_REGIONS_SUCCEED,
  FETCH_CAREINFO_REGIONS_PROCESSING,
  FETCH_CAREINFO_MONTHS_FAILED,
  FETCH_CAREINFO_MONTHS_SUCCEED,
  FETCH_CAREINFO_MONTHS_PROCESSING,
  FETCH_CAREINFO_GENUSCATEGORIES_FAILED,
  FETCH_CAREINFO_GENUSCATEGORIES_SUCCEED,
  FETCH_CAREINFO_GENUSCATEGORIES_PROCESSING,
  FETCH_CAREINFO_ICONS_FAILED,
  FETCH_CAREINFO_ICONS_SUCCEED,
  FETCH_CAREINFO_ICONS_PROCESSING,
  SAVE_CAREINFO_FAILED,
  SAVE_CAREINFO_SUCCEED,
  SAVE_CAREINFO_PROCESSING,
  DELETE_CAREINFO_FAILED,
  DELETE_CAREINFO_SUCCEED,
  DELETE_CAREINFO_PROCESSING,
  HIDE_CAREINFO_ALERT,
} from 'constants/careInfo.constants'

const initialState = {
  fetching: false,
  fetched: false,
  saving: false,
  saved: false,
  deleting: false,
  deleted: false,
  error: false,
  errorMessage: '',
  list: [],
  total: 0,
  regions: [],
  months: [],
  genusCategories: [],
  params: {},
  record: {
    object_id: '',
    categoryId: {
      value: '',
      label: '',
    },
    alexa: '',
    careField1: '',
    careField2: '',
    careField3: '',
    careDetails: '',
    careDescription: '',
    monthId: [],
    regions: [],
    icon: '',
  },
  searchText: '',
}

const careInfo = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAREINFO_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CAREINFO_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
        errorMessage: '',
      }
    case FETCH_CAREINFO_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        errorMessage: '',
        list: action.payload.list,
      }
    case SET_CAREINFO_PARAMS:
      return {
        ...state,
        params: action.payload,
      }
    case GET_CAREINFO_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: true,
        errorMessage: action.payload.error,
      }
    case GET_CAREINFO_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: false,
        errorMessage: '',
      }
    case GET_CAREINFO_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: false,
        record: action.payload.record,
        errorMessage: '',
      }
    case FETCH_CAREINFO_REGIONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CAREINFO_REGIONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CAREINFO_REGIONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        regions: action.payload.regions,
      }
    case COUNT_CAREINFO_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_CAREINFO_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_CAREINFO_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    case FETCH_CAREINFO_GENUSCATEGORIES_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CAREINFO_GENUSCATEGORIES_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CAREINFO_GENUSCATEGORIES_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        genusCategories: action.payload.genusCategories,
      }
    case FETCH_CAREINFO_MONTHS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CAREINFO_MONTHS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CAREINFO_MONTHS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        months: action.payload.months,
      }
    case FETCH_CAREINFO_ICONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case FETCH_CAREINFO_ICONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case FETCH_CAREINFO_ICONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        icons: action.payload.icons,
      }
    case SAVE_CAREINFO_FAILED:
      return {
        ...state,
        saving: false,
        saved: true,
        errorMessage: action.payload.error,
      }
    case SAVE_CAREINFO_PROCESSING:
      return {
        ...state,
        saving: true,
        saved: false,
      }
    case SAVE_CAREINFO_SUCCEED:
      return {
        ...state,
        saving: false,
        saved: true,
      }
    case DELETE_CAREINFO_FAILED:
      return {
        ...state,
        deleting: false,
        deleted: false,
        errorMessage: action.payload.error,
      }
    case DELETE_CAREINFO_PROCESSING:
      return {
        ...state,
        deleting: true,
        deleted: false,
      }
    case DELETE_CAREINFO_SUCCEED:
      return {
        ...state,
        deleting: false,
        deleted: true,
      }
    case HIDE_CAREINFO_ALERT:
      return {
        ...state,
        fetched: false,
        saved: false,
        error: false,
        errorMessage: '',
        deleted: false,
      }
    default:
      return state
  }
}

export default careInfo
