import {
  FETCH_CARE_ICONS_PROCESSING,
  FETCH_CARE_ICONS_SUCCEED,
  FETCH_CARE_ICONS_FAILED,
  COUNT_CARE_ICONS_PROCESSING,
  COUNT_CARE_ICONS_SUCCEED,
  COUNT_CARE_ICONS_FAILED,
  GET_CARE_ICONS_PROCESSING,
  GET_CARE_ICONS_SUCCEED,
  GET_CARE_ICONS_FAILED,
} from '../constants'

const initialState = {
  fetching: false,
  fetched: false,
  errorMessage: '',
  list: [],
  total: 0,
  record: {
    careIconName: '',
  },
  searchText: '',
}

const careIcon = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CARE_ICONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case FETCH_CARE_ICONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
        errorMessage: '',
      }
    case FETCH_CARE_ICONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        list: action.payload.list,
        errorMessage: '',
      }
    case GET_CARE_ICONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        errorMessage: action.payload.error,
      }
    case GET_CARE_ICONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case GET_CARE_ICONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        record: action.payload.record,
      }
    case COUNT_CARE_ICONS_FAILED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        errorMessage: action.payload.error,
      }
    case COUNT_CARE_ICONS_PROCESSING:
      return {
        ...state,
        fetching: true,
        fetched: false,
      }
    case COUNT_CARE_ICONS_SUCCEED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        total: action.payload.total,
      }
    default:
      return state
  }
}

export default careIcon
