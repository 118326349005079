import {
  FETCH_CARE_ICONS_PROCESSING,
  FETCH_CARE_ICONS_SUCCEED,
  FETCH_CARE_ICONS_FAILED,
  COUNT_CARE_ICONS_PROCESSING,
  COUNT_CARE_ICONS_SUCCEED,
  COUNT_CARE_ICONS_FAILED,
  GET_CARE_ICONS_PROCESSING,
  GET_CARE_ICONS_SUCCEED,
  GET_CARE_ICONS_FAILED
} from '../../constants'
import Parse from '../parse'

export const careIcon = {
  count() {
    return async dispatch => {
      dispatch({
        type: COUNT_CARE_ICONS_PROCESSING
      })
      let query = new Parse.Query(Parse.Object.extend('CareIcon'))
      try {
        const total = await query.count()
        dispatch({
          type: COUNT_CARE_ICONS_SUCCEED,
          payload: {
            total: total
          }
        })
      } catch (error) {
        dispatch({
          type: COUNT_CARE_ICONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`
          }
        })
      }
    }
  },
  fetch(page, rowsPerPage, searchText = '', history) {
    return async dispatch => {
      dispatch({
        type: FETCH_CARE_ICONS_PROCESSING
      })
      let query = new Parse.Query(Parse.Object.extend('CareIcon'))
      if (searchText !== '') {
        query.matches('categoryCareInfoName', searchText.trim(), 'i')
      }
      try {
        const results = await query
          .limit(rowsPerPage)
          .skip(page * rowsPerPage)
          .descending('createdAt')
          .find()
        var list = []
        list = results.map(r => {
          return {
            label: r.get('name'),
            value: r.id
          }
        })
        dispatch({
          type: FETCH_CARE_ICONS_SUCCEED,
          payload: {
            list: list
          }
        })
      } catch (error) {
        dispatch({
          type: FETCH_CARE_ICONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`
          }
        })
        history.push('/login')
      }
    }
  },
  get(id) {
    return async dispatch => {
      dispatch({
        type: GET_CARE_ICONS_PROCESSING
      })
      let query = new Parse.Query(Parse.Object.extend('CareIcon'))
      let categoryCareInfo = {}
      try {
        categoryCareInfo = await query
          .include('products')
          .include('regions')
          .include('categoryId')
          .include('monthId')
          .get(id)
      } catch (error) {
        dispatch({
          type: GET_CARE_ICONS_FAILED,
          payload: {
            error: `Error: ${error.code} ${error.message}`
          }
        })
      }
      const record = {
        careIconName: categoryCareInfo.get('name')
      }
      return dispatch({
        type: GET_CARE_ICONS_SUCCEED,
        payload: {
          record
        }
      })

    }
  },
}
