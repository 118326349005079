import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CustomSnackbar from './CustomSnackbar'
import logo from '../assets/img/sp-logo-final.svg'
import LoginForm from './auth/LoginForm'
import { auth } from '../actions'
import RegularCard from './Cards/RegularCard.js'
import { Grid, withStyles } from '@material-ui/core'

class Login extends Component {
  state = {
    showInfo: false,
    showMessage: '',
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
  }

  submit = values => {
    this.props.dispatch(auth.login(values))
  }
  handleCloseError = () => {
    this.props.dispatch(auth.hideAlert())
  }

  render() {
    const { loading, error, errorMessage } = this.props
    return (
      <Grid container justify="center">
        <CustomSnackbar
          variant="error"
          open={error}
          message={errorMessage || ''}
          onClose={this.handleCloseError}
        />
        <Grid xs={4} item>
          <RegularCard
            cardTitle="SmartPlant CMS"
            cardSubtitle="Log in"
            mediaUrl={logo}
            mediaTitle="SmartPlant"
            content={
              <LoginForm
                error={errorMessage}
                loading={loading}
                onSubmit={this.submit.bind(this)}
              />
            }
            footer={''}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.auth.isLoggedIn,
  userId: state.auth.userId,
  token: state.auth.token,
  error: state.auth.error,
  errorMessage: state.auth.errorMessage,
})

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: '70px'
  },
})

export default withStyles(styles)(connect(mapStateToProps)(Login))
