// ##############################
// // // Sidebar styles
// #############################

import { drawerWidth } from '../styles'

const sidebarStyle = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  logo: {
    position: 'relative',
    padding: '20px 0',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '0.5px',
      right: '0',
      width: '100%',
      // backgroundColor: 'rgba(255,255,255, 0.20)',
    },
  },
  logoLink: {
    textTransform: 'uppercase',
    padding: '5px 0',
    display: 'block',
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: '400',
    lineHeight: '30px',
    textDecoration: 'none',
    // backgroundColor: 'transparent',
    '&,&:hover': {
      // color: '#FFFFFF',
    },
  },
  logoImage: {
    display: 'block',
  },
  img: {
    width: '98px',
    verticalAlign: 'middle',
    border: '0',
  },
  item: {
    color: '#fff',
  },
})

export default sidebarStyle
