import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { persistor } from '../../store'
import cx from 'classnames'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import userActions from 'actions/user.actions'
import { auth as authActions } from '../../actions'
import HeaderLinks from '../Header/HeaderLinks.js'
import sidebarStyle from 'variables/styles/sidebarStyle.jsx'

class Sidebar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    sidebarOpen: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    image: PropTypes.string,
    routes: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
    toggleSidebar: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    // return props.location.pathname.indexOf(routeName) > -1 ? true : false
    return this.props.location.pathname
      .split('/')
      .includes(routeName.replace('/', ''))
      ? true
      : false
  }
  activeExpand(children) {
    for (let i = 0; i < children.length; i++) {
      let routeName = children[i].path
      if (
        this.props.location.pathname
          .split('/')
          .includes(routeName.replace('/', ''))
      ) {
        return true
      }
    }
    return false
  }
  handleLogout = async () => {
    this.props.logout()
    await persistor.flush()
    this.props.history.push('/login')
  }
  render() {
    const { classes, color, logo, image, routes, sidebarOpen } = this.props
    var links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect) return null
          const listItemClasses = cx({
            [' ' + classes[color]]: this.activeRoute(prop.path),
          })
          const whiteFontClasses = cx({
            [' ' + classes.whiteFont]: this.activeRoute(prop.path),
          })
          if (prop.children) {
            return (
              <ExpansionPanel
                key={key}
                defaultExpanded={this.activeExpand(prop.children)}
                className={
                  classes.expandable + (prop.breakLine ? ' break' : '')
                }
              >
                <ExpansionPanelSummary
                  classes={{
                    root: classes.expandablePanelSummary,
                    content: classes.expandableContentSummary,
                    expandIcon: classes.expandIcon,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <NavLink
                    to={prop.path}
                    className={classes.item}
                    activeClassName="active"
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                    >
                      <ListItemIcon
                        className={classes.itemIcon + whiteFontClasses}
                      >
                        <prop.icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={prop.sidebarName}
                        className={classes.itemText + whiteFontClasses}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  className={classes.expandablePanelDetails}
                >
                  {prop.children.map((propChild, keyChild) => {
                    if (propChild.redirect) return null
                    const listItemClasses = cx({
                      [' ' + classes[color]]: this.activeRoute(propChild.path),
                    })
                    const whiteFontClasses = cx({
                      [' ' + classes.whiteFont]: this.activeRoute(
                        propChild.path
                      ),
                    })
                    return (
                      <NavLink
                        to={propChild.path}
                        className={
                          classes.item + (propChild.breakLine ? ' break' : '')
                        }
                        activeClassName="active"
                        key={key + keyChild}
                      >
                        <ListItem
                          button
                          className={classes.itemLink + listItemClasses}
                        >
                          <ListItemIcon
                            className={classes.itemIcon + whiteFontClasses}
                          >
                            <prop.icon />
                          </ListItemIcon>
                          <ListItemText
                            primary={propChild.sidebarName}
                            className={classes.itemText + whiteFontClasses}
                            disableTypography={true}
                          />
                        </ListItem>
                      </NavLink>
                    )
                  })}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          } else {
            return (
              <NavLink
                to={prop.path}
                className={classes.item + (prop.breakLine ? ' break' : '')}
                activeClassName="active"
                key={key}
              >
                <ListItem button className={classes.itemLink + listItemClasses}>
                  <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.sidebarName}
                    className={classes.itemText + whiteFontClasses}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
            )
          }
        })}
        <NavLink
          to="#"
          onClick={this.handleLogout}
          className={classes.item}
          activeClassName="active"
        >
          <ListItem button className={classes.itemLink}>
            <ListItemIcon className={classes.itemIcon}>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText
              primary="log out"
              className={classes.itemText}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      </List>
    )
    var brand = (
      <div className={classes.logo}>
        <a href="/" className={classes.logoLink}>
          <div className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>
        </a>
      </div>
    )
    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={sidebarOpen}
        onClose={() => this.props.toggleSidebar}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {brand}
        <div>
          <HeaderLinks />
          {links}
        </div>
        {image !== undefined ? (
          <div
            className={classes.background}
            style={{ backgroundImage: 'url(' + image + ')' }}
          />
        ) : null}
      </Drawer>
    )
  }
}

const mapStateToProps = state => ({
  sidebarOpen: state.user.sidebarOpen,
})

const mapDispatchToProps = { ...authActions, ...userActions }

export default withStyles(sidebarStyle)(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
)
