import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form'
import { Button, Fade, withStyles, CircularProgress } from '@material-ui/core'
import AsyncSelect from 'react-select/lib/Async'
import cronstrue from 'cronstrue'

import { overlay, loadingSpinner } from '../../variables/styles'
import RegularCard from '../Cards/RegularCard.js'
import careInfoActions from 'actions/careInfo.actions'
import formPropTypes from '../../constants/formPropTypes'
import CustomTextField from 'components/Input/CustomTextField'
import CustomSnackbar from 'components/CustomSnackbar'
import CustomMultiSelect from 'components/Input/CustomMultiSelect'
import asyncSelectStyles from 'variables/asyncSelectStyles'
import categoryApi from 'api/modules/category.api'
import genusApi from 'api/modules/genus.api'
import plantApi from 'api/modules/plant.api'

const form = 'careInfo'
const selector = formValueSelector(form)

const validate = () => {
  const errors = {}
  return errors
}

const onSubmit = async (values, dispatch, props) => {
  values.object_id = props.initialValues.object_id
  try {
    await props.save(values)
  } catch (err) {
    throw new SubmissionError({ _error: err.message })
  }
}

class CareInfoForm extends Component {
  static propTypes = {
    ...formPropTypes,
    notificationScheduleValue: PropTypes.string,
    genusCategories: PropTypes.array,
    icons: PropTypes.array,
    regions: PropTypes.array.isRequired,
    months: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    duplicateId: PropTypes.object,
    closeDuplicate: PropTypes.func,
  }

  async componentDidMount() {
    await this.props.getRegions()
    await this.props.getMonths()
    await this.props.getGenusCategories()
    await this.props.getProducts()
    await this.props.getIcons()
    if (this.props.duplicateId !== undefined && this.props.duplicateId !== null) {
      await this.props.get(this.props.duplicateId)
    } else {
      await this.props.get(this.props.match.params.id)
    }
  }

  hideAlert = () => {
    this.props.hideAlert()
    if (this.props.duplicateId) {
      this.props.closeDuplicate()
    }
  }

  handleCloseError = () => {
    this.props.hideAlert()
    this.props.reset()
  }

  backToList = () => {
    this.props.hideAlert()
    this.props.history.push('/careinfo')
  }

  getGenusCategories = (query) => categoryApi.select(query)

  getGenera = (query) => genusApi.select(query)

  getPlants = (query) => plantApi.select(query)

  render() {
    const {
      notificationScheduleValue,
      submitting,
      submitSucceeded,
      months,
      handleSubmit,
      error,
      classes,
      fetching,
      pristine,
    } = this.props

    const title = this.props.match.params.id ? 'Edit ' : 'Add new'

    return (
      <div>
        <CustomSnackbar
          variant="error"
          open={Boolean(error)}
          message={error && error.message}
          handleClose={() => this.handleCloseError()}
        />
        <CustomSnackbar variant="info" open={Boolean(submitting)} message="Saving" />
        <CustomSnackbar
          variant="success"
          open={Boolean(submitSucceeded)}
          message="Saved"
          onClose={() => this.hideAlert()}
        />
        <form onSubmit={handleSubmit}>
          <RegularCard
            cardTitle={title}
            content={
              <>
                <Fade in={fetching || submitting}>
                  <div className={classes.overlay} style={{ zIndex: fetching ? 10 : 0 }}>
                    <CircularProgress className={classes.loadingSpinner} />
                  </div>
                </Fade>
                <div>
                  <Field name="notificationSchedule" label="Schedule" component={CustomTextField} />
                  <p>
                    {notificationScheduleValue || (
                      <span>
                        Could not recognise, click{' '}
                        <a href="https://crontab.guru/" target="_blank" rel="noopener noreferrer">
                          here
                        </a>{' '}
                        for more help
                      </span>
                    )}
                  </p>
                </div>
                <div>
                  <Field name="careNotification" component={CustomTextField} label="Care Notification" />
                </div>
                <div>
                  <Field name="care[0]" component={CustomTextField} label="Care field 1" multiline />
                </div>
                <div>
                  <Field name="care[1]" component={CustomTextField} label="Care field 2" multiline />
                </div>
                <div>
                  <Field name="care[2]" component={CustomTextField} label="Care field 3" multiline />
                </div>
                <div>
                  <Field
                    name="plants"
                    label="Plants"
                    component={(props) => (
                      <AsyncSelect
                        {...props.input}
                        onBlur={() => {}}
                        placeholder="Search plants…"
                        isMulti
                        loadOptions={this.getPlants}
                        cacheOptions
                        styles={asyncSelectStyles}
                      />
                    )}
                  />
                </div>
                <div>
                  <Field
                    name="genera"
                    label="Genera"
                    component={(props) => (
                      <AsyncSelect
                        {...props.input}
                        onBlur={() => {}}
                        placeholder="Search genera…"
                        isMulti
                        loadOptions={this.getGenera}
                        cacheOptions
                        styles={asyncSelectStyles}
                      />
                    )}
                    parse={(genera) =>
                      genera &&
                      genera.map((g) => ({
                        __type: 'Pointer',
                        className: 'Genus',
                        objectId: g.value,
                        ...g,
                      }))
                    }
                    format={(genera) =>
                      genera &&
                      genera.map((g) => ({
                        value: g.objectId,
                        label: g.genusName,
                        ...g,
                      }))
                    }
                  />
                </div>
                <div>
                  <Field
                    name="categories"
                    label="Categories"
                    component={(props) => (
                      <AsyncSelect
                        {...props.input}
                        placeholder="Search categories…"
                        isMulti
                        loadOptions={this.getGenusCategories}
                        cacheOptions
                        styles={asyncSelectStyles}
                      />
                    )}
                  />
                </div>
                <div>
                  <Field
                    name="monthId"
                    label="Months"
                    options={months}
                    placeholder="Select months"
                    component={CustomMultiSelect}
                  />
                </div>
              </>
            }
            footer={
              <>
                <Button type="submit" variant="contained" color="primary" disabled={fetching || pristine || submitting}>
                  Submit
                </Button>
                <Button variant="contained" color="secondary" onClick={() => this.props.history.push('/careinfo')}>
                  Back to list
                </Button>
              </>
            }
          />
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const notificationScheduleValue = selector(state, 'notificationSchedule')
  let notificationSchedule
  try {
    notificationSchedule = cronstrue.toString(notificationScheduleValue)
  } catch (err) {
    notificationSchedule = null
  }

  return {
    notificationScheduleValue: notificationSchedule,
    genusCategories: state.careInfo.genusCategories,
    regions: state.careInfo.regions,
    months: state.careInfo.months,
    products: state.careInfo.products,
    icons: state.careInfo.icons,
    fetching: state.careInfo.fetching,
    fetched: state.careInfo.fetched,
    saving: state.careInfo.saving,
    saved: state.careInfo.saved,
    initialValues: state.careInfo.record,
  }
}

const mapDispatchToActions = { ...careInfoActions }

const styles = () => ({
  overlay,
  loadingSpinner,
})

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToActions
  )(
    reduxForm({
      form,
      validate,
      onSubmit,
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
    })(CareInfoForm)
  )
)
